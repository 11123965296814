import Desktop from "./Pages/Team/Desktop";
import Mobile from "./Pages/Team/Mobile";
import { useEffect, useRef, useState } from "react"
import styles from "./Team.module.scss"
import { useCam3lot, useRequest } from '../../hooks';
import { StakeInfoItem } from '../../chain/Cam3lot';
import {useHistory} from "react-router-dom"
import {getFullDisplayBalance} from '../../libs/formatUtil'
import {useBrowser} from '../../hooks/useBrowser'
import BigNumber from "bignumber.js";
import {useWalletModal} from "../../hooks/useWalletModal"
import { receiptCheck } from "../../libs/utils";
import {useAddPopup} from '../../state/application/hooks';
import { Cam3lotProvider } from "../../chain/Cam3lot-new";
import { chain, chains } from "../../libs/chain";

interface Info {
  agentPoint?: number
  point?: number
  stakePoint?: number
  stakeValue?: number //不用
  teamBTCValue?: number
  teamNumber?: number
  teamStakeValue?: string
  totalPoint?: number
  availablePoints?: number,
  stakeConfigurations: any[]
  teamStakePoint?: string
  personalStakePoint?: string
  teamAgentPoint?: string
  personalAgentPoint?: string
  networkPoint?: string
}
interface Token {
  amount: string
  address:string
  icon:string
  name:string
  price:string
  chain: chain
}

interface Snapshot {
  cycleName:string
  points:string
  reward:string
}

export interface Props {
  params: {
    userTotalBTC: number
    info: Info
    moreClick: (e: React.SyntheticEvent) => void
    showMore: boolean
    tokens: Token[]
    showTokenMore: boolean
    requestedUser: boolean
    invitationCode: string
    next: string
    progress: number
    toStake: () => void
    moreTokenClick: (e: React.SyntheticEvent) => void
    showHistory: boolean
    historyClick: (e: React.SyntheticEvent) => void
    snapshots: Snapshot[]
    totalReward: number
    reward: number
    redeemed: number
    withdraw: () => void
    withdrawLoading: boolean
    codeCopied: boolean;
    codeCopy: () => void;
  }
}

const Index = () => {
  const { account, currentChain } = useCam3lot();
  const addPopup = useAddPopup();
  let { get, post } = useRequest()
  let browser = useBrowser()
  const history = useHistory()
  let {setWalletModal} = useWalletModal()
  const [showMore, setShowMore] = useState<boolean>(false)
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [showTokenMore, setShowTokenMore] = useState<boolean>(false)
  const [tokens, setTokens] = useState<Token[]>([])
  const [snapshots, setSnapshots] = useState<Snapshot[]>([])
  const [next, setNext] = useState<string>('0')
  const [userTotalBTC, setUserTotalBTC] = useState<number>(0)
  const [info, setInfo] = useState<Info>({
    stakeConfigurations: []
  })
  const [progress, setProgress] = useState<number>(0)
  const [totalReward, setTotalReward] = useState<number>(0)
  const [reward, setReward] = useState<number>(0)
  const [redeemed, setRedeemed] = useState<number>(0)
  const [cam3lot, setcam3lot] = useState<Cam3lotProvider | null>(null)
  useEffect(() => {
    currentChain && setcam3lot(new Cam3lotProvider(currentChain))
  }, [currentChain])
  
  useEffect(() => {
    if(cam3lot && account) {
      //tokens 
      get('/api/tokens').then(async (tokenRes:any) => {
        // tokenRes.data.list = [...tokenRes.data.list,...tokenRes.data.list,...tokenRes.data.list,...tokenRes.data.list,...tokenRes.data.list,...tokenRes.data.list,...tokenRes.data.list,]
        let stakeRes:StakeInfoItem[] = []
        if(account){
          const getStakeInfos = chains.map(val => cam3lot.getStakeInfo(account, val))
          const res = await Promise.all(getStakeInfos)
          res.forEach((val, index) => {
            val = val.map(item => ({...item, chain: chains[index]}))
            stakeRes = [...stakeRes, ...val]
          })
        }

        console.log(stakeRes,tokenRes.data.list, 'filter')
        let list:Token[] = tokenRes.data.list.map((val:any) => {
          const findChain = chains.find(item => item.name === val.chain)
          let filter:any = stakeRes.find(stakeVal => stakeVal.token == val.address && val.chain === stakeVal?.chain?.name)
          if(filter) {
            val.amount = filter.amount
          }
          val.chain = findChain
          return val
        })
        // TODO: mock
        // list = [...list,...list,...list,...list,...list,...list,...list,...list,...list,...list,...list,...list,...list,...list,]
        const sortList = list.sort((a, b) => a.name === 'BTC' ? -1 : 1)
        setTokens(sortList.sort((a, b) => a.name === 'BTC' && a.chain.name === 'Merlin' ? -1 : 1))

        // 总资产
        let total: any = 0 
        list.forEach(val => {
          total = new BigNumber(total).plus(new BigNumber(getFullDisplayBalance(val.amount, 18, 5)).times(val.price))
        })
        total = new BigNumber(total).div(tokenRes.data.btcPrice).toFixed(5)
        setUserTotalBTC(isNaN(Number(total)) ? 0 : Number(total))
      })
    }
  }, [cam3lot, account])
  
  useEffect(() => {
    
    document.body.addEventListener('click', () => {
      setShowHistory(false)
      setShowMore(false)
      setShowTokenMore(false)
    })


    if(!account) return
    get('/api/user_summary').then((res:any) => {
      let info: Info = res.data
      setInfo(info)

      // 进度
      let t = info.teamBTCValue || 0
      let num = 0
      let o = 0
      let first = info.stakeConfigurations[0]
      if(t <= first.btcValue){
        o = t/first.btcValue *0.25 * 100
        setNext(first.btcValue)
      }else {
        info.stakeConfigurations.forEach((val, index) => {
          if(index == info.stakeConfigurations.length-1){
            if(t > val.btcValue){
              num = index+1
              setNext(val.btcValue)
            }
          }else{
            let next = info.stakeConfigurations[index + 1]
            if(t>=val.btcValue && t<next.btcValue){
              num = index+1
              setNext(next.btcValue)
              o = (t-val.btcValue)/(next.btcValue -val.btcValue)*0.25 * 100
            }
          }
        })
      }
      let p = num*20 + o
      setProgress(p)
    })
    get('/api/settlement_snapshots').then((res: any) => {
      // TODO: mock
      // res.data = [
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      //   {cycleName: '123',points: '123',reward: '123',},
      // ]
      setSnapshots(res.data || [])
    })
    refreshReward()
  }, [account])

  let refreshReward = () => {
    post('/api/settlement/wallet').then((res: any) => {
      // ‼️ TODO:测试，上线时需要回复
      // res.data ={
      //   totalReward: 0,
      //   reward: 0
      // }
      setReward(res.data.reward || 0)
      setTotalReward(res.data.totalReward || 0)
      let redeemed = (res.data.totalReward - res.data.reward).toFixed(2)
      setRedeemed(Number(redeemed))
    })
  }

  const [invitationCode, setInvitationCode] = useState<string>('');
  const [requestedUser, setRequestedUser] = useState<boolean>(false);
  useEffect(() => {
    if (account) {
      // 设置邀请码
      get('/api/user_info').then((res: any) => {
        setInvitationCode(res.data.invitationCode)
        setRequestedUser(true)
      })
    }
  }, [account])

  let historyClick = (e: React.SyntheticEvent) => {
    setShowHistory(!showHistory)
    setShowMore(false)
    setShowTokenMore(false)
    e.stopPropagation();
  }

  let moreClick = (e: React.SyntheticEvent) => {
    setShowMore(!showMore)
    setShowHistory(false)
    setShowTokenMore(false)
    e.stopPropagation();
  }
  let moreTokenClick = (e: React.SyntheticEvent) => {
    setShowTokenMore(!showTokenMore)
    setShowHistory(false)
    setShowMore(false)
    e.stopPropagation();
  }
  let toStake = () => {
    history.push('/stake')
  }

  let [withdrawLoading, setWithdrawLoading] = useState<boolean>(false)
  let withdraw = () => {
    if(!account){
      return setWalletModal({show: true})
    }
    setWithdrawLoading(true)
    post('/api/settlement/withdraw').then((res: any) => {
      receiptCheck(res.data.hash).then(() => {
        refreshReward()
        setWithdrawLoading(false)
      }).catch(err => {
        throw err
      })
    }).catch((err: any) => {
      setWithdrawLoading(false)
    })
  }
  
  const [codeCopied, setCodeCopied] = useState<boolean>(false)
  const codeCopy = () => {
    try {
      setCodeCopied(true)
      setTimeout(() => setCodeCopied(false), 1000)
    } catch (error) {
      console.error(error)
    }
  }

  let getParams = () => {
    return {
      userTotalBTC,
      info,
      moreClick,
      showMore,
      tokens,
      showTokenMore,
      requestedUser,
      invitationCode,
      next,
      progress,
      toStake,
      moreTokenClick,
      showHistory,
      historyClick,
      snapshots,
      totalReward,
      reward,
      redeemed,
      withdraw,
      withdrawLoading,
      codeCopied,
      codeCopy,
    };
  };
  
  return <div>
    {browser.mobile ? <Mobile params={getParams()}/> : <Desktop params={getParams()}/>}
  </div>
}
export default Index