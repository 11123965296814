import {useCallback, useEffect, useState} from "react";
import createContext from "./createContext";
import { withCookies, useCookies} from 'react-cookie';
import {useWallet} from 'use-wallet'
import {useAccount, useConnect, useConnectors} from "wagmi";
import {BSC_NET} from "../config";

interface ConnectInfo {
    wallet: string
}

interface UseCookie {
    setCustomCookie: any
    removeCustomCookie: any
}

export const [useCookie, CookieProvider] = createContext<UseCookie>('useCookie')

export const useCookieState = (): UseCookie => {
    const { connect, connectors } = useConnect()
    const {address: account} = useAccount();
    const [cookies, setCookie, removeCookie] = useCookies();
    useEffect( () => {
        if (account) {
            return
        }

        // 使用wagmi不需要再自动连接钱包
        if (cookies.connectInfo) {
        }
    }, [account])

    let setCustomCookie = useCallback((wallet: string) => {
        let expiresTimestamp = Date.now() + 7200 * 1000;
        setCookie('connectInfo', {wallet: wallet}, { path: '/', expires:  new Date(expiresTimestamp)})
    }, [])

    let removeCustomCookie = useCallback(() => {
        removeCookie('connectInfo', { path: '/'})
    }, [])

    return {
        setCustomCookie,
        removeCustomCookie
    }
}