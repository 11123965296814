import Mobile from './Pages/Mobile'
import Desktop from './Pages/Desktop'
import { useEffect, useRef, useState } from "react";
import styles from "./Index.module.scss";
import { useRequest } from "../../hooks";
import { useWalletModal } from "../../hooks/useWalletModal";
import {useAccount} from "wagmi";
import {dateFormat} from '../../libs/formatUtil'
import usePlugins from "../../hooks/usePlugins";
import {useBrowser} from '../../hooks/useBrowser'
import { useWeb3Modal } from '@web3modal/wagmi/react'

declare let window: any;
export interface Point {
  date: string
  dateEn: string
  point: number
}
export interface Work {
  costSeconds: number,
  ip: string,
  amount: string
}

export interface Props{
  params: {
    account: string | undefined
    conncet: () => void
    window: any
    quality: number
    userIp: string | undefined
    downloadZip:  () => void
    totalPoint: string
    todayPoint: string
    pointList: Point[]
    workList: Work[]
    getSecondTime: (time:number) => string
  }
}

const Index = () => {
  let browser = useBrowser()
  let { onDataChange } = usePlugins()
  let {address: account} = useAccount();
  let {get} = useRequest()
  let [userIp, setUserIp] = useState<string>()
  let [quality, setQuality] = useState<number>(0)
  let [todayPoint, setTodayPoint] = useState<string>('0.00')
  let [totalPoint, setTotalPoint] = useState<string>('0.00')
  let [pointList, setPointList] = useState<Point[]>([])
  let [workList, setWorkList] = useState<Work[]>([])
  let {setWalletModal} = useWalletModal()
  let {open} = useWeb3Modal()

  useEffect(() => {
    let today = new Date()
    let list:Point[] = []
    for (let index = 0; index < 10; index++) {
      let d = new Date()
      d.setDate(today.getDate() - (9 - index));
      list.push({
        dateEn: `${d.toDateString().split(' ')[1]}${d.getDate()}`,
        date: dateFormat('YYYY-MM-DD', d, 0, false),
        point: 0
      })
    }

    if(account) {
      get('/api/my_points').then((res: any) => {
        setTodayPoint(Number(res.data.todayEarning).toFixed(2))
        setTotalPoint(Number(res.data.totalPoints).toFixed(2))
      })
      get(`/api/points_statistics?endDate=${list[list.length-1].date}&startDate=${list[0].date}`).then((res: any) => {
        // TODO: mock
        // res = {
        //   "success": true,
        //   "data": {
        //       "2024-03-14": {
        //           "date": "2024-03-14",
        //           "points": "528"
        //       },
        //       "2024-03-15": {
        //           "date": "2024-03-15",
        //           "points": "2088"
        //       }
        //   }
        // }
        list.forEach(val => {
          if(res.data[val.date]){
            val.point = Number(Number(res.data[val.date].points).toFixed(2))
          }
        })
        setPointList(list)
      })
      get('/api/network_statistics').then((res: any) => {
        // TODO: mock
        // res.data = [
        //   {
        //     costSeconds: 2911,
        //     ip: '128.113.133.13',
        //     amount: '330.2'
        //   },
        //   {
        //     costSeconds: 93911,
        //     ip: '123.123.133.13',
        //     amount: '2330.2'
        //   },
        //   {
        //     costSeconds: 293911,
        //     ip: '121.122.133.133',
        //     amount: '200.2'
        //   }
        // ]
        setWorkList(res.data)
      })
    }else{
      setPointList(list)
    }
  }, [account])

  useEffect(() => {
    onDataChange(params =>{
      console.log('事件返回：', params)
      setUserIp(params.ip)
      setQuality(params.quality)
    })
  }, [window.camelot])

  let getSecondTime = (time: number) => {
    let D = parseInt(String(time / 86400))
    let H = parseInt(String(time / 3600))
    let M = parseInt(String((time % 3600) / 60))
    let S = parseInt(String(time % 60))

    return `${D}day, ` + `${H}hrs, ` + `${M}mins`
  }
  let conncet = () => {
    open()
  }
  let downloadZip = () => {
    window.open('https://chromewebstore.google.com/detail/camelot/pjjfagejlcghhpehddgeaohjnflpklcc?hl=zh-CN&authuser=0')
  }

  let getParams = () => {
    return {
      account,
      conncet,
      window,
      quality,
      userIp,
      downloadZip,
      totalPoint,
      todayPoint,
      pointList,
      workList,
      getSecondTime,
    }
  }

  return (
    <div>
      {browser.mobile ? <Mobile params={getParams()}/> : <Desktop params={getParams()}/>}
    </div>
  );
};
export default Index;
