import ReactDOM from "react-dom";
import "./index.scss";
import HigherApp from "./HigherApp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";
import {chainConfig, projectId} from "./config";
import { WagmiProvider } from "wagmi";
import { createWeb3Modal } from "@web3modal/wagmi/react"
import { AIlayer, B2, Bitlayer, merlin } from "./libs/chain";


const queryClient = new QueryClient();
let web3modalOptions = {
    wagmiConfig: chainConfig,
    projectId: projectId,
    enableAnalytics: false, // Optional - defaults to your Cloud configuration
    // allWallets: "HIDE",
    includeWalletIds: [
        "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
        "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662",
        "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
        "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709"
    ],
    chainImages: {
      [merlin.id]: merlin.logo,
      [AIlayer.id]: AIlayer.logo,
      [Bitlayer.id]: Bitlayer.logo,
      [B2.id]: B2.logo
    }
    // defaultChain: AIlayer
}

createWeb3Modal(web3modalOptions)

ReactDOM.render(
  <WagmiProvider config={chainConfig}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <HigherApp />
      </Router>
    </QueryClientProvider>
  </WagmiProvider>,
  document.getElementById("root")
);
