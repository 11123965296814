import {useEffect, useState} from "react"
import styles from "./SelectLanguage.module.scss"
import Cookies from "js-cookie";
import {useLanguage} from "../../hooks/useLanuage"
import classNames from "classnames";

  
const SelectLanguage = (props: {closeMenu: () => void}) => {
    let {closeMenu} = props
    const {setLanguage} = useLanguage();
    let languages:any = [
        {
            key: 'en',
            name: 'English'
        },
        {
            key:  'zh',
            name: '華語'
        },
    ]
    const [selected, setSelected] = useState<any>({})
    const [propShow, setPropShow] = useState<boolean>(false)
    // 初始化
    useEffect(() => {
        let key = Cookies.get('language') || languages[0].key
        setSelected(languages.filter((val:any) => val.key == key)[0])
        Cookies.set('language', key)
        setLanguage({
            len: key
        })
        document.body.onclick = () => {
            setPropShow(false)
        }
    }, [])

    const selectedCell = async (val: any) => {
        setSelected(val)
        Cookies.set('language', val.key)
        setLanguage({
            len: val.key
        })
        setPropShow(false)
        closeMenu()
    }

    let openProp = (e: React.SyntheticEvent) => {
        setPropShow(!propShow)
        e.stopPropagation();
    }

    return (
        <div className={styles.view}>
            <div className={styles.btn} onClick={openProp}>
                <img src={`/images/len/${selected.key}.png`} alt="" />
                <div>{selected.name}</div>
                <div></div>
            </div>
            <div className={styles.propView} hidden={!propShow}>
                <div className={styles.prop}>
                    <div className={styles.after}></div>
                    {
                        languages.map((val:any, index:number) => <div className={classNames(styles.cell, val.key == selected.key ? styles.selected : '')} key={index} onClick={() => selectedCell(val)}>                            
                            <img src={`/images/len/${val.key}.png`} alt="" />
                            <div>{val.name}</div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    )
}
export default SelectLanguage