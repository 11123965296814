import { useEffect, useMemo, useState } from "react"
import Icon from "../Icon"
import Tooltip from "../Tooltip"
import styles from "./WalletPop.module.scss"
import { truncate } from "../../libs/text"
import { useCam3lot, useRequest } from '../../hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from "../Button"
import {useCookie} from "../../hooks/useCookie";
import usePlugins from "../../hooks/usePlugins";
import {useAccount, useDisconnect} from "wagmi";
import Cookies from "js-cookie";
import {FormattedMessage} from 'react-intl'
interface Props {
  open: (open: boolean) => void
}

const ConnectedInfo = (props: Props) => {
  let { get } = useRequest()
  let { logout } = usePlugins()
  const { open } = props
  const { disconnect } = useDisconnect()
  const {removeCustomCookie} = useCookie()
  let { address: account } = useAccount()
  const [copied, setCopied] = useState(false)
  const [invitationCode, setInvitationCode] = useState<string>('');
  const { currentChain } = useCam3lot()
  useEffect(() => {
     // 设置邀请码
     if(!account) return
     get('/api/user_info').then((res: any) => {
      setInvitationCode(res.data.invitationCode)
    })
  }, [account])
  const copy = () => {
    try {
      setCopied(true)
      setTimeout(() => setCopied(false), 1000)
    } catch (error) {
      console.error(error)
    }
  }
  const [codeCopied, setCodeCopied] = useState(false)
  const codeCopy = () => {
    try {
      setCodeCopied(true)
      setTimeout(() => setCodeCopied(false), 1000)
    } catch (error) {
      console.error(error)
    }
  }
  let out = () => {
    disconnect();
    removeCustomCookie();
    Cookies.remove('account');
    logout();
    open(false);
  }
  let openMerlin = () => {
    window.open(`${currentChain?.explorerUrl}/address/${account}`)
  }

  return (
    <div className={styles.bg}>
      <div className={styles.card}>
        <div className={styles.wallet}>
          <div className={styles.colse} onClick={() => open(false)}>
            <Icon name="account_close" />
          </div>
          <header className={styles.header}>
            <div className={styles.walletTitle}><FormattedMessage id='YourWallet'/></div>
          </header>
          <div className={styles.accountView}>
            <div className={styles.account}>
              <Icon name="account_balance_wallet" size={24}/>&nbsp;&nbsp;
              <div>{truncate(account || '-', [10, 9])}</div>
            </div>
            <CopyToClipboard
              onCopy={copy}
              text={account || ''}
            >
              <div id="parent">
                <Tooltip
                  content="Copied!"
                  visible={copied}
                  className={styles.button}
                >
                  <Button className={styles.copyAddress}>
                    <Icon style={{color: ''}} size={24} name="file_copy"></Icon>
                  </Button>
                </Tooltip>
              </div>
            </CopyToClipboard>
          </div>
          <section className={styles.info}>
            <div onClick={openMerlin}>
              <div style={{color: '#F36C17'}}><FormattedMessage id='ViewonMerlin'/></div>
              <Icon style={{color: '#F36C17'}} size={14} name="open_in_new" />
            </div>
            {
              !!invitationCode && <div>
                <div><FormattedMessage id='agent10'/></div>
                <div className={styles.code}>{invitationCode}</div>
                <CopyToClipboard
                  onCopy={codeCopy}
                  text={invitationCode}
                >
                  <div id="parent">
                    <Tooltip
                      content="Copied!"
                      visible={codeCopied}
                      className={styles.button}
                    >
                      <Icon style={{color: '#F36C17'}} size={12} name="file_copy"></Icon>
                    </Tooltip>
                  </div>
                </CopyToClipboard>
              </div>
            }
          </section>
          <div className={styles.btns}>
            <Button outline onClick={out}>
            <FormattedMessage id='DisconnectWallet'/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectedInfo
