import React, { useEffect, useMemo, useState } from 'react'
import './History.scss'
import './index.scss'
import { FormattedMessage } from 'react-intl'
import { ethers } from 'ethers'
import { B2, Bitlayer, b2ChainName, chain } from '../../libs/chain'
import { clotStakeAbi } from '../../chain/clotStake'
import { web3ProviderFrom } from '../../chain/ether-utils'
import { dateFormat } from '../../libs/formatUtil'
import { CircularProgress, Dialog, Snackbar } from '@material-ui/core'
import moment, { Moment } from 'moment'
import BigNumber from 'bignumber.js'
import { useRequest } from '../../hooks'
import Loading from '../../components/Loading'
// 导入新增的依赖
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { chains } from '../../libs/chain';
import Result, {ResultItem, STATUS} from "../../components/Result";
import { useWeb3Modal } from '@web3modal/wagmi/react';

interface historyItem {
  chain: string
  chainId: number
  stakeIndex: number
  amount: string
  startTime: string
  endTime: string,
  orderId: string,
  chainName: string,
  claimed: boolean; // 新增属性
}

interface claimedItem {
  "id": string,
  "createdAt": number,
  "type": string,
  "chain": string,
  "amount": string,
  "date": string
}
const History = () => {

  const { open } = useWeb3Modal()
  let { address: account, chainId, connector } = useAccount();
  const { data: hash, writeContract, error } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash });
  const [data, setdata] = useState<any>([])
  const [dailyRewardsOpen, setdailyRewardsOpen] = useState(false)
  const [lockTime, setlockTime] = useState(0)
  const [activeData, setactiveData] = useState<historyItem | null>(null)
  const [utcTime, setutcTime] = useState(moment().utcOffset(0))
  const [claimedList, setclaimedList] = useState<claimedItem[]>([])
  let { get, post } = useRequest();
  const [loading, setloading] = useState(false)
  const [snackbarStatus, setSnackbarStatus] = useState<{
    type: "success" | 'failed'
  } | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)

  // 定义交易状态的类型
  const [transactionInfo, setTransaction] = useState<ResultItem>({
    show: false,
    hash: 'Hash Code',
    status: STATUS.LOADING
  });

  const fetchHistory = async (chain: chain) => {
    const provider = new ethers.providers.Web3Provider(
      web3ProviderFrom(chain.chainRpc),
      chain.id,
    )
    if (chain?.clotStakeContractAddress && account) {
      const instance = new ethers.Contract(chain?.clotStakeContractAddress, clotStakeAbi, provider);
      const result = await instance.getStakes(account)
      const lockTime = await instance.LOCK_TIME()
      setlockTime(7)
      return result.map((val: any, index: number) => {
        return {
          chain: chain.name === 'B2' ? b2ChainName : chain.name,
          chainName: chain.name,
          chainId: chain.id,
          stakeIndex: index,
          orderId: val.orderId.toString(),
          amount: ethers.utils.formatEther(val.amount.toString()),
          startTime: dateFormat("YYYY/MM/DD HH:mm", new Date(val.timestamp.toNumber() * 1000), 0, false),
          endTime: dateFormat("YYYY/MM/DD HH:mm", new Date(val.timestamp.add(lockTime).toNumber() * 1000), 0, false),
          claimed: val.claimed, // 新增属性
        } as historyItem
      })
    }
  }
  useEffect(() => {
    get('/api/activity2/current_timestamp').then((res: {
      data: {
        timestamp: number
      }
    }) => {
      res.data && setutcTime(moment(res.data.timestamp))
    })
  }, [])

  useEffect(() => {
    if (account) {
      Promise.all([fetchHistory(B2), fetchHistory(Bitlayer)]).then((res: any) => {
        console.log(res[0])
        console.log(res[1])
        setdata([...res[0], ...res[1]])
      })
    }
    // eslint-disable-next-line
  }, [account])
  const findClaimedList = (val: any) => {
    return get('/api/activity2/prizes', {
      orderId: val.orderId,
      chain: val.chainName
    }).then((res: {
      data: claimedItem[]
    }) => {
      if (res.data) {
        setclaimedList(res.data)
      }
    })
  }

  const openDailyRewards = async (val: historyItem) => {
    setactiveData(val)
    await findClaimedList(val)
    setdailyRewardsOpen(true)
  }

  const dailyRewardsList = useMemo(() => {
    if (activeData) {
      return new Array(lockTime).fill(undefined).map((val, index) => {
        const startTime = moment(activeData?.startTime).format('YYYY-MM-DD 00:00:00')
        const nextTime = moment(startTime).add(index + 1, 'day')
        const findClained = claimedList.find(val => moment(val.date).format('YYYY-MM-DD') === nextTime.format('YYYY-MM-DD'))
        return {
          nextTime,
          ...findClained
        }
      })
    }
    return []
  }, [lockTime, activeData, claimedList])

  const claimDailyRewards = (val: any, index: number) => {
    setloading(true)
    post('/api/activity2/draw', {
      "chain": activeData?.chainName,
      "orderId": activeData?.orderId,
      "day": index + 1
    }).then((res: any) => {
      setSnackbarOpen(true)
      setSnackbarStatus({
        type: 'success',
      })
      findClaimedList(activeData)
    }).catch(() => {
      setSnackbarOpen(true)
      setSnackbarStatus({
        type: 'failed'
      })
    }).finally(() => {
      setloading(false)
    })

  }

  // 定义 handleClaimClick 方法，添加参数类型
  const handleClaimClick = async (claimChainId: number, stakeIndex: number) => {
    console.log('Input chain:', chainId);
    console.log('Available chains:', chains);

    const findChain = chains.find((c) => c.id == chainId);

    console.log('Found chain:', findChain);

    const clotStakeContractAddress = findChain?.clotStakeContractAddress;
    console.log('Chain IDs:', findChain?.id, chainId);

    if (findChain?.id !== claimChainId) {
      open({
        view: 'Networks'
      });
      return;
    }

    console.log('writeContract param:', clotStakeContractAddress, stakeIndex);
    if (clotStakeContractAddress) {
      writeContract({
        abi: clotStakeAbi,
        chainId: findChain?.id,
        // @ts-ignore
        address: clotStakeContractAddress,
        functionName: 'claim',
        connector: connector,
        args: [stakeIndex],
      });
    }
  }

  useEffect(() => {
    if (isConfirming) {
      setTransaction({
        show: true,
        hash,
        status: STATUS.LOADING,
      });
    } else if (!isConfirming && isConfirmed) {
      setTransaction({
        show: true,
        hash,
        status: STATUS.SUCCESS,
      });
    } else if (!isConfirming && !!error) {
      console.log(error);
      setTransaction({
        show: true,
        hash,
        status: STATUS.FAILURE,
      });
    }
  }, [isConfirming, error, hash, isConfirmed]);

  if (transactionInfo.show) {
    return <Result
        resultItem={transactionInfo}
        btnClick={() => {
          window.location.reload()
        }}
    />
  }


  return (
    <div className='campaign campaign-history'>
      <div className='campaign-bg'>
        <div className='mmd:hidden'>
          <img src="/images/campaign/pc-bg.png" alt="" className='block w-full campaign-image' />
        </div>
        <div className='md:hidden'>
          <img src="/images/campaign/mobile-bg.png" alt="" className='block w-full campaign-image' />
        </div>
        <div className='campaign-bg-content'>
          <span className="text1"><FormattedMessage id='joinDefi' /></span>
          <span className="text2"><FormattedMessage id='defi2Pin' /></span>
          <span className="text1"><FormattedMessage id='movement' /></span>
        </div>
      </div>
      {/* pc */}
      <div className='campaign-container relative mmd:hidden'>
        <img src="/images/campaign/history-content-bg.png" alt="" className='w-full' />
        <div className='campaign-container-content absolute campaign-container-bg'>
          <div className='relative'>
            <p className='your-history'>
              <FormattedMessage id='YourHistory' />
            </p>
            <div className='table-header'>
              <div className='table-row table-row-chain'><FormattedMessage id='stakeH0' /></div>
              <div className='table-row'><FormattedMessage id='startTime' />(UTC)</div>
              <div className='table-row'><FormattedMessage id='endTime' />(UTC)</div>
              <div className='table-row'><FormattedMessage id='dailyRewards' /></div>
              <div className='table-row'><FormattedMessage id='ultimatePrize' /></div>
            </div>
            {data.map((val: historyItem, index: number) => (
                <div className='table-content' key={index}>
                  <div className='table-row table-row-chain'>{val.chain}</div>
                  <div className='table-row'>{val.startTime}</div>
                  <div className='table-row'>{val.endTime}</div>
                  <div className='table-row-open'>
                    <div className='tag tag-open' onClick={() => openDailyRewards(val)}>
                      <FormattedMessage id='open' />
                    </div>
                  </div>
                  <div className='table-row-open'>
                    <div className={`tag tag-open ${val.claimed ? 'border-none' : ''}`}
                        onClick={!val.claimed ? () => handleClaimClick(val.chainId, val.stakeIndex) : undefined}
                        style={{ cursor: !val.claimed ? 'pointer' : 'not-allowed' }}>
                      {!val.claimed ? <FormattedMessage id='ultimatePrize' /> : '1 Watch & 23000 CLOT'}
                    </div>
                  </div>
                </div>
            ))}

            {data.length === 0 && <div className='none-icon'>
              <img src="/images/static/none_icon.png" alt="" />
            </div>}

          </div>
        </div>
      </div>
      {/* mobile */}
      <div className='campaign-container relative md:hidden'>
        <img src="/images/campaign/m-history-content-bg.png" alt="" className='w-full absolute' />
        <div className='campaign-box'>
          <p className='your-history'>
            <FormattedMessage id='YourHistory' />
          </p>
          {data.map((val: historyItem, index: number) => (
              <div className='history-item' key={index}>
                <div className='history-item-row'>
                  <div>
                    <div className='chain-row'>{val.chain}</div>
                  </div>
                  <div className='time-row'>
                    <span className='time-row-label'><FormattedMessage id='startTime'/>(UTC)</span>
                    <span className='time-row-time'>{val.startTime}</span>
                  </div>
                  <div className='time-row'>
                      <span className='time-row-label'><FormattedMessage id='endTime'/>(UTC)</span>
                    <span className='time-row-time'>{val.endTime}</span>
                  </div>
                </div>
                <div className='history-item-row history-tag-row'>
                  <div className='history-item-row-box history-item-image-box'>
                    <img src='/images/campaign/clot-box.png' alt='' className='history-item-image'/>
                  </div>
                  <div className='history-item-row-box' onClick={() => openDailyRewards(val)}>
                    <div className='tag tag-open'>
                      <FormattedMessage id='dailyRewards'/>
                    </div>
                  </div>
                  <div className='history-item-row-box'
                      onClick={!val.claimed ? () => handleClaimClick(val.chainId, val.stakeIndex) : undefined}
                      style={{cursor: !val.claimed ? 'pointer' : 'not-allowed'}}>
                    <div className={`tag tag-open ${val.claimed ? 'border-none' : ''}`}>
                      {!val.claimed ? <FormattedMessage id='ultimatePrize'/> : '1 Watch & 23000 CLOT'}
                    </div>
                  </div>
                </div>
              </div>
          ))}


          {data.length === 0 && <div className='none-icon'>
            <img src="/images/static/none_icon.png" alt="" />
          </div>}

        </div>
      </div>
      <Dialog open={dailyRewardsOpen} onClose={() => setdailyRewardsOpen(false)}>
        <div className='dialog-content'>
          <div className='table-header'>
            <div className='table-row'><FormattedMessage id='stakeH1' /></div>
            <div className='table-row'><FormattedMessage id='dailyRewards' /></div>
          </div>
          {dailyRewardsList.map((val, index: number) => {
            return <div className='table-content'>
              <div className='table-row'><FormattedMessage id='days' values={{ n: index + 1 }} /></div>
              <div className='table-row'>
                {val.date ? <div className='dialog-claimed'>+ {val.amount} {val.type === 'POINT' ? 'Points' : 'CLOT'}</div> : BigNumber(utcTime.unix()).isGreaterThanOrEqualTo(val.nextTime.unix()) ? <div className='dialog-tag' onClick={() => {
                  claimDailyRewards(val, index)
                }}>
                  {loading && <CircularProgress color="inherit" size={14} className="openLoading" />}
                  <FormattedMessage id='open' />
                  </div>
                  : <div className='dialog-tag waiting'>Waiting</div>}
              </div>
            </div>
          })}
        </div>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => {
          setSnackbarOpen(false)
          setSnackbarStatus(null)
        }}
        message={<div className='snackbar-status'>
          <img src={`/images/static/${snackbarStatus?.type === 'success' ? 'success_icon' : 'error_icon'}.png`} alt="" className='snackbar-status-img' />
          {snackbarStatus?.type === 'success' ? 'success' : 'failed'}
        </div>}
      />
    </div>
  )
}

export default History