import App from "./App";
import { useLanguageState, LanguageProvider } from "./hooks/useLanuage";

function HigherApp() {
  const languageState = useLanguageState();
  return (
    <LanguageProvider value={languageState}>
      <App />
    </LanguageProvider>
  );
}

export default HigherApp;
