import JSBI from 'jsbi';
import BigNumber from "bignumber.js";
import {config} from "../config";
import moment from 'moment';

/** 获取友好展示的数值 */
export const getFullDisplayBalance = (balance: string, decimals = 18, fractionDigits = 3) => {
    return Number(getDisplayBalance(balance, decimals, fractionDigits)).toString();
};

export const getDisplayBalance = (balance: string, decimals = 18, fractionDigits = 3) => {
    if (!balance) return "0";
    if (Number(balance) === 0) return "0";

    const number = getBalance(balance, decimals - fractionDigits);
    return (number / 10 ** fractionDigits).toFixed(fractionDigits);
};

export function getBalance(balance: string, decimals = 18): number {
    let b = JSBI.BigInt(balance);
    let d = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals));
    return JSBI.toNumber(JSBI.divide(b, d));
}

/** 获取高精度的数值 */
export const getHighPrecisionVaule = (amount: number, decimals = 18) => {
    // 消除小数
    let base = String(JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals)));
    let b = new BigNumber(amount).multipliedBy(base).toString(10);
    if (Number(b) < 1) {
        return '0'
    }
    return b;
}

/** 获取抵押率 */
export const getBuildRadio = (buildAmount: string, buildPrice: string, buildOutAmount: string, buildOutPrice: string): number => {
    const j1 = JSBI.multiply(JSBI.BigInt(buildAmount), JSBI.BigInt(buildPrice));
    const j2 = JSBI.multiply(j1, JSBI.BigInt(100));
    const j3 = JSBI.multiply(JSBI.BigInt(buildOutAmount), JSBI.BigInt(buildOutPrice));
    if (JSBI.toNumber(j3) === 0) {return 0};
    const j4 = JSBI.divide(j2, j3);
    return JSBI.toNumber(j4);
}

/** 处理返回的错误 */
export const handleChainErr = (err: string) => {
    if (err) {
        let errMsg = err.split(':');
        if (errMsg.length > 0) {
            return errMsg[errMsg.length-1]
        } else {
            return "Internal error"
        }
    }

    return "Internal error"
}


export const dateFormat = (fmt:string, date:any, utc = 0, hasUtcTxt: boolean = true) =>  {
    date = moment(new Date(date)).utcOffset(utc);
    let timeZone = ''
    switch (utc) {
        case 0:
            timeZone = hasUtcTxt ? ' (UTC)' : ''
            break;
        default:
            break;
    } 
    return date.format(`${fmt}${timeZone}`)
    // YYYY.MM.DD HH:mm

    // let ret;
    // const opt:any = {
    //   'Y+': date.getFullYear().toString(),        // 年
    //   'm+': (date.getMonth() + 1).toString(),     // 月
    //   'd+': date.getDate().toString(),            // 日
    //   'H+': date.getHours().toString(),           // 时
    //   'M+': date.getMinutes().toString(),         // 分
    //   'S+': date.getSeconds().toString()          // 秒
    // };
    // for (const k in opt) {
    //   ret = new RegExp('(' + k + ')').exec(fmt);
    //   if (ret) {
    //     fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')));
    //   }
    // }
    // return fmt;
};

export function addCommas(num:number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}