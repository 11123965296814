import styles from "./Desktop.module.scss";
import Icon from "../../../../components/Icon";
import { dateFormat } from "../../../../libs/formatUtil";
import { getFullDisplayBalance } from "../../../../libs/formatUtil";
import { truncate } from "../../../../libs/text";
import { Props } from "../../History";
import { FormattedMessage } from "react-intl";
import { B2, b2ChainName } from "../../../../libs/chain";

const Index = (props: Props) => {
  let { list, tokensObj, account, back } = props.params;

  return (
    <div className={styles.page}>
      <div className={styles.bg}>
        <div className={styles.content}>
          <div className={styles.images}>
            <img src="/images/other/stake_1.png" alt="" />
            <img src="/images/other/stake.png" alt="" />
          </div>
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.list}>
          <div>
            <div><FormattedMessage id="stakeH0" /></div>
            <div><FormattedMessage id="stakeH1" /></div>
            <div><FormattedMessage id="stakeH2" /></div>
            <div><FormattedMessage id="stakeH3" /></div>
            <div><FormattedMessage id="stakeH4" /></div>
            <div><FormattedMessage id="stakeH5" /></div>
            <div><FormattedMessage id="stakeH8" /></div>
          </div>

          {list.length > 0 && Object.keys(tokensObj).length > 0 ? (
            list.map((val: any, index: number) => {
              console.log("val:", val);
              return (
                <div className={styles.cellview} key={index}>
                  <div className="flex">
                    <img src={val.chain.logo} alt="" />
                    {val.chain.name === 'B2' ? b2ChainName : val.chain.name}
                  </div>
                  <div>
                    {dateFormat("YYYY/MM/DD HH:mm", new Date(val.date * 1000))}
                  </div>
                  <div>
                    <img src={tokensObj[val.token].icon} alt="" />
                    <div>{tokensObj[val.token].name}</div>
                  </div>
                  <div>{val.action == 1 ? "Stake" : "UnSatke"}</div>
                  <div>{getFullDisplayBalance(val.amount, 18, 5)}</div>
                  <div>{truncate(account || "-", [4, 3])}</div>
                  <div>
                    <Icon name="check_circle" />
                  </div>
                </div>
              );
            })
          ) : (
            <div className={styles.none}>
              <img src="/images/static/none_icon.png" alt="" />
            </div>
          )}
        </div>
        <div className={styles.btn} onClick={() => back()}>
          <FormattedMessage id="stakeH7" />
          <Icon size={20} name="call_made"></Icon>
        </div>
      </div>
    </div>
  );
};
export default Index;
