import { useState, useEffect, ReactNode, useRef } from "react";
import classNames from "classnames";
import styles from "./Team.module.scss";
import Dialog from "@material-ui/core/Dialog";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { useCam3lot, useRequest } from "../../hooks";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../hooks/useLanuage";
import { useLocation } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import InputOTP from "./InputOTP";
import usePlugins from "../../hooks/usePlugins";
import { useAccount, useDisconnect } from "wagmi";
import { signMessage } from "@wagmi/core";
import { chainConfig } from "../../config";
import { useCookie } from "../../hooks/useCookie";
import { useBrowser } from "../../hooks/useBrowser";
import {SAFE_WALLET} from "../../constants";

declare let window: any;
enum Role {
  Leader = "Leader",
  Knight = "Knight",
}

const Index = () => {
  let browser = useBrowser();
  let { address: account } = useAccount();
  const { disconnect } = useDisconnect();
  const { removeCustomCookie } = useCookie();
  let { login, onLogout } = usePlugins();
  // 入团队逻辑
  const history = useHistory();
  const { language } = useLanguage();
  const [addOpen, setAddOpen] = useState(false);
  const [invitationCode, setInvitationCode] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [joinLoading, setJoinLoading] = useState(false);
  let { get, normalGet, post } = useRequest();
  const { pathname } = useLocation();
  const loadingRef = useRef(loading);

  useEffect(() => {
    // 首次进入页面
    if (!account) return;

    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);

    // 检查地址
    post("/api/check", {
      address: account,
    })
      .then((res: any) => {
        if (!res.data.registered) {
          if (pathname == "/") {
            throw "";
          } else {
            loadingRef.current = false;
            setLoading(false);
            setAddOpen(true);
            throw "";
          }
        } else if (!res.data.tokenIsValid) {
          if (pathname == "/") {
            throw "";
          } else {
            return SAFE_WALLET.indexOf(account) == -1 ? signMessage(chainConfig, { message: "Login" }) : "ignore_sign";
          }
        } else {
          // 登录插件
          login();
          // 监听退出
          onLogout(() => {
            removeCustomCookie();
            Cookies.remove("account");
            disconnect();
          });
          throw "";
        }
      })
      .then((res: string) => {
        return post("/api/login", {
          data: "Login",
          address: account,
          signMessage: res,
        });
      })
      .then((res: any) => {
        Cookies.set(`t-${account}`, res.data.accessToken || "", {
          secure: true,
          sameSite: "none"
        });
        window.location.reload();
      })
      .finally(() => {
        loadingRef.current = false;
        setLoading(false);
      })
      .catch((err: any) => {
        let text = err instanceof Object ? err.message : err;
        if (text) {
          setMessageOpen(true);
          setErrorMessage(text.toString());
        }

        loadingRef.current = false;
        setLoading(false);
      });
  }, [account, pathname]);

  let [value, setValue] = useState<string>("");
  let valueChange = (value: string) => {
    setValue(value);
  };

  let create = async () => {
    setJoinLoading(true);
    let obj: any = {
      role: roleType == Role.Leader ? 1 : 2,
    };
    if (value) obj.invitationCode = value;
    let json = JSON.stringify(obj);
    let res = SAFE_WALLET.indexOf(String(account)) == -1 ? await signMessage(chainConfig, { message: json }) : "ignore_sign";
    console.log("sign message:", res);
    await post("/api/register", {
      address: account,
      data: json,
      signMessage: res,
    })
      .then((res: any) => {
        Cookies.set(`t-${account}`, res.data.accessToken || "", {
          secure: true,
          sameSite: "none"
        });
        window.location.reload();
        setAddOpen(false);
      })
      .catch((e: any) => {
        console.log("create error:", e);
      });
    setJoinLoading(false);
  };

  let closeDialog = () => {
    setRoleType(undefined);
    if (pathname == "/") {
      setAddOpen(false);
    } else {
      history.push("/");
      setAddOpen(false);
    }
  };

  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setMessageOpen(false);
  };

  let [selectRoleShow, setSelectRoleShow] = useState<boolean>(false);
  let selectRole = () => {
    setSelectRoleShow(true);
  };
  let [roleType, setRoleType] = useState<Role>();
  // useEffect(() => {
  //   if(browser.mobile){
  //     setRoleType(Role.Leader)
  //   }
  // }, [])
  let setRole = (type: Role) => {
    setRoleType(type);
    setSelectRoleShow(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={messageOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <div className={styles.errorMessage}>{errorMessage}</div>
      </Snackbar>
      {/* pc / mobile */}
      {browser.desktop ? <Dialog aria-labelledby="simple-dialog-title" open={addOpen}>
        <div className={styles.content}>
          {
            <div className={styles.close} onClick={closeDialog}>
              <Icon className={styles.close} name="close" size={28} />
            </div>
          }
          <div className={styles.modalTitle}><FormattedMessage id='CreateAgent'/></div>
          <div className={classNames(styles.modalContent)}>
            <div className={styles.step}>
              <div className={styles.step1}>
                <div>
                  <div><FormattedMessage id='FIRSTSTEP'/></div>
                  <Icon size={30} name="arrow_right_alt"></Icon>
                </div>
                <div className={styles.step1Info}>
                  <div className={styles.selectRole}>
                    <div onClick={selectRole}>
                      {roleType
                        ? (
                          <>
                            <FormattedMessage id='CurrentRole'/>
                            <FormattedMessage id={roleType}/>
                          </>
                        )
                        : <FormattedMessage id='ChooseYourRole'/>}
                    </div>
                    <img
                      onClick={selectRole}
                      src="/images/other/role_s.png"
                      alt=""
                    />
                    {selectRoleShow && (
                      <div className={styles.popSelect}>
                        <div onClick={() => setRole(Role.Leader)}>
                          <div><FormattedMessage id='Leader'/></div>
                          <div>
                            <FormattedMessage id='ExcellentLeader'/>
                          </div>
                        </div>
                        <div onClick={() => setRole(Role.Knight)}>
                          <div><FormattedMessage id='Knight'/></div>
                          <div>
                            <FormattedMessage id='BraveKnight'/>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {roleType == Role.Leader && (
                    <div className={styles.code}>
                      <div><FormattedMessage id='getaninvite'/></div>
                      <div>{invitationCode}</div>
                    </div>
                  )}
                  {roleType == Role.Knight && (
                    <div className={styles.invite}>
                      <div><FormattedMessage id='invitecode'/></div>
                      <div>
                        <InputOTP valueChange={valueChange} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.step2}>
                <div>
                  <div><FormattedMessage id='SECONDSTEP'/></div>
                  <Icon size={30} name="arrow_right_alt"></Icon>
                </div>
                <div>
                  <Button
                    className={styles.confim}
                    loading={joinLoading}
                    disabled={
                      roleType
                        ? roleType == Role.Knight
                          ? value.length < 6
                          : false
                        : true
                    }
                    onClick={create}
                  >
                    <FormattedMessage id='Create'/>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog> :
      <Dialog aria-labelledby="simple-dialog-title" open={addOpen}>
        <div className={styles.moblileContent}>
          <div className={styles.close} onClick={closeDialog}>
            <Icon className={styles.close} name="close" size={28} />
          </div>
          <div className={styles.title}><FormattedMessage id='CreateAgent'/></div>
          <div className={styles.subtitle}>
            <FormattedMessage id='ChooseYourRole'/>
            <img src="/images/other/downIcon.png" alt="" />
          </div>
          <div className={styles.subContent}>
            <div className={classNames(styles.cell, roleType == Role.Leader ? styles.selected : '') } onClick={() => setRole(Role.Leader)}>
              <div><FormattedMessage id='Lord'/></div>
              <div>
                <div><FormattedMessage id='ExcellentLeader'/></div>
              </div>
            </div>
            <div className={classNames(styles.cell, roleType == Role.Knight ? styles.selected : '')} onClick={() => setRole(Role.Knight)}>
              <div><FormattedMessage id='Knight'/></div>
              <div>
                <div><FormattedMessage id='BraveKnight'/></div>
              </div>
            </div>
            {roleType == Role.Leader && (
              <div className={styles.code}>
                <div><FormattedMessage id='getaninvite'/></div>
                <div>{invitationCode}</div>
              </div>
            )}
            {roleType == Role.Knight && (
              <div className={styles.invite}>
                <div><FormattedMessage id='Enterinvite'/></div>
                <div>
                  <InputOTP valueChange={valueChange} />
                </div>
              </div>
            )}
          </div>
          <Button
            className={styles.confim}
            loading={joinLoading}
            disabled={
              roleType
                ? roleType == Role.Knight
                  ? value.length < 6
                  : false
                : true
            }
            onClick={create}
          >
            <FormattedMessage id='Create'/>
          </Button>
        </div>
      </Dialog>}
    </>
  );
};

export default Index;
