import en from './len_en';
import zh from './len_zh';

const formatLanguagePackage = (data) => { // 用来将json转化成a.b.c这种对象
  const currentData = {}
  const destructionObject = (prefix, obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (typeof value === 'string') {
          currentData[`${prefix}${key}`] = value
        } else if (typeof value === 'object') destructionObject(`${prefix}${key}.`, value)
      }
    }
  }
  destructionObject('', data)
  return currentData
}

export default {
  en: formatLanguagePackage(en),
  zh: formatLanguagePackage(zh),
}