import { FC } from "react"
import classNames from "classnames"
import styles from "./Container.module.scss"
import Foot from "./Foot"
import { useLocation } from 'react-router-dom';
interface Props {
  className?: string
  sm?: boolean
  children?: React.ReactNode
}

const Container: FC<Props> = ({ children, className, sm }) => {
  const { pathname } = useLocation()
  return <div className={styles.containerView}>
    <div className={classNames(!sm ? styles.container : styles.sm, className, ['/funPIN', '/funPIN_history'].includes(pathname) ? 'no-style' : '')}>
      {children}
    </div>
    {
      pathname == '/' && <Foot/>
    }
  </div>
}

export default Container
