
import styles from "./Foot.module.scss"
import {useHistory} from "react-router-dom"
import classNames from "classnames"
import {useBrowser} from '../hooks/useBrowser'
import {FormattedMessage} from 'react-intl'
import { useState } from "react"

const Foot = () => {
  let browser = useBrowser()
  const history = useHistory()
  const toLink = (type: string) => {
    let links:any = {
      Discord: 'https://discord.gg/CamelotLayer3',
      Twitter: 'https://x.com/CamelotLayer3'
    }
    window.open(links[type])
  }

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className={styles.footView}>
      <div className={styles.view}>
        <div className={styles.left}>
          <div className={styles.managed}>
            <FormattedMessage id='Managed'/>
          </div>
          <img className={styles.logo} src="/images/static/header_logo.png" alt="" />
          <div className={styles.icons}>
            <img src={ browser.mobile ? '/images/other/Discord_m.png' : "/images/other/Discord.png"  } onClick={() => toLink('Discord')} alt="" />
            <img src={ browser.mobile ? '/images/other/Twitter_m.png' : "/images/other/Twitter.png"  } onClick={() => toLink('Twitter')} alt="" />
            {/* <img src="/images/other/GitHub.png" alt="" /> */}
            {/* <img src="/images/other/Telegram.png" alt="" /> */}
          </div>
        </div>
        <div className={classNames(styles.menu, styles.Camelot)}>
          <div className={styles.title}><FormattedMessage id='Camelot'/></div>
          <div className={styles.cell} onClick={() => history.push('/')}><FormattedMessage id='Home'/></div>
          <div className={styles.cell} onClick={() => history.push('/agent')}><FormattedMessage id='Agent'/></div>
          <div className={styles.cell} onClick={() => history.push('/stake')}><FormattedMessage id='Stake'/></div>
          <div className={styles.cell} onClick={() => history.push('/network')}><FormattedMessage id='Data'/></div>
          <div className={styles.cell} onClick={() => window.open("https://testscan.cam3lot.io/")}><FormattedMessage id='Testnet'/></div>
          <div className={styles.cell} onClick={() => window.open("https://camelot-protocol.gitbook.io/camelot")}><FormattedMessage id='Docs'/></div>
        </div>
        <div className={classNames(styles.menu, styles.Connected)}>
          <div className={styles.title}><FormattedMessage id='GetConnected'/></div>
          <div className={styles.cell} onClick={() => toLink('Discord')}><FormattedMessage id='Discord'/></div>
          <div className={styles.cell} onClick={() => toLink('Twitter')}><FormattedMessage id='Twitter'/></div>
        </div>
      </div>
    </div>
  )
}
export default Foot