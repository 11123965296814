import Mobile from './Pages/Index/Mobile'
import Desktop from "./Pages/Index/Desktop";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "../../hooks";
import BigNumber from "bignumber.js";
import { replaceState, getUrlQuery } from '../../libs/utils';
import {
  getHighPrecisionVaule,
  getFullDisplayBalance,
} from "../../libs/formatUtil";
import { ResultItem, STATUS } from "../../components/Result";
import { StakeABI } from "../../chain/contract";
import {useBrowser} from '../../hooks/useBrowser'
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import {BTC_ADDRESS} from "../../constants";
import {ethers} from "ethers";
import { StakeInfoItem } from '../../chain/Cam3lot';
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { chain, chains } from '../../libs/chain';
import { Cam3lotProvider } from '../../chain/Cam3lot-new';

interface Token {
  address: string;
  icon: string;
  name: string;
}

export enum Type {
  stake = 'stake',
  unStake = 'unStake'
}

export interface Props {
  params: {
    assetPop: boolean;
    setAssetPop: (val: boolean) => void;
    tokens: Token[];
    currentAsset: Token | undefined;
    transactionInfo: ResultItem;
    networkPop: boolean;
    balance: string;
    value: string;
    free: string;
    timeLoading: boolean;
    isConfirming: boolean;
    stake: (address: string) => void;
    toTeam: () => void;
    toHistory: () => void;
    selectAsset: (val: Token) => void;
    refresh: () => void;
    setCurrentNetwork: React.Dispatch<React.SetStateAction<chain>>;
    currentNetwork: chain;
    valueChange: (val: string) => void;
    networks: chain[];
    selNetClick: (e: React.SyntheticEvent) => void;
    invitationCode: string;
    codeCopied: boolean;
    codeCopy: () => void;
    type: any;
    setType: any; 
    unStake: any;
    setMax: any;
    account: any;
  };
}

const Index = () => {
  let browser = useBrowser()
  let [type, setType] = useState<Type>(Type.stake)
  const { data: hash, writeContract, error } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({ hash });
  const { address: account, connector, chainId} = useAccount();
  // const { cam3lot } = useCam3lot();
  let { get } = useRequest();
  let [networkPop, setNetworkPop] = useState<boolean>(false);
  let [assetPop, setAssetPop] = useState<boolean>(false);
  let [currentAsset, setCurrentAsset] = useState<Token>();
  let [currentNetwork, setCurrentNetwork] = useState<chain>(chains[0]);
  const [cam3lot, setcam3lot] = useState<Cam3lotProvider>(new Cam3lotProvider(chains[0]))
  const { open } = useWeb3Modal()
  const [tokens, setTokens] = useState<Token[]>([]);
  let [feeRatio, setFeeRatio] = useState<number>(0);

  useEffect(() => {
    document.body.addEventListener("click", () => {
      setNetworkPop(false);
    });
  }, []);
  
  const fetchTokens = async () => {
    const tokenRes = await get("/api/tokens", {
      chainName: currentNetwork.name
    })
    const list = tokenRes.data.list
    return list
  }
  const initProvider = async () => {
    try {
      const cam3lotProvider = new Cam3lotProvider(currentNetwork)
      setcam3lot(cam3lotProvider)
      setTokens([])
      const tokenList: Token[] = await fetchTokens()
      if(tokenList.length) {
        setTokens([...tokenList])
        setCurrentAsset(tokenList[0]);
      }
    } catch (error) {
      console.log(error, '31321')
    }
  }

  useEffect(() => {
    initProvider()
    // eslint-disable-next-line
  }, [currentNetwork])
  

  let history = useHistory();
  let toHistory = () => {
    history.push("/stake_history");
  };
  let toTeam = () => {
    history.push("/stake_team");
  };
  let selectAsset = (val: Token) => {
    setCurrentAsset(val);
    setTimeout(() => {
      setAssetPop(false);
    }, 200);
  };

  useEffect(() => {
    let query = getUrlQuery(window.location.href)
    if(query.type){
      setType(query.type)
    }
  }, [])

  useEffect(() => {
    if (isConfirming) {
      setTransaction({
        show: true,
        hash,
        status: STATUS.LOADING,
      });
    } else if (!isConfirming && isConfirmed) {
      setTransaction({
        show: true,
        hash,
        status: STATUS.SUCCESS,
      });
    } else if (!isConfirming && !!error) {
      setTransaction({
        show: true,
        hash,
        status: STATUS.FAILURE,
        // @ts-ignore
        error: error.details,
      });
    }
  }, [isConfirming, error, hash, isConfirmed]);


  useEffect(() => {
    if (!cam3lot) return;
    cam3lot.getFeeRatio().then((res: number) => {
      setFeeRatio(res);
    });
  }, [cam3lot]);

  let [balance, setBalance] = useState<string>("0");
  let [hightBalance, setHightBalance] = useState<string>("");
  
  useEffect(() => {
    replaceState(window.location.pathname, {type: type})
    if(type === Type.stake){
      if (currentAsset && account) {
        if (currentAsset.name !== "BTC") {
          cam3lot
              .getTokenBalanceOfUser(currentAsset?.address, account)
              .then((res) => {
                // TODO
                // res = '83330823123131413131'
                console.log("balance res:", res);
                setBalance(getFullDisplayBalance(res, 18, 5));
                setHightBalance(res)
              });
        } else {
          if(account) {
            cam3lot.getBalance(account).then(res => {
              setBalance(getFullDisplayBalance(res, 18, 5));
              setHightBalance(res)
            })
          }
          // console.log("BTC balance:", btcBalance, btcBalance.data)
          // setBalance(new BigNumber(btcBalance.data.formatted).toFixed(5,1).toString())
          // setHightBalance(btcBalance.data.formatted)
        }
      }
    }else if(type === Type.unStake){
      if(currentAsset && account){
        cam3lot.getStakeInfo(account, currentNetwork).then((res: StakeInfoItem[]) => {
          // todo
          // res = [
          //   {
          //     token: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          //     amount: '93330823123131413131'
          //   }
          // ]
          let amount = '0'
          res.forEach(val => {
            if(val.token === currentAsset?.address){
              amount = val.amount
            }
          })
          setBalance(getFullDisplayBalance(amount, 18, 5))
          setHightBalance(amount)
        })
      }
    }
  }, [currentAsset, account, type, cam3lot]);

  // useEffect(() => {
  //   if (type == Type.stake && currentAsset && currentAsset.name == "BTC" && account && btcBalance && btcBalance.data) {
  //     setBalance(new BigNumber(btcBalance.data.formatted).toFixed(5,1).toString())
  //     setHightBalance(btcBalance.data.formatted)
  //   }
  // }, [btcBalance])

  useEffect(() => {
    setBalance('0')
    setHightBalance('0')
    setValue('')
  }, [type, currentAsset])

  useEffect(() => {
    if(account){
      // 设置邀请码
      get('/api/user_info').then((res: any) => {
        setInvitationCode(res.data.invitationCode)
      })
    }
    // eslint-disable-next-line
  }, [account]);

  const [invitationCode, setInvitationCode] = useState<string>('');
  const [codeCopied, setCodeCopied] = useState<boolean>(false)
  const codeCopy = () => {
    try {
      setCodeCopied(true)
      setTimeout(() => setCodeCopied(false), 1000)
    } catch (error) {
      console.error(error)
    }
  }

  let [value, setValue] = useState<string>("");
  let [time, setTime] = useState<NodeJS.Timeout>();
  let [timeLoading, setTimeLoading] = useState<boolean>(false);
  let [free, setFree] = useState<string>("0");
  useEffect(() => {
    let free = new BigNumber(Number(value)).multipliedBy(feeRatio);
    setFree(free.toString());
  }, [value, feeRatio]);
  let valueChange = (value: string) => {
    if (/^\d*\.?\d*$/.test(value)) {
      if (Number(balance) === 0) return setValue("");
      setValue(value);

      if (time) {
        clearTimeout(time);
      }
      setTimeLoading(true);
      setTime(
        setTimeout(() => {
          // 最小值
          let min = type === Type.stake ? 0.00001 : 0.00001
          if (/^\d*\.?\d*$/.test(value)) {
            let data = "";
            if (value.includes(".")) {
              data = value;
            } else {
              data = Number(value).toString();
            }
            if (Number(data) > Number(balance)) {
              data = String(Number(balance));
            } else if (Number(data) !== 0 && Number(data) < min) {
              data = min.toString();
            }
            setValue(data === '0' ? '' : data);
          }
          setTimeLoading(false);
        }, 800)
      );
    }
  };

  let stake = async (address: string) => {
    
    if(chainId !== currentNetwork.id) {
      await open({
        view: 'Networks'
      })
      return
    }

    if(!connector || !account) {
      return 
    }
    if (address === BTC_ADDRESS) {
      // @ts-ignore
      writeContract({
        abi: StakeABI,
        //@ts-ignore
        address: currentNetwork.contractAddress,
        functionName: "stakeBTC",
        connector,
        value: ethers.utils.parseUnits(value, 'ether').toBigInt()
      });
    } else {
      let amount = getHighPrecisionVaule(Number(value || 0))
      if(value === balance) amount = hightBalance
      // @ts-ignore
      writeContract({
        abi: StakeABI,
        //@ts-ignore
        address: currentNetwork.contractAddress,
        functionName: "stake",
        args: [currentAsset?.address, amount],
        connector,
      });
    }
  };

  const [transactionInfo, setTransaction] = useState<ResultItem>({
    show: false,
    hash: "hash code",
    status: STATUS.LOADING,
  });

  let refresh = () => {
    window.location.reload();
  };

  let selNetClick = (e: React.SyntheticEvent) => {
    setNetworkPop(!networkPop);
    e.stopPropagation();
  };
  let setMax = () => {
    if(Number(balance) > 0){
      setValue(balance)
    }
  }
  let unStake = async () => {
    if (!account) {
      open()
      return
    }
    
    if(chainId !== currentNetwork.id) {
      await open({
        view: 'Networks'
      })
    }

    let address = currentAsset?.address
    if (address === BTC_ADDRESS) {
      // @ts-ignore
      writeContract({
        abi: StakeABI,
        //@ts-ignore
        address: currentNetwork.contractAddress,
        functionName: "unstakeBTC",
        connector,
        args: [ethers.utils.parseUnits(value, 'ether').toBigInt()],
      });
    } else {
      let amount = getHighPrecisionVaule(Number(value || 0))
      if(value === balance) amount = hightBalance
      // @ts-ignore
      writeContract({
        abi: StakeABI,
        //@ts-ignore
        address: currentNetwork.contractAddress,
        functionName: "unstake",
        args: [currentAsset?.address, amount],
        connector,
      });
    }
  }


  let getParams = () => {
    return {
      assetPop,
      setAssetPop,
      tokens,
      currentAsset,
      transactionInfo,
      networkPop,
      balance,
      value,
      free,
      timeLoading,
      isConfirming,
      stake,
      toTeam,
      toHistory,
      selectAsset,
      selNetClick,
      refresh,
      setCurrentNetwork,
      currentNetwork,
      valueChange,
      networks: chains,
      invitationCode,
      codeCopied,
      codeCopy,
      type,
      setType,
      unStake,
      setMax,
      account,
    };
  };

  return (
    <div>
      {browser.mobile ? <Mobile params={getParams()}/> : <Desktop params={getParams()}/>}
    </div>
  );
};
export default Index;
