import Button from "../../../../components/Button"
import styles from "./Desktop.module.scss"
import { truncate } from "../../../../libs/text"
import {getFullDisplayBalance} from '../../../../libs/formatUtil'
import Icon from "../../../../components/Icon";
import { Props } from "../../Team";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from "../../../../components/Tooltip"
import {TooltipIcon} from "../../../../components/Tooltip"
import {TEAM_NUMBER} from "../../../../constants";
import TokenLogoAndChainLogo from "../../../../components/TokenLogoAndChainLogo";
import BigNumber from "bignumber.js";

const Index = (props: Props) => {
  let {
    userTotalBTC,
    info,
    moreClick,
    showMore,
    tokens,
    showTokenMore,
    requestedUser,
    invitationCode,
    next,
    progress,
    toStake,
    moreTokenClick,
    showHistory,
    historyClick,
    snapshots,
    totalReward,
    reward,
    redeemed,
    withdraw,
    withdrawLoading,
    codeCopied,
    codeCopy,
  } = props.params;

  return <div className={styles.page}>
    <div className={styles.bg}>
      <div className={styles.content}>
        <div className={styles.images}>
          <img src="/images/other/bridge.png" alt="" />
        </div>
      </div>
    </div>
    <div className={styles.center}>
      <div className={styles.title}><div><FormattedMessage id="stakeT1"/></div></div>
      <div className={styles.view}>
        <div className={styles.viewContent}>
          <div className={styles.top}>
            <div className={styles.view1_1}>
              <div className={styles.title1}><FormattedMessage id="stakeT2"/></div>
              <img src="/images/other/box.png" alt="" />
              <div>
                <img src="/images/other/btcicon.png" alt="" />
                {userTotalBTC} BTC
              </div>
            </div>
            <div className={styles.view1_2}>
              <div  className={styles.title2}>
                <img src="/images/other/trophy.png" alt="" />
                <div><FormattedMessage id="stakeT3"/></div>
              </div>
              <div className={styles.camelotPoints}>
                <div>
                  <FormattedMessage id="stakeT37"/> {info.totalPoint || 0}
                  <TooltipIcon className={styles.tooltipIcon} content={'Points are updated at 0:00 (UTC) every day'}></TooltipIcon>
                </div>
                <div>
                  <FormattedMessage id="stakeT4"/> {info.availablePoints || 0}
                </div>
                <div>
                  <FormattedMessage id="stakeT38"/>
                  {info?.totalPoint != null && info?.availablePoints != null
                      ? new BigNumber(info.totalPoint).minus(new BigNumber(info.availablePoints)).toString()
                      : '0'}
                </div>
                <div>
                  {/*<div onClick={historyClick}><FormattedMessage id="stakeT5"/></div>*/}
                  <div onClick={moreClick}><FormattedMessage id="stakeT6"/></div>
                </div>
                {
                  showHistory && (
                    <div className={styles.historyInfo}>
                      <div className={styles.historyInfoTitle}><FormattedMessage id="stakeT7"/></div>
                      <div className={styles.historyInfoNav}>
                        <div><FormattedMessage id="stakeT8"/></div>
                        <div><FormattedMessage id="stakeT9"/></div>
                        <div><FormattedMessage id="stakeT10"/></div>
                      </div>
                      <div className={styles.historyInfoList}>
                        {
                          snapshots.map((val, index:number) => <div key={index} className={styles.historyInfoCell}>
                            <div>{val.cycleName}</div>
                            <div>{val.points}</div>
                            <div>{val.reward}</div>
                          </div>)
                        }
                      </div>
                      {
                        snapshots.length == 0 && <div className={styles.none}>
                          <img src="/images/static/none_icon.png" alt=""/>
                        </div>
                      }
                    </div>
                  )
                }
                {
                  showMore && (
                    <div className={styles.moreInfo}>
                      <div>
                        <div>
                          <div>
                            <div><FormattedMessage id="stakeT11"/></div>
                            <div>{info.totalPoint}</div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div><FormattedMessage id="stakeT12"/></div>
                            <div>{info.stakePoint}</div>
                          </div>
                          <div>
                            <div className={styles.secondPoint}><FormattedMessage id="stakeT13"/></div>
                            <div>{info.personalStakePoint}</div>
                          </div>
                          <div>
                            <div className={styles.secondPoint}><FormattedMessage id="stakeT14"/></div>
                            <div>{info.teamStakePoint}</div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div><FormattedMessage id="stakeT15"/></div>
                            <div>{info.agentPoint}</div>
                          </div>
                          <div>
                            <div className={styles.secondPoint}><FormattedMessage id="stakeT16"/></div>
                            <div>{info.personalAgentPoint}</div>
                          </div>
                          <div>
                            <div className={styles.secondPoint}><FormattedMessage id="stakeT17"/></div>
                            <div>{info.teamAgentPoint}</div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div><FormattedMessage id="stakeT18"/></div>
                            <div>{ info.networkPoint }</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className={styles.rewardInfo}>
                  <div className={styles.alignCenter}><FormattedMessage id="stakeT21"/> { totalReward } CLOT</div>
                  <div><FormattedMessage id="stakeT22"/> {redeemed} CLOT</div>
                  <div><FormattedMessage id="stakeT23"/> { reward } CLOT <TooltipIcon className={styles.tooltipIcon} content={'You can redeem when amount is larger than 100.'}></TooltipIcon></div>
                </div>
                <Button className={styles.withdraw} loading={withdrawLoading} disabled={!(reward >= 100)} onClick={withdraw}><FormattedMessage id="stakeT24"/></Button>
              </div>
            </div>
            <div className={styles.view1_3}>
              <div className={styles.title2}>
                <img src="/images/other/icon1.png" alt="" />
                <div><FormattedMessage id="stakeT25"/></div>
              </div>
              <div>
                {
                  tokens.slice(0,3).map((val:any, index:number) => {
                    return  (
                      <div className={styles.cell} key={index}>
                        <div>{getFullDisplayBalance(val.amount, 18, 5)} {val.name}</div>
                        {/* <img src={val.icon} alt="" /> */}
                        <TokenLogoAndChainLogo chainLogo={val.chain?.logo} tokenLogo={val.icon} size={21}/>
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.tokensmoreView}>
                {
                  tokens.length > 3 && <div className={styles.tokensmore} onClick={moreTokenClick}>
                    <FormattedMessage id="stakeT26"/>
                  </div>
                }
                {
                  showTokenMore && <div className={styles.moretokenInfo}>
                    {
                      tokens.map((val:any, index:number) => {
                        return  (
                          <div className={styles.moretokenInfoCell} key={index}>
                            <div>{getFullDisplayBalance(val.amount, 18, 5)} {val.name}</div>
                            {/* <img src={val.icon} alt="" /> */}
                            <TokenLogoAndChainLogo className={styles.logoView} chainLogo={val.chain?.logo} tokenLogo={val.icon} size={21}/>
                          </div>
                        )
                      })
                    }
                  </div>
                }
              </div>
            </div>
            <div className={styles.view1_4}>
              <div className={styles.title2}>
                <img src="/images/other/icon3.png" alt="" />
                <div><FormattedMessage id="stakeT27"/></div>
              </div>
              <div>
                <div>
                  <div><FormattedMessage id="stakeT28"/></div>
                  <div>{info.teamNumber || 0}/{TEAM_NUMBER}</div>
                  <img src="/images/other/user.png" alt="" />
                </div>
                <div>
                  <div><FormattedMessage id="stakeT29"/></div>
                  <div>{info.teamBTCValue || 0} BTC</div>
                  <img src="/images/other/btc.png" alt="" />
                </div>
                {
                  requestedUser && <div>
                    <div><FormattedMessage id="stakeT30"/></div>
                    <div>{invitationCode ? <FormattedMessage id="stakeT31"/> : <FormattedMessage id="stakeT32"/> }</div>
                    {
                      invitationCode ? <img src="/images/other/leader.png" alt="" /> : <img src="/images/other/usericon.png" alt="" />
                    }
                  </div>
                } 
                {
                  !!invitationCode && <div className={styles.invite}>
                    <div><FormattedMessage id="agent10" />&nbsp;</div>
                    <div className={styles.code}>{invitationCode}</div>
                    <CopyToClipboard
                      onCopy={codeCopy}
                      text={invitationCode}
                    >
                      <div id="parent">
                        <Tooltip
                          content="Copied!"
                          visible={codeCopied}
                          className={styles.copyButton}
                        >
                          <Icon style={{color: '#F36C17'}} size={15} name="file_copy"></Icon>
                        </Tooltip>
                      </div>
                    </CopyToClipboard>
                  </div>
                }
              </div>
            </div>
          </div>
          {
            info.stakeConfigurations.length > 0 && <>
              <div className={styles.BTC}>
                <div>{info.teamBTCValue} BTC</div>
                <div><FormattedMessage id="stakeT33"/> {next} BTC</div>
              </div>
              <div className={styles.progress}>
                <div>
                  <div><FormattedMessage id="stakeT34"/></div>
                  {
                    info.stakeConfigurations?.map((val:any, index:number) => {
                      return <div key={index}>+ {val.sharing * 100}%</div>
                    })
                  }
                </div>
                <div>
                  <div style={{width: `${progress}%`}}></div>
                </div>
                <div>
                  <div><FormattedMessage id="stakeT35"/></div>
                  {
                    info.stakeConfigurations?.map((val:any, index:number) => {
                      return <div key={index}>{val.btcValue} BTC</div>
                    })
                  }
                </div>
              </div>
            </>
          }
        </div>
        <Button outline className={styles.btn} onClick={toStake}>
        <FormattedMessage id="stakeT36"/>
          <Icon size={20} name="call_made"></Icon>
        </Button>
      </div>
    </div>
  </div>
}
export default Index