const lang = {
  // public
  Connect: '連接',
  Pending: '處理中',
  notconnect: '未連接',
  CopyAddress: '複製地址',
  Disconnect: '斷開連接',
  Wallet: '錢包',
  ConnectWallet: '連接錢包',

  // Home
  home1: 'Bitcoin',
  'home1-1': 'Layer3',
  'home1-2': 'Protocol',
  home2: 'FOR DePIN',
  home3: 'Camelot Protocol 是一個BTC三層協議，可以將閒置的GPU資源提供給行動和穿戴式裝置廠商，用於AI模型訓練',
  home4: 'Layer3',
  home5: 'DePIN',
  home6: 'AI',
  home7: 'Crypto',
  home8: 'Layer3',
  home9: '基於Merlin的專用於DePIN的Layer3協議',
  home10: '基於Merlin構建Layer3，並逐步建立DePIN網絡，通過Restaking機制進行鏈接。匯集企業及個人閑置資源，專注於為可移動設備上的AI應用提供分佈式運算和帶寬共享服務。',
  home11: '瞭解更多',
  home12: 'RESTAKE',
  home13: "致力於打造全球最大的由DePIN驅動的AI可移動設備生態系統",
  home14: '基於DePIN網絡，發佈内置BitcoinCore的Camelot終端設備，最終實現每個DePIN網絡節點都運行一個BTC全節點的願景，讓BTC網絡以極其去中心化和穩定的狀態覆蓋全球。',
  home15: '架構',
  home16: '分佈式共識算力供應',
  home17: '為DePIN打造的Layer3',
  home18: '原生的比特幣Layer2',
  home19: '加密貨幣的核心',
  home20: '核心產品',
  home21: 'CAMELOT',
  home22: 'Agent',
  home23: '每個人都有資格通過簡單的交互來擁有Agent，例如關注我們的X或者加入Discord。Agent將幫助您通過日常任務賺取額外積分。',
  home24: 'CAMELOT',
  home25: 'ReStake',
  home26: '一個高度安全、無憂的系統，可幫助您質押您的mToken和其他Merlin資產來賺取積分。',
  home27: 'CAMELOT',
  home28: 'Computility',
  home29: '充分利用未完全使用的GPU資源，將其整合到Camelot的DePIN系統中來賺取積分。',
  home30: 'CAMELOT',
  home31: 'Data',
  home32: '通過運行網絡應用程序獲得獎勵，它將從公共網站獲取數據並將其分享給AI公司。',
  home33: '生態系統',
  home34: 'RACA手機',
  home35: '與BTC生態系統和Camelot的DePIN兼容。無需完成任何任務即可獲得社區空投。',
  home36: 'ZOBO智能手錶',
  home37: '第一款以AI爲核心的智能手錶，采用創新的可穿戴技術，就像您的隨身AI助手一樣。',
  home38: '智能駕駛',
  home39: '提供豐富的車內AI應用場景，協助電車企業在自動駕駛，車載助手等方向的技術支援與算力供應。',
  home40: 'VR/AR',
  home41: '協助智慧頭顯設備在AI小型化，生活通用人工智慧，遊戲內容輔助衍生等方向的技術與算力支援。',
  home42: "Camelot協議: 為AI的魔法打造物理基礎。",
  home43: '合作夥伴',
  home44: '架構',

  // agent
  agent1: '心中那份信念驅使我日復一日，\n傾盡全力嘗試將其握於手中。',
  agent2: '小時',
  agent3: '分鐘',
  agent4: '秒',
  agent5: '恭喜！ 您已獲得10積分！',
  agent6: '已嘗試',
  agent7: '拔出！',
  agent8: '連接錢包',
  agent9: '查看我的積分',
  agent10: '您的邀請碼：',

  // network 
  network1: '連接',
  network2: '下載插件',
  network3: 'Data積分',
  network4: '總收益：',
  network5: "今日收益：",
  network6: '已連接',
  network7: '未連接',
  network8: '儀表盤',
  network9: '您的網絡記錄',
  network10: '已連接時間',
  network12: '獲得的積分',
  network13: '下載',
  network14: '儀表盤',

  // stake
  stake1: '選擇資產',
  stake2: '首個專用於DePIN的BTC-Layer3公鏈',
  stake3: '網絡',
  stake4: 'Merlin',
  stake5: '資產',
  stake6: '數量',
  stake7: '餘額：',
  stake8: '服務費',
  stake9: '查看我的積分',
  stake10: '查看交易歷史記錄',
  stake11: '質押',
  stake12: '質押',

  // history
  stakeH0: '鏈',
  stakeH1: '時間',
  stakeH2: '資產',
  stakeH3: '類型',
  stakeH4: '數量',
  stakeH5: '來自',
  stakeH6: '質押更多',
  stakeH7: '質押更多',
  stakeH8: '狀態',

  // team
  stakeT1: "Camelot's Miracle",
  stakeT2: '已質押資產',
  stakeT3: '您的收益',
  stakeT4: '積分餘額：',
  stakeT5: '歷史記錄',
  stakeT6: '詳情',
  stakeT7: '收益歷史記錄',
  stakeT8: '紀元',
  stakeT9: '積分',
  stakeT10: '收益',
  stakeT11: '總積分',
  stakeT12: '質押積分',
  stakeT13: '個人積分',
  stakeT14: '團隊積分',
  stakeT15: 'Agent積分',
  stakeT16: '個人積分',
  stakeT17: '團隊積分',
  stakeT18: 'Data積分',
  stakeT19: 'Cloud積分',
  stakeT20: 'Computility積分',
  stakeT21: '總收益：',
  stakeT22: '已兌換：',
  stakeT23: '可兌換：',
  stakeT24: '兌換',
  stakeT25: '您的資產',
  stakeT26: '更多',
  stakeT27: '您的團隊',
  stakeT28: '縂成員',
  stakeT29: '總資產',
  stakeT30: '身份',
  stakeT31: '領主',
  stakeT32: '騎士',
  stakeT33: '下一個里程碑：',
  stakeT34: '獎勵',
  stakeT35: '目標',
  stakeT36: '質押更多',
  stakeT37: '總積分：',
  stakeT38: '已消耗的積分： ',

  // v2
  Home: '首頁',
  Agent: 'Agent',
  Stake: '質押',
  Computility: '算力',
  StakeHistory: '質押歷史紀錄',
  StakeTeam: 'StakeTeam',
  Data: 'Data',
  Dapp: "Dapp",
  Develop: "Develop",
  Market: "Market",
  Valiant: "Valiant",
  FunPIN: "FunPIN",
  Hot: "Hot",
  Watch: "Watch",
  Testnet: '測試網',
  Docs: '文檔',
  Managed: 'Managed by',
  Camelot: 'Camelot',
  GetConnected: '聯繫我們',
  Twitter: 'Twitter',
  Discord: 'Discord',
  YourWallet: '你的錢包',
  DisconnectWallet: '斷開連結',
  ViewonMerlin: '查看交易',
  CreateAgent: '創建Agent',
  FIRSTSTEP: '第一步',
  Leader: '君主',
  Lord: '君主',
  ExcellentLeader: '身為優秀的領導者，打造自己的團隊！捍衛你的名字！',
  Knight: '騎士',
  BraveKnight: '身為勇敢的騎士，跟隨優秀的君主吧！光明將指引你前進！',
  getaninvite: '您將獲得邀請碼',
  invitecode: '邀請碼',
  SECONDSTEP: '第二步',
  Create: '創建',
  ChooseYourRole: '戰士！請選擇您的角色!',
  Enterinvite: '輸入邀請碼',
  CurrentRole: '當前角色',
  Beta: 'Beta',
  Download: '下載',
  comingSoon: '敬請期待',
  ta1: 'Camelot比特幣設備',
  ta2: 'AI雲服務',
  ta3: '去中心化物理',
  ta4: '基礎設施網絡',
  ta5: 'DePIN專用Layer3',
  ta6: '比特幣二層共鏈',
  ta7: '數字貨幣核心',
  dataTips: ' 妳必須至少質押0.001 BTC等值的token並且保持網絡連接。',
  Audited: '審計',
  Audit: '審計',
  Approved: '已授權',
  Approve: '授權',
  Unstake: "解押",

  // FunPIN
  joinDefi: "Join the",
  defi2Pin: "GameFi 2 PIN",
  movement: "Movement",
  "campaign1": "FunPIN：",
  "campaign1-1": "參與精彩冒險，贏取智慧手錶！",
  "campaign2": "想像一下將遊戲的刺激與現實世界獎勵的快感結合在一起！",
  "campaign2-1": "透過 FunPIN，您可以使用 $CLOT 代幣參與冒險，解鎖獨家寶藏，並贏取令人垂涎的 Camelot 智慧手錶。",
  "campaign3": "如何運作：",
  "campaign4": "1. 開啟您的冒險：",
  "campaign5": " • 門票價格：20,000 $CLOT",
  "campaign6": " • 冒險時長：每輪冒險持續 7 天",
  "campaign7": "2. 解鎖驚人獎勵：",
  "campaign8": " 1). 泰坦寶箱：",
  "campaign9": " • 冒險結束時開啟，保證獲得：",
  "campaign10": "   • 一隻全新的 Camelot 智慧手錶",
  "campaign11": "   • 20,000 $CLOT",
  "campaign12": "   • 額外驚喜獎勵，如 NFT、代幣和積分",
  "campaign13": " 2). 每日簽到寶箱：",
  "campaign14": "   • 每天開啟，隨機獲得代幣和積分",
  "campaign15": "3. 如何參與：",
  "campaign16": "  • 連接您的錢包：確保您有一個相容的錢包，裡面至少有 20,000 $CLOT 代幣。",
  "campaign17": "  • 購買門票：前往「現在加入」部分併購買您的門票。您可以多次參與冒險！",
  "campaign18": "  • 追蹤您的旅程：透過我們的儀表板監控您的冒險進度和獎勵情況。",
  "campaign19": "  • 開啟寶箱：每天享受簽到寶箱的驚喜，並在 7 天後開啟泰坦寶箱，領取大獎！",
  "campaign20": "為什麼選擇 FunPIN？",
  "campaign21": " • 精彩冒險：每次冒險都充滿刺激體驗。",
  "campaign22": " • 現實獎勵：贏取獨家的 Camelot 智慧手錶和其他珍貴獎勵。",
  "campaign23": " • 每日驚喜：每天開啟簽到寶箱，享受驚喜。",
  "campaign24": " • 參與簡單：簡單幾步即可加入並追蹤您的進度。",
  "campaign25": "加入 FunPIN 革命，體驗遊戲的樂趣和現實世界的獎勵。今天就開始您的冒險，看看哪些寶藏等著您！",
  "Enteron": "Enter on",
  "Ended": "Ended",
  'history': '歷史記錄',
  "YourHistory": "你的歷史記錄",
  "startTime": "開始時間",
  "endTime": "結束時間",
  "dailyRewards": "每日獎勵",
  "ultimatePrize": "終極大獎",
  "open": "開啟",
  "days": "第 {n} 天",
  "waiting": "等待发放奖励"
};
export default lang;