import {useCallback, useEffect, useState} from 'react';
import { useCam3lot } from '../hooks';
import JSBI from "jsbi";
import {Contract} from "ethers";
import {useAccount} from "wagmi";

const useAllowance = (token: Contract | undefined, spender: string, pendingApproval?: boolean) => {
    const [allowance, setAllowance] = useState<JSBI>(JSBI.BigInt(0));
    const {address: account} = useAccount()

    const fetchAllowance = useCallback(async () => {
        setTimeout(async () => {
            if (!spender) return
            // @ts-ignore
            const _allowance = await token.allowance(account, spender);
            // @ts-ignore
            setAllowance(_allowance);
        }, 800)
    }, [account, spender, token, pendingApproval]);

    useEffect(() => {
        if (account && spender && token) {
            fetchAllowance().catch((err) => console.log(`Failed to fetch allowance: ${err.stack}`));
        }
    }, [account, spender, token, pendingApproval]);

    return allowance;
};

export default useAllowance;
