import { Link } from "@material-ui/core";
import styles from "./Result.module.scss"
import { getScanHashUrl } from "../libs/global";
import { truncate } from "../libs/text"
import Button from "./Button"
import { FC } from "react"
import classNames from "classnames"
import Card from "./Card"
import { useCam3lot } from "../hooks";

export enum STATUS {
  SUCCESS = "success",
  LOADING = "loading",
  FAILURE = "failure",
}

export interface ResultItem {
  show: boolean
  status: STATUS
  hash?: string
  error?: string,
}

interface Props {
  className?: string
  resultItem: ResultItem
  btnClick?: () => void
  btnTxt?: any
}

const Result: FC<Props> = ({ resultItem, className, btnTxt = 'Refresh', btnClick }) => {
  const { status = STATUS.LOADING, hash = '', error = '' } = resultItem
  const { currentChain } = useCam3lot()

  const title = {
    [STATUS.SUCCESS]: "Complete",
    [STATUS.LOADING]: "Waiting",
    [STATUS.FAILURE]: "Failed"
  }[status]
  const icon = status == STATUS.FAILURE ? <img className={styles.successIcon} src="/images/static/error_icon.png" alt="" /> : status == STATUS.LOADING ? <img className={styles.loadingIcon} src="/images/static/loading_icon.png" alt="" /> : <img className={styles.successIcon} src="/images/static/success_icon.png" alt="" />
  
  return (
    <div className={classNames(styles.view, className)}>
      <Card icon={icon} title={title} lg>
        <section className={styles.contents}>
          <div hidden={!error} className={styles.errorView}>{error}</div>
          {
            hash && currentChain && <div className={classNames(styles.underline, styles.hashView)}>
              <Link target="_blank" color="inherit" href={getScanHashUrl(hash, currentChain)}>{truncate(hash)}</Link>
            </div>
          }
          {
            status != STATUS.LOADING && <Button className={styles.copyError} onClick={btnClick}>
              {btnTxt}
            </Button>
          }
        </section>
      </Card>
    </div>
  )
}

export default Result
