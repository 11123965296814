import { Chain } from "viem"

export interface chain extends Chain {
  explorerUrl: string
  chainRpc: string
  contractAddress: string
  logo: string
  clotStakeContractAddress?: string
}
export const merlin: chain = {
  name: "Merlin",
  id: 4200,
  explorerUrl: 'https://scan.merlinchain.io',
  chainRpc: "https://rpc.merlinchain.io",
  rpcUrls: {
    default: {
      http: ["https://rpc.merlinchain.io"]
    }
  },
  contractAddress: "0x87b73cF1cdAF33e7C1F35E9e90cf655771aa1B1a",
  nativeCurrency: {
    name: "BTC",
    symbol: "BTC",
    decimals: 18
  },
  logo: "/images/other/Merlin.png",
}
export const b2ChainName = "B² Network"
export const B2: chain = {
  name: "B2",
  id: 223,
  explorerUrl: 'https://explorer.bsquared.network',
  chainRpc: "https://rpc.bsquared.network",
  rpcUrls: {
    default: {
      http: ["https://rpc.bsquared.network"]
    }
  },
  contractAddress: "0x24b535ae99431D8737C93f8CAa590881dc5b7333",
  clotStakeContractAddress: "0xbe25fF892A3c40feC18E684b4CC45c542944a6Af",
  nativeCurrency: {
    name: "BTC",
    symbol: "BTC",
    decimals: 18
  },
  logo: "/images/other/b2.png",
}
export const AIlayer: chain = {
  name: "AILayer",
  id: 2649,
  explorerUrl: 'https://mainnet-explorer.ailayer.xyz',
  chainRpc: "https://mainnet-rpc.anvm.io",
  rpcUrls: {
    default: {
      http: ["https://mainnet-rpc.anvm.io"]
    }
  },
  contractAddress: "0x24b535ae99431D8737C93f8CAa590881dc5b7333",
  nativeCurrency: {
    name: "BTC",
    symbol: "BTC",
    decimals: 18
  },
  logo: "/images/other/ailayer.png",
}
export const Bitlayer: chain = {
  name: "BitLayer",
  id: 200901,
  explorerUrl: 'https://www.btrscan.com',
  chainRpc: "https://rpc.bitlayer.org",
  rpcUrls: {
    default: {
      http: ["https://rpc.bitlayer.org", 'https://rpc.bitlayer-rpc.com', 'https://rpc.ankr.com/bitlayer']
    }
  },
  contractAddress: "0x24b535ae99431D8737C93f8CAa590881dc5b7333",
  clotStakeContractAddress: "0xbe25fF892A3c40feC18E684b4CC45c542944a6Af",
  nativeCurrency: {
    name: "BTC",
    symbol: "BTC",
    decimals: 18
  },
  logo: "/images/other/bitlayer.png",
}
export const chains: chain[] = [AIlayer ,Bitlayer, merlin, B2]