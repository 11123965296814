import { FC } from "react"
import Tippy, { TippyProps } from "@tippyjs/react"
import classNames from "classnames"
import Icon from "./Icon"

import "tippy.js/dist/tippy.css"
import "tippy.js/themes/material.css"
import styles from "./Tooltip.module.scss"

export const DefaultTippyProps: TippyProps = {
  animation: false,
  interactive: true,
  appendTo: document.body,
}

export const DropdownTippyProps: TippyProps = {
  ...DefaultTippyProps,
  placement: "bottom-end",
  trigger: "click",
}

const TooltipTippyProps: TippyProps = {
  ...DefaultTippyProps,
  // trigger: "click",
  placement: "top",
  theme: "material",
  className: styles.tooltip,
}

interface Props extends Omit<TippyProps, "children"> {
  onClick?: () => void
  children?: React.ReactNode
}

const Tooltip: FC<Props> = ({ className, onClick, children, ...props }) => {
  const button = (
    <button
      type="button"
      className={classNames(styles.button, className)}
      onClick={onClick}
    >
      {children}
    </button>
  )

  return props.content ? (
    <Tippy
      {...TooltipTippyProps}
      {...props}
    >
      {button}
    </Tippy>
  ) : (
    button
  )
}

export const TooltipIcon: FC<Props> = ({ children, ...props }) => (
  <div className={styles.flex}>
    {children}
    <div className={styles.icon}>
      <Tooltip {...props}>
        <div style={{padding: '0 0 0 5px', display: 'flex'}}>
          {/* <Icon name="info_outline" size={18} /> */}
          <img style={{height: `16px`}} src="/images/other/tips.png" alt="" />
        </div>
      </Tooltip>
    </div>
  </div>
)

export const TooltipIconNoIcon: FC<Props> = ({ children, ...props }) => (
    <div className={styles.flex}>
      {children}
      <div className={styles.icon}>
        <Tooltip {...props}>
        </Tooltip>
      </div>
    </div>
)

export default Tooltip
