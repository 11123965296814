import { useEffect, useRef, useState } from "react";
import styles from "./Mobile.module.scss";
import Button from "../../../components/Button";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from "../../../components/Tooltip"
import Icon from "../../../components/Icon"
import {Props} from "../Index"
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../../hooks/useLanuage";
import classNames from "classnames";

const Index = (props: Props) => {
  let {
    toTeam, 
    invitationCode, 
    codeCopy, 
    account, 
    timeObj, 
    pullSuccess, 
    isSigned, 
    disabled, 
    loading, 
    submit, 
    codeCopied,
    second,
    signedNumber
  } = props.params
  const { language } = useLanguage();

  return (
    <div className={styles.page}>
      {
        loading && <div className={styles.loading} >
          <div>
            <img src="/images/other/loading.gif" alt="" />
            <div><div style={{width: `${ second.current * 10}%`}}></div></div>
            <div>{ 10 - second.current}S</div>
          </div>
        </div>
      }
      <div className={styles.bg}>
        <div className={styles.content}>
          <div className={styles.images}>
            <img src="/images/other/agent_bg1.png" alt="" />
            <img src="/images/other/Vector.png" alt="" />
            <img src="/images/other/Vector2.png" alt="" />
          </div>
          <div className={styles.right}>
            <div className={styles.rightContent}>
              <div className={classNames(styles.subtitle, language?.len == 'zh' ? styles.subtitleZh : '')}><FormattedMessage id="agent1" /></div>
              <div className={styles.time}>
                <div>
                  <div>{timeObj.H < 10 ? '0': ''}{timeObj.H}</div>
                  <div><FormattedMessage id="agent2" /></div>
                </div>
                <div>
                  <div>{timeObj.M < 10 ? '0': ''}{timeObj.M}</div>
                  <div><FormattedMessage id="agent3" /></div>
                </div>
                <div>
                  <div>{timeObj.S < 10 ? '0': ''}{timeObj.S}</div>
                  <div><FormattedMessage id="agent4" /></div>
                </div>
              </div>
              <div className={styles.number}>
                <img src="/images/other/car1_y.png" alt="" />
                <div>Total Pulls: { signedNumber }</div>
                <img src="/images/other/car3_y.png" alt="" />
              </div>
              <div className={styles.success} style={{opacity: pullSuccess ? 1 : 0 } }><FormattedMessage id="agent5" /></div>
              <div className={styles.btns}>
                <Button 
                  className={styles.button}
                  disabled={isSigned || disabled}
                  loading={loading}
                  onClick={submit}
                >{account ? isSigned ? <FormattedMessage id="agent6" /> : <FormattedMessage id="agent7" /> : <FormattedMessage id="agent8" />}</Button>
              </div>
              <div className={styles.btns} style={{ marginTop: '13px'}}>
                <Button 
                  outline
                  className={styles.button}
                  onClick={toTeam}
                ><FormattedMessage id="agent9" /></Button>
              </div>
              {
                !!invitationCode && <div className={styles.invite}>
                  <div><FormattedMessage id="agent10" />&nbsp;</div>
                  <div className={styles.code}>{invitationCode}</div>
                  <CopyToClipboard
                    onCopy={codeCopy}
                    text={invitationCode}
                  >
                    <div id="parent">
                      <Tooltip
                        content="Copied!"
                        visible={codeCopied}
                        className={styles.copyButton}
                      >
                        <Icon style={{color: '#F36C17'}} size={12} name="file_copy"></Icon>
                      </Tooltip>
                    </div>
                  </CopyToClipboard>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
