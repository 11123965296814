import {useEffect, useState} from "react"
import Icon from "../Icon"
import Dialog from '@material-ui/core/Dialog';
import styles from './WalletModal.module.scss';
import {useWalletModal} from "../../hooks/useWalletModal"
import {useCookie} from "../../hooks/useCookie";
import {Connector, useAccount, useConnect, useSwitchChain} from "wagmi";
import {BSC_NET} from "../../config";
import {FormattedMessage} from 'react-intl'

function WalletModal() {
    const {connectors, connect} = useConnect()
    const [open, setOpen] = useState(false);
    const {WalletModal, setWalletModal} = useWalletModal();
    const {setCustomCookie} = useCookie();

    let btcWallets: any = [
        {
            icon: '/images/wallet/Unisat.png',
            title: 'Unisat Wallet',
            key: 'unisat'
        },
        {
            icon: '/images/wallet/OKX.png',
            title: 'OKX Wallet',
            key: 'okx'
        },
    ]
    let evmWallets: string[] = ["injected", "com.okex.wallet"]

    useEffect(() => {
        setOpen(WalletModal?.show || false)
    }, [WalletModal])

    const switchChain = async (connector: Connector | undefined) => {
        if (!connector) return
        let currentChainId = await connector.getChainId()
        if (currentChainId != BSC_NET.MAIN) {
            // @ts-ignore
            await connector.switchChain({chainId: BSC_NET.MAIN})
        }
    };

    const conncetBTCWallet = async (key: any | undefined) => {
        connect(key)
        setCustomCookie(key);
        setWalletModal({
            show: false
        })
    };

    const conncetEVMWallet = async (connector: Connector | undefined) => {
        if (!connector) return
        await switchChain(connector);
        // @ts-ignore
        connect({connector});
        setCustomCookie(connector.id);
        setWalletModal({
            show: false
        })
    };

    const close = () => {
        setOpen(false)
    };

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open}>
            <div className={styles.content}>
                <div className={styles.close} onClick={close}>
                    <Icon className={styles.close} name="close" size={28}/>
                </div>
                <div className={styles.modalTitle}><FormattedMessage id='ConnectWallet'/></div>
                {/*<div>*/}
                {/*    <div className={styles.subTitle}>BTC Wallets</div>*/}
                {/*    <div className={styles.modalContent}>*/}
                {/*        {*/}
                {/*            btcWallets.length > 0 ? btcWallets.map((val: any, index: number) => (*/}
                {/*                <div key={index} onClick={() => conncetBTCWallet(val.key)}>*/}
                {/*                    <img src={val.icon} alt=""/>*/}
                {/*                    <span>{val.title}</span>*/}
                {/*                </div>*/}
                {/*            )) : <div className={styles.none}>none</div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div>
                    {/*<div className={styles.subTitle}>EVM Wallets</div>*/}
                    <div className={styles.modalContent}>
                        {
                            connectors.length > 0 ? connectors.toReversed().map((connector) => {
                                return evmWallets.indexOf(connector.id) != -1 && (
                                    <div key={connector.name} onClick={() => conncetEVMWallet(connector)}>
                                        <img src={connector.id == "injected" ? "./images/wallet/MetaMask.png" : connector.icon} alt=""/>
                                        <span>{connector.id == "injected" ? "MetaMask" : connector.name}</span>
                                    </div>
                                )
                            }) : <div className={styles.none}>none</div>
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default WalletModal;

