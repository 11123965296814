import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AutoColumn } from './Column';
import { AutoRow } from './Row';
// @ts-ignore
import { useWallet } from 'use-wallet';
import Icon from "./../Icon"
import {getScanHashUrl} from "../../libs/global";
import {useChainId} from "wagmi";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string;
  success?: boolean;
  summary?: string;
}) {
  const chainId = useChainId();

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? (
          <span style={{ color: '#00E8B9' }}>
            <Icon name='check_circle_outline' size={40} />
          </span>
        ) : ( 
          <span style={{ color: '#e64c57' }}>
            <Icon name='highlight_off' size={40} />
          </span>
        )} 
      </div>
      <AutoColumn gap="8px">
        <StyledPopupDesc>
          {summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}
        </StyledPopupDesc>
        {chainId && (
          <StyledLink target={"_blank"} href={getScanHashUrl(hash)}>View on Binance</StyledLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  );
}

const StyledPopupDesc = styled.span`
  font-weight: 500;
  color: #FFFFFF;
`;

const StyledLink = styled.a`
  font-weight: 500;
  color: #FFCC3B;
`;
