import JSBI from 'jsbi'
import { BigNumber, Contract, ethers, Overrides } from 'ethers';
import { ERC20ABI, StakeABI } from './contract'
import { BigNumber as BignumberJS } from "bignumber.js";
import { web3ProviderFrom } from "./ether-utils";
import { chain } from "../libs/chain";
import { Connector } from 'wagmi';

export interface Dictionary<A> {
    [index: string]: A;
}

export interface StakeInfoItem {
    token: string,
    amount: string
}

export enum StakeType {
    STAKE = 1,
    UNSTAKE = 2
}

export enum TranStatus {
    ERROR = 0,
    SUCCESS = 1,
    PENDING = 2
}

export interface StakeHistoryItem {
    token: string;
    amount: string;
    date: number;
    action: StakeType
}

export class Cam3lotProvider {
    // @ts-ignore
    myAccount: string;
    config: chain;
    contracts: Dictionary<Contract> = {};
    balances: Dictionary<string> = {};
    provider: ethers.providers.Web3Provider;
    signer?: ethers.Signer;

    constructor(cfg: chain) {
        this.provider = new ethers.providers.Web3Provider(
            web3ProviderFrom(cfg.chainRpc),
            cfg.id,
        )
        this.config = cfg;

        this.unlockWallet.bind(this);
        this.getBalance.bind(this);
        this.getTokenBalanceOfUser.bind(this);
        this.signMessage.bind(this);

        this.stake.bind(this);
        this.getStakeInfo.bind(this);
        this.getStakeHistory.bind(this);
        this.getFeeRatio.bind(this);

        this.getERC20Contract.bind(this);
        this.getERC20ContractNoSigner.bind(this);
        this.gasOptions.bind(this);
        this.getTranStatus.bind(this);
    }

    unlockWallet(provider: any, account: string) {
        const newProvider = new ethers.providers.Web3Provider(provider, this.config.id);
        this.provider = newProvider;
        this.signer = newProvider.getSigner(0);
        this.myAccount = account;
    }

    async signMessage(message: string): Promise<string> {
        if (!this.signer) throw "connect wallet"
        // @ts-ignore
        let m = await this.signer.signMessage(message)
        return m
    }

    /** get balance of bnb */
    async getBalance(account: string): Promise<string> {
        if (!this.provider) {
            return String(JSBI.BigInt(0));
        }
        const balance = await this.provider.getBalance(account)
        return String(balance);
    }

    /** get balance of erc20 token of user todo3  balance*/ //获取余额 erc20Address config里面 gdaiAddr xust余额
    async getTokenBalanceOfUser(erc20Address: string, account: string): Promise<string> {
        if(!erc20Address) return '0'
        // @ts-ignore
        let instance = new ethers.Contract(erc20Address, ERC20ABI, this.provider);
        const balance = await instance.balanceOf(account);
        const balanceStr = String(balance);
        return balanceStr;
    }


    /**
     * 质押
     * @param tokenAddress token地址
     * @param amount 质押的数量
     * */
    async stake(tokenAddress: string, amount: string): Promise<string> {
        if (!this.signer) throw "connect wallet"
        let instance = new ethers.Contract(this.config.contractAddress, StakeABI, this.provider);
        // @ts-ignore
        if (this.signer) instance = instance.connect(this.signer);
        // const gas = await instance.estimateGas.stake(tokenAddress, amount);
        // const tran = await instance.stake(tokenAddress, amount, this.gasOptions(gas));
        const tran = await instance.stake(tokenAddress, amount, { gasLimit: 300000, gasPrice: ethers.utils.parseUnits('0.05', 'gwei') });
        return tran.hash;
    }


    /**
     * 获取用户质押资产列表
     * */
    async getStakeInfo(account: string, config: chain): Promise<StakeInfoItem[]> {
        const provider = new ethers.providers.Web3Provider(
            web3ProviderFrom(config.chainRpc),
            config.id,
        )
        let instance = new ethers.Contract(config.contractAddress, StakeABI, provider);

        let list = await instance.getStakeInfo(account);

        let stakeInfo: StakeInfoItem[] = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            let stakeInfoItem: StakeInfoItem = {
                token: item.token,
                amount: String(item.amount),
            }

            stakeInfo.push(stakeInfoItem)
        }

        return stakeInfo
    }

    /**
     *  获取用户质押历史
     */
    async getStakeHistory(account: string, config: chain): Promise<StakeHistoryItem[]> {
        const provider = new ethers.providers.Web3Provider(
            web3ProviderFrom(config.chainRpc),
            config.id,
        )
        let instance = new ethers.Contract(config.contractAddress, StakeABI, provider);
        let list = await instance.getStakeHistory(account);

        let stakeHistory: StakeHistoryItem[] = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            let stakeHistoryItem: StakeHistoryItem = {
                token: item.token,
                amount: String(item.amount),
                date: Number(item.date),
                action: Number(item.action)
            }

            stakeHistory.push(stakeHistoryItem)
        }

        return stakeHistory
    }

    /**
     * 手续费费率，返回0.1，代表10%
     * */
    async getFeeRatio(): Promise<number> {
        let instance = new ethers.Contract(this.config.contractAddress, StakeABI, this.provider);
        let _feePercent = await instance.feePercent();
        return new BignumberJS(String(_feePercent)).div(10000).toNumber()
    }

    /********************* gas *********************/
    gasOptions(gas: BigNumber): Overrides {
        const multiplied = Math.floor(gas.toNumber() * 1.7);
        console.log(`⛽️ Gas multiplied: ${gas} -> ${multiplied}`);
        return {
            gasLimit: BigNumber.from(multiplied),
            gasPrice: ethers.utils.parseUnits('0.05', 'gwei')
        };
    }

    /** get contarct instance */
    getERC20Contract(addr: string): Contract {
        return new ethers.Contract(addr, ERC20ABI, this.provider)
    }

    /** get contarct instance no signer */
    getERC20ContractNoSigner(addr: string): Contract {
        return new ethers.Contract(addr, ERC20ABI, this.provider)
    }

    async getTranStatus(hash: string): Promise<TranStatus> {
        const result = await this.provider.getTransactionReceipt(hash);
        if (!result) {
            return TranStatus.PENDING
        } else {
            return result.status == 0 ? TranStatus.ERROR : TranStatus.SUCCESS
        }
    }
    
    // get Contract provide
    /** get contarct instance no signer */
    getStakeContractNoSigner(addr: string): Contract {
        return new ethers.Contract(addr, StakeABI, this.provider)
    }
    async walletProvider(connector: Connector, account: string) {
        const ethereum: any = await connector.getProvider()
        const provider = new ethers.providers.Web3Provider(ethereum, this.config.id);
        const singer = await provider.getSigner(account)
        return new ethers.Contract(this.config.contractAddress, StakeABI, singer);
        // 
        // 
    }

}
