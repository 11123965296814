import React, { useEffect, useState } from 'react'
import './index.scss'
import { FormattedMessage } from 'react-intl'
import Button from "../../components/Button";
import { useHistory } from 'react-router-dom';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { B2, Bitlayer, chains } from '../../libs/chain';
import Result, { ResultItem, STATUS } from '../../components/Result';
import { clotStakeAbi } from '../../chain/clotStake';
import { Cam3lotProvider } from '../../chain/Cam3lot-new';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import ApprovalButtons from '../../components/ApprovalButtons';
const clotAddress = "0x363dBfE061ACEF6A205E93409Eac2b1D77AcB3b0"
const Index = () => {
  const history = useHistory()
  const { address: account, connector, chainId } = useAccount();
  const { open } = useWeb3Modal()
  const [b2ButtonIsDisable, setb2ButtonIsDisable] = useState(false)
  const [bitButtonIsDisable, setbitButtonIsDisable] = useState(false)
  const [b2ChainClotBalance, setb2ChainClotBalance] = useState('0')
  const [bitChainClotBalance, setbitChainClotBalance] = useState('0')

  const [transactionInfo, setTransaction] = useState<ResultItem>({
    show: false,
    hash: "hash code",
    status: STATUS.LOADING,
  });

  useEffect(() => {
    if (account) {
      fetchClotBalance()
    }
  }, [account])

  const fetchClotBalance = () => {
    const b2Provider = new Cam3lotProvider(B2)
    const bitProvider = new Cam3lotProvider(Bitlayer)
    if (account) {
      Promise.all([
        b2Provider.getTokenBalanceOfUser(clotAddress, account),
        bitProvider.getTokenBalanceOfUser(clotAddress, account)
      ]).then(res => {
        const balances = res.map(val => ethers.utils.formatEther(val))
        const minStakeNum = 20000000000000
        if (balances[0]) {
          setb2ChainClotBalance(balances[0])
          setb2ButtonIsDisable(!BigNumber(balances[0]).isGreaterThanOrEqualTo(minStakeNum))
        }
        if (balances[1]) {
          setbitChainClotBalance(balances[1])
          setbitButtonIsDisable(!BigNumber(balances[1]).isGreaterThanOrEqualTo(minStakeNum))
        }
        console.log(balances, '13213')
      })

    }
  }

  const { data: hash, writeContract, error } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({ hash });

  const stake = async (type: 'B2' | "BitLayer") => {
    const findChain = chains.find(chain => chain.name === type)
    const clotStakeContractAddress = findChain?.clotStakeContractAddress
    if (findChain?.id !== chainId) {
      open({
        view: 'Networks'
      })
      return
    }

    if (clotStakeContractAddress) {
      // const ethereum: any = await connector?.getProvider()
      // const provider = new ethers.providers.Web3Provider(ethereum, findChain.id);
      // const singer = await provider.getSigner(account)
      // const contract = new ethers.Contract(clotStakeContractAddress, clotStakeAbi, singer);
      // contract.stake().then((res: any) => {
      //   console.log(res)
      // })
      console.log(clotStakeAbi, clotStakeContractAddress, connector)
      writeContract({
        abi: clotStakeAbi,
        chainId: findChain?.id,
        account: account,
        //@ts-ignore
        address: clotStakeContractAddress,
        functionName: "stake",
        connector,
        args: [],
      });
    }
  }
  useEffect(() => {
    if (isConfirming) {
      setTransaction({
        show: true,
        hash,
        status: STATUS.LOADING,
      });
    } else if (!isConfirming && isConfirmed) {
      setTransaction({
        show: true,
        hash,
        status: STATUS.SUCCESS,
      });
    } else if (!isConfirming && !!error) {
      console.log(error)
      setTransaction({
        show: true,
        hash,
        status: STATUS.FAILURE,
        // @ts-ignore
        error: error.details,
      });
    }
  }, [isConfirming, error, hash, isConfirmed]);

  if (transactionInfo.show) {
    return <Result
      resultItem={transactionInfo}
      btnClick={() => {
        window.location.reload()
      }}
    />
  }
  return (
    <div className='campaign'>
      <div className='campaign-bg'>
        <div className='mmd:hidden'>
          <img src="/images/campaign/pc-bg.png" alt="" className='block w-full campaign-image' />

        </div>
        <div className='md:hidden'>
          <img src="/images/campaign/mobile-bg.png" alt="" className='block w-full campaign-image' />
        </div>
        <div className='campaign-bg-content'>
          <span className="text1"><FormattedMessage id='joinDefi' /></span>
          <span className="text2"><FormattedMessage id='defi2Pin' /></span>
          <span className="text1"><FormattedMessage id='movement' /></span>
        </div>
      </div>
      {/* pc */}
      <div className='campaign-container relative mmd:hidden'>
        <img src="/images/campaign/content-bg.png" alt="" className='w-full' />
        <div className='campaign-container-content absolute campaign-container-bg'>
          <div className='relative'>
            <img src="/images/campaign/watch.jpg" alt="" className='block campaign-watch' />
            <div className='watch-text-box'>
              <span className="watch-text1"><FormattedMessage id='campaign1' /></span>
              <span className="watch-text2"><FormattedMessage id='campaign1-1' /></span>
            </div>
          </div>
          <div className='campaign-box'>
            <div className='campaign-info-box'>
              <p className='campaign-info-title campaign-info-title1'>
                <FormattedMessage id='campaign3' />
              </p>
              <p className='campaign-info-desc'>
                <FormattedMessage id='campaign4' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign5' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign6' />
              </p>

              <p className='campaign-info-desc mt-20'>
                <FormattedMessage id='campaign7' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign8' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign9' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign10' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign11' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign12' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign13' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign14' />
              </p>

              <p className='campaign-info-desc mt-20'>
                <FormattedMessage id='campaign15' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign16' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign17' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign18' />
              </p>
              <p className='campaign-info-desc1'>
                <FormattedMessage id='campaign19' />
              </p>

              <div className='why-join'>

                <p className='campaign-info-title campaign-info-title2'>
                  <FormattedMessage id='campaign20' />
                </p>
                <p className='campaign-info-desc'>
                  <FormattedMessage id='campaign21' />
                </p>
                <p className='campaign-info-desc'>
                  <FormattedMessage id='campaign22' />
                </p>
                <p className='campaign-info-desc'>
                  <FormattedMessage id='campaign23' />
                </p>
                <p className='campaign-info-desc'>
                  <FormattedMessage id='campaign24' />
                </p>
              </div>
            </div>
          </div>


          <p className='campaign-info-end'>
            <FormattedMessage id='campaign25' />
          </p>

          {account ? <>
            <div className='buttons'>
              <div>
                <p className='balance-button-box'>
                  <span><FormattedMessage id='stake7' /></span>
                  <span> {b2ChainClotBalance} CLOT</span>
                </p>
                <ApprovalButtons className='campaign-button' hiddenProgress address={clotAddress} contractAddressKey="clotStakeContractAddress" spender={B2} disabled={b2ButtonIsDisable} submit={() => {
                  stake('B2')
                  // console.log(1321)
                }}><FormattedMessage id='Ended' /></ApprovalButtons>
              </div>
              <div>
                <p className='balance-button-box'>
                  <span><FormattedMessage id='stake7' /></span>
                  <span> {bitChainClotBalance} CLOT</span>
                </p>
                <ApprovalButtons className='campaign-button' hiddenProgress address={clotAddress} contractAddressKey="clotStakeContractAddress" spender={Bitlayer} disabled={bitButtonIsDisable} submit={() => {
                  stake('BitLayer')
                }}><FormattedMessage id='Ended' /></ApprovalButtons>
              </div>
            </div>
            <div className='campaign-history-button' onClick={() => {
              history.push('/funPIN_history')
            }}>
              <FormattedMessage id='history' />
            </div>
          </> : <div className='wallet-connect'>
            <Button className="campaign-button" onClick={() => open()}><FormattedMessage id='Connect' /></Button>
          </div>}
        </div>
      </div>
      {/* mobile */}
      <div className='campaign-container relative md:hidden'>
        <img src="/images/campaign/m-content-bg.png" alt="" className='w-full absolute' />
        <div className='campaign-box'>
          <div className='watch-text-box'>
            <span className="watch-text1"><FormattedMessage id='campaign1' /></span>
            <span className="watch-text2"><FormattedMessage id='campaign1-1' /></span>
          </div>
          <div className='mobile-box'>
            <img src="/images/campaign/watch-icon.png" alt="" className='mobile-box-image' />
            <img src="/images/campaign/box.png" alt="" className='mobile-box-image' />
          </div>
          {/* <p className='campaign-text-2'><FormattedMessage id='campaign2' /></p> */}
          <p className='campaign-text-2-1'><FormattedMessage id='campaign2-1' /></p>
          {account ? <>
            <div className='buttons'>
              {/* <Button className="campaign-button" disabled={b2ButtonIsDisable} onClick={() => {
                stake('B2')
              }}><FormattedMessage id='Enteron' /> BSquare</Button>
              <Button className="campaign-button" disabled={bitButtonIsDisable} onClick={() => {
                stake('BitLayer')
              }}><FormattedMessage id='Enteron' /> BitLayer</Button> */}

              <div>
                <p className='balance-button-box'>
                  <span><FormattedMessage id='stake7' /></span>
                  <span> {b2ChainClotBalance} CLOT</span>
                </p>
                <ApprovalButtons className='campaign-button' hiddenProgress address={clotAddress} contractAddressKey="clotStakeContractAddress" spender={B2} disabled={b2ButtonIsDisable} submit={() => {
                  stake('B2')
                  // console.log(1321)
                }}><FormattedMessage id='Ended' /></ApprovalButtons>
              </div>
              <div>
                <p className='balance-button-box bit-balance'>
                  <span><FormattedMessage id='stake7' /></span>
                  <span> {bitChainClotBalance} CLOT</span>
                </p>
                <ApprovalButtons className='campaign-button' hiddenProgress address={clotAddress} contractAddressKey="clotStakeContractAddress" spender={Bitlayer} disabled={bitButtonIsDisable} submit={() => {
                  stake('BitLayer')
                }}><FormattedMessage id='Ended' /></ApprovalButtons>
              </div>
            </div>
            <div className='campaign-history-button' onClick={() => {
              history.push('/funPIN_history')
            }}>
              <FormattedMessage id='history' />
            </div>
          </> : <div className='wallet-connect'>
            <Button className="campaign-button wallet-connect-button" onClick={() => open()}><FormattedMessage id='Connect' /></Button>
          </div>}

          <div className='campaign-info-box'>
            <p className='campaign-info-title'>
              <FormattedMessage id='campaign3' />
            </p>
            <p className='campaign-info-desc'>
              <FormattedMessage id='campaign4' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign5' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign6' />
            </p>

            <p className='campaign-info-desc mt-20'>
              <FormattedMessage id='campaign7' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign8' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign9' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign10' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign11' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign12' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign13' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign14' />
            </p>

            <p className='campaign-info-desc mt-20'>
              <FormattedMessage id='campaign15' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign16' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign17' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign18' />
            </p>
            <p className='campaign-info-desc1'>
              <FormattedMessage id='campaign19' />
            </p>

            <p className='campaign-info-title'>
              <FormattedMessage id='campaign20' />
            </p>

            <p className='campaign-info-desc mt-20'>
              <FormattedMessage id='campaign21' />
            </p>
            <p className='campaign-info-desc'>
              <FormattedMessage id='campaign22' />
            </p>
            <p className='campaign-info-desc'>
              <FormattedMessage id='campaign23' />
            </p>
            <p className='campaign-info-desc'>
              <FormattedMessage id='campaign24' />
            </p>

            <p className='campaign-info-end'>
              <FormattedMessage id='campaign25' />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index