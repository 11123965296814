import routes from "./routes";
import { Provider } from "react-redux";
import store from "./state";
import "./App.css";
import languages from "./intl";
import Header from "./components/Header/Header";
import { Cam3lotProvider, useCam3lotState } from "./hooks/useCam3lot";
import {
  useWalletModalState,
  WalletModalProvider,
} from "./hooks/useWalletModal";
import Updaters from "./state/Updaters";
import Popups from "./components/Popups";
import WalletModal from "./components/Connect/WalletModal";
import { useCookieState, CookieProvider } from "./hooks/useCookie";
import Container from "./components/Container";
import { IntlProvider } from "react-intl";
import { useLanguage } from "./hooks/useLanuage";
import { useEffect, useState } from "react";

function App() {
  const cam3lotForuse = useCam3lotState();
  const walletModal = useWalletModalState();
  const cookie = useCookieState();

  const { language } = useLanguage();
  const [len, setLen] = useState<string>("en");
  useEffect(() => {
    language && setLen(language.len || "en");
  }, [language]);

  return (
    <Cam3lotProvider value={cam3lotForuse}>
      <Provider store={store}>
        {/* @ts-ignore */}
        <IntlProvider locale={len} messages={languages[len]}>
          <Updaters />
          <WalletModalProvider value={walletModal}>
            <CookieProvider value={cookie}>
              <Popups />
              <WalletModal />
              <Header />
              <Container>{routes()}</Container>
            </CookieProvider>
          </WalletModalProvider>
        </IntlProvider>
      </Provider>
    </Cam3lotProvider>
  );
}

export default App;
