import { NavLink } from "react-router-dom"
import classNames from "classnames"
import styles from "./Menu.module.scss"
import {FormattedMessage} from 'react-intl'
import {RouterData} from '../../routes'

interface MenuItem {
  attrs: { to: string; children: string, hidden: boolean,  disabled?: boolean, tag?: string, link?: string, second?: string[]  }
}

const Menu = ({ list, closePop }: { list: MenuItem[], closePop: any }) => {
  let MenuList = [...list]

  let toLink = (link:string) => {
    window.open(link)
  }
  
  return (
    <ul className={styles.menu}>
      {MenuList.map(({ attrs }) => {
        return attrs.hidden ? null : (
          <li
            className={classNames(styles.item)}
            key={attrs.children}
          >
            {
              attrs.second != null ? (
                <div className={styles.second}>
                  <div className={styles.secondTitle}><FormattedMessage id={attrs.children}/></div>
                  <div className={styles.pop}>
                    {
                      attrs.second.map(val => {
                        let s = MenuList.find(v => v.attrs.children == val) || MenuList[0]
                        let sAttrs = s.attrs
                        return sAttrs.link != null ? <div key={sAttrs.link} className={styles.link} onClick={() => toLink(sAttrs.link || '')}>
                        {sAttrs.tag && <div className={styles.shottag}><FormattedMessage id={sAttrs.tag}/></div>}
                        <FormattedMessage id={sAttrs.children}/>
                      </div> : <NavLink
                          {...sAttrs}
                          key={sAttrs.to}
                          exact={sAttrs.to === "/"}
                          className={classNames(sAttrs.disabled && styles.disabled, styles.link)}
                          activeClassName={styles.active}
                          onClick={() => closePop(false)}
                        >
                          <>
                            {sAttrs.tag && <div className={styles.tag}><FormattedMessage id={sAttrs.tag}/></div>}
                            <FormattedMessage id={sAttrs.children}/>
                          </>
                        </NavLink>
                      })
                    }
                  </div>
                </div>
              ) : attrs.link != null ? (
                <div className={styles.link} key={attrs.link} onClick={() => toLink(attrs.link || '')}>
                  {attrs.tag && <div className={styles.shottag}><FormattedMessage id={attrs.tag}/></div>}
                  <FormattedMessage id={attrs.children}/>
                </div>
              ) : (
                <NavLink
                  {...attrs}
                  exact={attrs.to === "/"}
                  key={attrs.to} 
                  className={classNames(attrs.disabled && styles.disabled, styles.link)}
                  activeClassName={styles.active}
                  onClick={() => closePop(false)}
                >
                  <>
                    {attrs.tag && <div className={styles.tag}><FormattedMessage id={attrs.tag}/></div>}
                    <FormattedMessage id={attrs.children}/>
                  </>
                </NavLink>
              )
            }
          </li>
        )
      })}
    </ul>
  )
}

export default Menu
