import { Switch, Route, RouteProps, Redirect } from "react-router-dom";
import Home from "./pages/Home/Index";
import Agent from "./pages/Agent/Index";
import Stake from "./pages/Stake/Index";
import Data from "./pages/Data/Index";
import StakeHistory from "./pages/Stake/History";
import StakeTeam from "./pages/Stake/Team";
import FunPIN from "./pages/FunPIN/index";
import FunPINHistory from "./pages/FunPIN/History";

export interface Dictionary<A> {
  [index: string]: A;
}

export enum MenuKey {
  Home = "Home",
  Dapp = "Dapp",
  FunPIN = "FunPIN",
  Develop = "Develop",
  Agent = "Agent",
  Stake = "Stake",
  Computility = "Computility",
  StakeHistory = "StakeHistory",
  FunPINHistory = "FunPINHistory",
  StakeTeam = "StakeTeam",
  Data = "Data",
  Market = "Market",
  Testnet = "Testnet",
  Docs = "Docs",
  Valiant = "Valiant",
  Watch = "Watch",
}

export const omit: string[] = [
  'Agent',
  'Stake',
  "StakeHistory", 
  "FunPINHistory", 
  "StakeTeam",
  'Computility',
  'Testnet',
  'Docs',
  'Data'
];

export type RouterData = RouteProps & {
  disabled?: boolean;
  tag?: string;
  link?: string;
  second?: string[]
};

export const menu: Dictionary<RouterData> = {
  [MenuKey.Home]: { path: "/", exact: true, component: Home },
  [MenuKey.Dapp]: { path: "/dapp", second: [
    MenuKey.Agent,
    MenuKey.Stake,
    MenuKey.Computility,
    MenuKey.Data,
  ] ,component: () => <div/> },
  [MenuKey.Develop]: { path: "/develop", second: [
    MenuKey.Testnet,
    MenuKey.Docs,
  ] ,component: () => <div/> },
  [MenuKey.Agent]: { path: "/agent", component: Agent },
  [MenuKey.Stake]: { path: "/stake", component: Stake },
  [MenuKey.StakeHistory]: { path: "/stake_history", component: StakeHistory },
  [MenuKey.FunPINHistory]: { path: "/funPIN_history", component: FunPINHistory },
  [MenuKey.StakeTeam]: { path: "/stake_team", component: StakeTeam },
  [MenuKey.Data]: { path: "/data", component: Data },
  [MenuKey.Market]: { link: 'https://market.cam3lot.io/', component: () => <div />},
  [MenuKey.Testnet]: { link: 'https://testscan.cam3lot.io', component: () => <div />},
  [MenuKey.Computility]: { path: "/computility", disabled: true, tag: "comingSoon", component: () => <div />},
  [MenuKey.Docs]: { path: "/doc", link: 'https://camelot-protocol.gitbook.io/camelot', component: () => <div />},
  [MenuKey.FunPIN]: { path: "/funPIN", tag: "Hot", component: FunPIN},
  [MenuKey.Valiant]: { path: "/valiant", link: 'https://valiant.cam3lot.io', component: () => <div />},
  [MenuKey.Watch]: { path: "/watch", link: 'https://watch.cam3lot.io', component: () => <div />},
};

export const getPath = (key: MenuKey) => menu[key].path as string;

const routers = (routes: Dictionary<RouteProps> = menu, path: string = "") => (
  <Switch>
    {Object.entries(routes).map(([key, route]) => (
      <Route {...route} path={path + route.path} key={key} />
    ))}
    <Redirect to="/" />
  </Switch>
);
export default routers
