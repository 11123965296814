import { useEffect, useRef, useState } from "react";
import styles from "./City2.module.scss";

interface Props {
  ip: string
}
const City = (props: Props) => {
  const { ip } = props
  let [info, setInfo] = useState<any>({})
  useEffect(() => {
    let url = `http://www.geoplugin.net/json.gp?ip=${ip}`
    // let url = `https://ip-api.com/json/${ip}?lang=en`
    fetch(url).then(res => res.json()).then(res => {
      setInfo({
        name: res.geoplugin_countryName,
        key: res.geoplugin_countryCode.toLowerCase()
      })
    })

  }, [ip])

  return (
    <div>
      {!!info.name && <span>
        <span className={`fi fi-${info.key}`}></span>&nbsp;&nbsp;
        {/* {info.name} */}
      </span>}
      <span>{ip}</span>
    </div>
  )
};
export default City;
