import {useBrowser} from '../../hooks/useBrowser'
import Mobile from './Pages/Mobile'
import Desktop from './Pages/Desktop'

const Index = () => {
  let browser = useBrowser()
  return  <div>
    {browser.mobile ? <Mobile/> : <Desktop/>}
  </div>
}
export default Index