import {config} from "../config";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { chain } from "./chain";

/** tx url */ //422
export const getScanHashUrl = (hash: string, chain?: chain) => {
    if(chain) {
        return `${chain.explorerUrl}/tx/${hash}`;
    }
    
}

/** address url  */
export const getScanAddressUrl = (address: string) => {
    return `${config.scanUrl}/address/${address}`;
}

/** 将字符串转成Byte32 */
export function convertToByte32(data: string) {
    let b = Web3.utils.asciiToHex(data)
    while (b.length < 66) {
        b = b + "0";
    }

    return Buffer.from(b.slice(2), 'hex')
}

/** 将Byte32转换为字符串 */
export function convertToString(data: any) {
    return new TextDecoder().decode(Buffer.from(convertToExactLenthUint8Array(data)))
}

/** 主要为了去除空格 */
function convertToExactLenthUint8Array(arr: Uint8Array) {
    const length = arr.length;
    let end = length;
    for (let i = length - 1; i >= 0; i--) {
        if (arr[i] !== 0) {
            end = i + 1;
            break
        }
    }

    return arr.slice(0, end);
}

/**
 * 根据价格计算止盈的收益
 *
 * @param principal 本金
 * @param currentPrice 当前价格
 * @param stopProfitPrice 止盈价格
 * @param leverage 杠杆倍数
 * @param isLong 是否是做多
 *  */
export function calProfit(principal: string, currentPrice: string, stopProfitPrice: string, leverage: number, isLong: boolean): BigNumber {
    if ((isLong && new BigNumber(stopProfitPrice).gte(currentPrice)) || (!isLong && new BigNumber(currentPrice).gte(stopProfitPrice))) {
        return new BigNumber(stopProfitPrice).minus(currentPrice).absoluteValue().div(currentPrice).multipliedBy(principal).multipliedBy(leverage)
    }  else {
        return new BigNumber(0);
    }
}

/**
 * 根据止盈百分比计算止盈价格
 *
 * @param percentage 止盈百分比，比如20%，参数填0.2
 * @param currentPrice 当前价格
 * @param leverage 杠杆倍数
 * @param isLong 是否是做多
 *  */
export function calStopProfitPrice(percentage: string, currentPrice: string, leverage: number, isLong: boolean): BigNumber {
    if (isLong) {
        return  new BigNumber(currentPrice).plus(new BigNumber(currentPrice).multipliedBy(percentage).div(leverage));
    } else {
        return  new BigNumber(currentPrice).minus(new BigNumber(currentPrice).multipliedBy(percentage).div(leverage));
    }
}

/**
 * 根据价格计算止损时的损失
 *
 * @param principal 本金
 * @param currentPrice 当前价格
 * @param stopLossPrice 止损价格
 * @param leverage 杠杆倍数
 * @param isLong 是否是做多
 *  */
export function calLoss(principal: string, currentPrice: string, stopLossPrice: string, leverage: number, isLong: boolean): BigNumber {
    if ((isLong && new BigNumber(currentPrice).gte(stopLossPrice)) || (!isLong && new BigNumber(stopLossPrice).gte(currentPrice))) {
        return new BigNumber(stopLossPrice).minus(currentPrice).absoluteValue().div(currentPrice).multipliedBy(principal).multipliedBy(leverage)
    }  else {
        return new BigNumber(0);
    }
}

/**
 * 根据止损百分比计算止损价格
 *
 * @param percentage 止盈百分比，比如20%，参数填0.2
 * @param currentPrice 当前价格
 * @param leverage 杠杆倍数
 * @param isLong 是否是做多
 *  */
export function calStopLossPrice(percentage: string, currentPrice: string, leverage: number, isLong: boolean): BigNumber {
    if (isLong) {
        return  new BigNumber(currentPrice).minus(new BigNumber(currentPrice).multipliedBy(percentage).div(leverage));
    } else {
        return  new BigNumber(currentPrice).plus(new BigNumber(currentPrice).multipliedBy(percentage).div(leverage));
    }
}


/**
 * 计算最大的止盈价格
 * */
export function calMaxProfitPrice(currentPrice: string, leverage: number, isLong: boolean): BigNumber {
    let maxProfitPercent = 9
    if (isLong) {
        return  new BigNumber(currentPrice).plus(new BigNumber(currentPrice).multipliedBy(maxProfitPercent).div(leverage));
    } else {
        return  new BigNumber(currentPrice).minus(new BigNumber(currentPrice).multipliedBy(maxProfitPercent).div(leverage));
    }
}

/**
 * 计算最大的止损价格, 也是强平价
 * */
export function calMaxLossPrice(currentPrice: string, leverage: number, isLong: boolean): BigNumber {
    let maxLossPercent = 0.9
    if (isLong) {
        return  new BigNumber(currentPrice).minus(new BigNumber(currentPrice).multipliedBy(maxLossPercent).div(leverage));
    } else {
        return  new BigNumber(currentPrice).plus(new BigNumber(currentPrice).multipliedBy(maxLossPercent).div(leverage));
    }
}

/**
 * 计算pnl
 * 都是低精度传入
 * @param openPrice 开仓价  
 * @param currentPrice 当前价格
 * @leverage leverage 杠杆倍数
 * @param position 用户的本金
 * @param buy 开多还是开空
 * */
export function calPnl(openPrice: string, currentPrice: string, leverage: number, position: string, buy: boolean): BigNumber {
    let pnl = new BigNumber(currentPrice).minus(openPrice).div(openPrice).multipliedBy(leverage).multipliedBy(position);
    if (buy) {
        return pnl;
    } else {
        return pnl.multipliedBy(-1);
    }
}


