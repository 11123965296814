import { ethers } from 'ethers';
import {config} from '../config';
import { web3ProviderFrom } from './ether-utils';

export function getDefaultProvider(): ethers.providers.Web3Provider {
  return new ethers.providers.Web3Provider(
    web3ProviderFrom(config.url),
    config.networkId,
  )
}

export function getB2Provider(): ethers.providers.Web3Provider {
  return new ethers.providers.Web3Provider(
      web3ProviderFrom("https://rpc.bsquared.network"),
      223,
  )
}
