import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Cam3lot} from "../chain/Cam3lot";
import {config} from "../config";
import createContext from "./createContext";
import Cookies from "js-cookie";
import {useAccount} from "wagmi";
import { chain, chains } from '../libs/chain';
export interface Cam3lotForUse {
    cam3lot: Cam3lot,
    account: string
    currentChain: chain
}

const cam3lot = createContext<Cam3lotForUse>("useCam3lot")
export const [useCam3lot, Cam3lotProvider] = cam3lot

export const useCam3lotState = (): Cam3lotForUse => {

    const {address: account, chainId} = useAccount();
    const currentChain = useMemo(() => {
        return chains.find(val => val.id === chainId)
    }, [chainId])

    const [cam3lot, setCam3lot] = useState<Cam3lot>(new Cam3lot(config));

    useEffect(() => {
        let oldAccount = Cookies.get('account');
        Cookies.set('account', account || '', {secure: true, sameSite: "none"})
        if (oldAccount != account && !!account && !!oldAccount) {
            window.location.reload()
        }
        setCam3lot(new Cam3lot(config));

    }, [account]);

    // @ts-ignore
    return {cam3lot, account, currentChain}
}

export default useCam3lot;
