import { createConfig, http } from "wagmi";
import { injected, safe, walletConnect } from 'wagmi/connectors'
import { AIlayer, Bitlayer, B2, merlin } from "./libs/chain";

const NODE_ENV_DEV = "development";
const NODE_ENV_PRO = "production";

export enum BSC_NET {
  TEST = 686868,
  MAIN = 4200,
}

export type Configuration = {
  networkId: number;
  chainName: string;
  url: string;
  scanUrl: string;
  stakeAddr: string;
};

const configuration: { [env: string]: Configuration } = {
  [NODE_ENV_DEV]: {
    networkId: BSC_NET.MAIN,
    chainName: "Merlin Main",
    url: "https://rpc.merlinchain.io",
    scanUrl: "https://scan.merlinchain.io",
    stakeAddr: "0x87b73cF1cdAF33e7C1F35E9e90cf655771aa1B1a",
  },
  [NODE_ENV_PRO]: {
    networkId: BSC_NET.MAIN,
    chainName: "Merlin Main",
    url: "https://rpc.merlinchain.io",
    scanUrl: "https://scan.merlinchain.io",
    stakeAddr: "0x87b73cF1cdAF33e7C1F35E9e90cf655771aa1B1a",
  },
};

export const projectId = 'b33fa9454e2e02ce8560a7b39c4f95f4'

// 2. Create wagmiConfig
const metadata = {
  name: 'Camelot',
  description: 'Camelot',
  url: 'https://cam3lot.io',
  icons: ['https://cam3lot.io/images/static/logo.png']
}



export const chainConfig = createConfig({
  chains: [AIlayer, Bitlayer, merlin, B2],
  connectors: [
    safe({
      allowedDomains: [/safe-wallet.merlinsecurity.io/],
      debug: false,
      shimDisconnect: true
    }),
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
  ],
  transports: {
    [AIlayer.id]: http("https://mainnet-rpc.anvm.io"),
    [Bitlayer.id]: http("https://rpc.bitlayer.org"),
    [merlin.id]: http("https://rpc.merlinchain.io"),
    [B2.id]: http("https://rpc.bsquared.network"),
  },
});

export const config = configuration[process.env.NODE_ENV || NODE_ENV_DEV];
