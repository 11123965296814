import classNames from "classnames"

interface Props {
  name: string
  style?: any
  size?: string | number
  className?: string
}

const Icon = ({ name, size, style = {}, className }: Props) => (
  <i
    className={classNames("material-icons", className)}
    style={{ fontSize: size, width: size, ...style}}
  >
    {name}
  </i>
)

export default Icon
