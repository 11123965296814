import {useCallback, useEffect, useMemo} from 'react';
import {useHasPendingApproval} from '../state/transactions/hooks';
import useAllowance from './useAllowance';
import {Contract} from 'ethers';
import JSBI from "jsbi";
import {getAccount, writeContract} from "@wagmi/core";
import {ERC20ABI} from "../chain/contract";
import {chainConfig} from "../config";
import {useWriteContract} from "wagmi";

const APPROVE_AMOUNT = JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
const APPROVE_BASE_AMOUNT = JSBI.BigInt('10000000000000000000000000');

export enum ApprovalState {
    UNKNOWN,
    NOT_APPROVED,
    PENDING,
    APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
function useApprove(token: Contract | undefined, spender: string, needApprove: boolean = false): [ApprovalState, () => void, string] {
    const { data: approveHash,isPending, writeContract, error } = useWriteContract()
    // const pendingApproval = useHasPendingApproval(token && token.address, spender);
    const currentAllowance = useAllowance(token, spender);
    const { connector } = getAccount(chainConfig)

    // check the current approval status
    const approvalState: ApprovalState = useMemo(() => {

        // we might not have enough data to know whether or not we need to approve
        if (!currentAllowance) return ApprovalState.UNKNOWN;

        // amountToApprove will be defined if currentAllowance is
        return !needApprove ? ApprovalState.APPROVED
            : JSBI.LT(currentAllowance, APPROVE_BASE_AMOUNT)
                ? ApprovalState.NOT_APPROVED
                : ApprovalState.APPROVED;
    }, [currentAllowance, needApprove]);
    const approve = useCallback(() => {
        if (approvalState !== ApprovalState.NOT_APPROVED) {
            console.error('approve was called unnecessarily');
            return "";
        }
        writeContract( {
            abi: ERC20ABI,
            // @ts-ignore
            address: token?.address,
            functionName: 'approve',
            args: [
                spender,
                String(APPROVE_AMOUNT),
            ],
            connector
        });

    }, [approvalState, token, spender, connector]);

    //@ts-ignore
    return [approvalState, approve, approveHash];
}

export default useApprove;
