import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
// @ts-ignore
import {useWallet} from 'use-wallet';
import {useDebounce} from '../../hooks';
import {useIsWindowVisible} from '../../hooks';
import {updateBlockNumber} from './actions';
import JSBI from "jsbi";
import {config} from "../../config";
import {getDefaultProvider} from "../../chain/provider";
import {useChainId} from "wagmi";

export default function Updater(): null {
    const chainId = useChainId();

    const dispatch = useDispatch();

    const windowVisible = useIsWindowVisible();

    const [state, setState] = useState<{
        chainId: number | null;
        blockNumber: number | null;
    }>({
        chainId,
        blockNumber: null,
    });

    const blockNumberCallback = useCallback(
        (blockNumber: any) => {
            if (typeof blockNumber === "number") {
                blockNumber = JSBI.toNumber(JSBI.BigInt(blockNumber))
            } else {
                blockNumber = JSBI.toNumber(JSBI.BigInt(blockNumber.epochNumber))
            }
            // @ts-ignore

            setState((state) => {
                if (chainId === state.chainId) {
                    if (typeof state.blockNumber !== 'number') return {chainId, blockNumber};
                    return {chainId, blockNumber: Math.max(blockNumber, state.blockNumber)};
                }
                return state;
            });
        },
        [chainId, setState],
    );

    // attach/detach listeners
    // @ts-ignore
    useEffect(() => {

        if (!chainId || !windowVisible) return undefined;
        setState({chainId, blockNumber: null});

        const provider = getDefaultProvider();
        provider
            .getBlockNumber()
            .then(blockNumberCallback)
            .catch((error) =>
                console.error(`Failed to get block number for chainId: ${chainId}`, error),
            );

        provider.on('block', blockNumberCallback)
        return () => provider.removeListener('block', blockNumberCallback);
    }, [dispatch, chainId, blockNumberCallback, windowVisible]);

    const debouncedState = useDebounce(state, 100);

    useEffect(() => {
        if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return;
        dispatch(
            updateBlockNumber({
                chainId: debouncedState.chainId,
                blockNumber: debouncedState.blockNumber,
            }),
        );
    }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId]);

    return null;
}
