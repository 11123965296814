import React, { FC } from 'react'
import styles from './TokenLogoAndChainLogo.module.scss'

interface IProps {
  chainLogo: string;
  tokenLogo: string;
  size: number;
  className?: string
}
const TokenLogoAndChainLogo:FC<IProps> = (props) => {
  return (
    <div className={`${styles.box} ${props.className || ''}`} style={{width: props.size, height: props.size}}>
      <img src={props.tokenLogo} className={styles.tokenLogo} alt="" />
      {props.chainLogo && <img src={props.chainLogo} alt="" className={styles.chainLogo}  style={{width: props.size / 1.5, height: props.size / 1.5}}/>}
    </div>
  )
}

export default TokenLogoAndChainLogo