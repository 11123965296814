import {useCallback, useState} from "react";
import createContext from "./createContext";

interface WalletModal {
  show: boolean
}


interface useWalletModal {
  // 钱包弹窗
  WalletModal: WalletModal | undefined
  setWalletModal: (info: WalletModal) => void
  // 钱包登录
  sgin: number | undefined
  setSign: (stamp: number) => void
}

export const [useWalletModal, WalletModalProvider] = createContext<useWalletModal>('useWalletModal')

export const useWalletModalState = (): useWalletModal => {
  const [info, setInfo] = useState<WalletModal>();
  
  let setWalletModal = useCallback((info: WalletModal) => {
    setInfo(info)
  }, [])

  const [stamp, setStamp] = useState<number>();
  
  let setSign = useCallback((val: number) => {
    setStamp(val)
  }, [])

  return {
    WalletModal: info,
    setWalletModal,
    sgin: stamp,
    setSign
  }
}