import {useCallback, useState} from "react";
import createContext from "./createContext";

interface Language {
  len: string
}

interface useLanguage {
  language: Language | undefined
  setLanguage: (info: Language) => void
}

export const [useLanguage, LanguageProvider] = createContext<useLanguage>('useLanguage')

export const useLanguageState = (): useLanguage => {
  const [language, setInfo] = useState<Language>();
  
  let setLanguage = useCallback((info: Language) => {
    setInfo(info)
  }, [])


  return {
    language,
    setLanguage
  }
}