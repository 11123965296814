import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// @ts-ignore
import {useWallet} from 'use-wallet';
import {useAddPopup, useBlockNumber} from '../application/hooks';
import {AppDispatch, AppState} from '../index';
import {checkedTransaction, finalizeTransaction} from './actions';
import {config} from "../../config";
import {getDefaultProvider} from "../../chain/provider";
import {useChainId} from "wagmi";

export enum TxStatus {
    ERROR = 0,
    SUCCESS = 1,
}

export function shouldCheck(
    lastBlockNumber: number,
    tx: { addedTime: number; receipt?: {}; lastCheckedBlockNumber?: number },
): boolean {
    // @ts-ignore
    if (tx.receipt && (tx.receipt.status === TxStatus.SUCCESS || tx.receipt.status === TxStatus.ERROR)) return false;
    if (!tx.lastCheckedBlockNumber) return true;
    const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
    if (blocksSinceCheck < 1) return false;
    const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
    if (minutesPending > 60) {
        // every 10 blocks if pending for longer than an hour
        return blocksSinceCheck > 9;
    } else if (minutesPending > 5) {
        // every 3 blocks if pending more than 5 minutes
        return blocksSinceCheck > 2;
    } else {
        // otherwise every block
        return true;
    }
}

export default function Updater(): null {
    const chainId= useChainId();

    const lastBlockNumber = useBlockNumber();

    const dispatch = useDispatch<AppDispatch>();
    const state = useSelector<AppState, AppState['transactions']>((state) => state.transactions);

    const transactions = chainId ? state[chainId] ?? {} : {};

    // show popup on confirm
    const addPopup = useAddPopup();

    useEffect(() => {
        if (!chainId || !lastBlockNumber) {
            return;
        }

        const provider = getDefaultProvider();
        Object.keys(transactions)
            .filter((hash) => shouldCheck(lastBlockNumber, transactions[hash]))
            .forEach((hash) => {
                provider
                    .getTransactionReceipt(hash)
                    .then((receipt) => {
                        // @ts-ignore
                        if (receipt && (receipt.status === TxStatus.SUCCESS || receipt.status === TxStatus.ERROR)) {
                            dispatch(
                                finalizeTransaction({
                                    chainId,
                                    hash,
                                    receipt: {
                                        blockHash: receipt.blockHash,
                                        blockNumber: receipt.blockNumber,
                                        contractAddress: receipt.contractAddress,
                                        from: receipt.from,
                                        status: receipt.status,
                                        to: receipt.to,
                                        transactionHash: receipt.transactionHash,
                                        transactionIndex: receipt.transactionIndex,
                                    },
                                }),
                            );

                            addPopup(
                                {
                                    txn: {
                                        hash,
                                        // @ts-ignore
                                        success: receipt.status === TxStatus.SUCCESS,
                                        summary: transactions[hash]?.summary,
                                    },
                                },
                                hash,
                            );
                        } else {
                            dispatch(checkedTransaction({chainId, hash, blockNumber: lastBlockNumber}));
                        }
                    })
                    .catch((error) => {
                        console.error(`failed to check transaction hash: ${hash}`, error);
                    });
            });
    }, [chainId, transactions, lastBlockNumber, dispatch, addPopup]);

    return null;
}
