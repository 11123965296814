import Mobile from './Pages/Mobile'
import Desktop from './Pages/Desktop'
import { useEffect, useRef, useState } from 'react'
import {useBrowser} from '../../hooks/useBrowser'
import {addCommas} from '../../libs/formatUtil'
import { useCam3lot, useRequest } from '../../hooks';
import { useWalletModal } from "../../hooks/useWalletModal"
import {useHistory} from "react-router-dom";
import { useWeb3Modal } from '@web3modal/wagmi/react'

export interface Props{
  params: {
    toTeam: () => void
    invitationCode: string
    codeCopy: () => void
    account: string | undefined
    timeObj: Time
    pullSuccess: boolean
    isSigned: boolean
    disabled: boolean
    loading: boolean
    submit: () => void
    codeCopied: boolean
    second: React.MutableRefObject<number>
    signedNumber: string
  }
}

interface Time {
  H: number
  M: number
  S: number
}
const Index = () => {
  let browser = useBrowser()
  let { setWalletModal } = useWalletModal()
  let { account } = useCam3lot();
  let { get, post } = useRequest()
  let history = useHistory();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false)
  const [pullSuccess, setPullSuccess] = useState<boolean>(false)
  const [timeObj, setTimeObj] = useState<Time>({
    H: 0,
    M: 0,
    S: 0,
  });
  const [timestamp, setTimestamp] = useState<any>();
  const [signedNumber, setSignedNumber] = useState<string>('0');
  let {open} = useWeb3Modal()

  useEffect(() => {
    init()
  }, [account])
  let init = () => {
    get('/api/total_signed_in_number').then((res:any) => {
      setSignedNumber(addCommas(res.data.totalNumber || 0))
    })

    if(!account) {
      setIsSigned(false)
      return setTimestamp(new Date().getTime())
    }
    get('/api/signed_in').then((res:any) => {
      setIsSigned(res.data.signed)
      setTimestamp(res.data.timestamp)
    })
  }
  
  let second = useRef<number>(0)
  const submit = () => {
    if(!account){
      open()
      return
    }
    second.current = 1
    setLoading(true)
    let interval = setInterval(() => {
      second.current = second.current + 1
      if(second.current >= 10){
        clearInterval(interval)
        post('/api/sign_in', {
          address: account
        }).then(() => {
          setIsSigned(true)
          setPullSuccess(true)
          setTimeout(() => {
            setPullSuccess(false)
          }, 3000);
        }).finally(() => {
          setLoading(false)
        })
      }
    }, 1000);
  }

  useEffect(() => {
    let dayTime = 1000*60*60*24
    let hourTime = 1000*60*60
    // let time: number = (dayTime - (timestamp+8*hourTime)%dayTime) / 1000;
    let time: number = (dayTime - (timestamp)%dayTime) / 1000;
    timer && clearInterval(timer);
    setTimer(undefined);
    let fun = () => {
      let date = {
        D: parseInt(String(time / 86400)),
        H: parseInt(String(time / 3600)),
        M: parseInt(String((time % 3600) / 60)),
        S: parseInt(String(time % 60)),
      };
      if (time >= 0) {
        time = time -1
        setTimeObj(date)
      } else {
        clearInterval(timer);
        init()
      }
    }
    fun()
    let interval = setInterval(fun, 1000)
    setTimer(interval);
  }, [timestamp]);

  useEffect(() => {
    if (account) {
      // 设置邀请码
      get('/api/user_info').then((res: any) => {
        setInvitationCode(res.data.invitationCode)
      })
    }
  }, [account])
  const [invitationCode, setInvitationCode] = useState<string>('');
  const [codeCopied, setCodeCopied] = useState<boolean>(false)
  const codeCopy = () => {
    try {
      setCodeCopied(true)
      setTimeout(() => setCodeCopied(false), 1000)
    } catch (error) {
      console.error(error)
    }
  }

  let toTeam = () => {
    history.push("/stake_team");
  }

  let getParams = () => {
    return {
      toTeam,
      invitationCode,
      codeCopy,
      account,
      timeObj,
      pullSuccess,
      isSigned,
      disabled,
      loading,
      submit,
      codeCopied,
      second,
      signedNumber
    }
  }

  return  <div>
    {browser.mobile ? <Mobile params={getParams()}/> : <Desktop params={getParams()}/>}
  </div>
}
export default Index