import styles from "./Desktop.module.scss";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";
declare let window: any;

const Index = () => {
  useEffect(() => {
    setTimeout(() => {
      window.AOS.init();
    }, 500);
  }, []);

  return (
    <div>
      <div className={styles.view1}>
        <div className={styles.bg}>
          <video
            className={styles.view1_video}
            autoPlay
            loop
            muted
            poster="/images/other/image.jpg"
            preload="true"
          >
            <source src="https://newcamelot.s3.ap-southeast-1.amazonaws.com/index.mp4" />
          </video>
          <div className={styles.view1_text_view}>
            <div className={styles.view1_4}>
              <div className={`flex ${styles.view1_4_box}`}>
                <div
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="600"
                  className={styles.bitcoin}
                >
                  <FormattedMessage id="home1" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="140"
                  data-aos-duration="600"
                  className={styles.layer3}
                >
                  <FormattedMessage id="home1-1" />
                </div>
                <div
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="600"
                  className={styles.protocol}
                >
                  <FormattedMessage id="home1-2" />
                </div>
              </div>
              <div data-aos="flip-down" data-aos-duration="600"  className={styles.home2}>
                <FormattedMessage id="home2" />
              </div>
              <div
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="600"
                className={styles.home3}
              >
                <FormattedMessage id="home3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.viewInfo}>
        <div>
          <div>
            <div data-aos="fade-down" >
              <FormattedMessage id="home4" />
            </div>
            {/*<div>TVL</div>*/}
          </div>
          <div>
            <div data-aos="fade-up" >
              <FormattedMessage id="home5" />
            </div>
            {/*<div>Network Coverage</div>*/}
          </div>
          <div>
            <div data-aos="fade-down" >
              <FormattedMessage id="home6" />
            </div>
            {/*<div>Devices</div>*/}
          </div>
          <div>
            <div data-aos="fade-up" >
              <FormattedMessage id="home7" />
            </div>
            {/*<div>Accumulated</div>*/}
          </div>
        </div>
      </div>
      {/* <div className={styles.view2}>
        <div className={styles.view2_item}>
          <div className={styles.view2_item_title}>
            <FormattedMessage id="home8" />
          </div>
          <div className={styles.view2_item_subtitle}>
            <FormattedMessage id="home9" />
          </div>
          <div className={styles.view2_item_content}>
            <FormattedMessage id="home10" />
          </div>
          <div className={styles.view2_item_button}>
            <FormattedMessage id="home11" />
          </div>
        </div>

        <div className={classNames(styles.view2_item, styles.view2_item_2)}>
          <img src="/images/other/v2b.png" alt="" />
        </div>

        <div className={classNames(styles.view2_item, styles.view2_item_3)}>
          <div className={styles.view2_item_title}>
            <FormattedMessage id="home12" />
          </div>
          <div className={styles.view2_item_subtitle}>
            <FormattedMessage id="home13" />
          </div>
          <div className={styles.view2_item_content}>
            <FormattedMessage id="home14" />
          </div>
          <div className={styles.view2_item_button}>
            <FormattedMessage id="home11" />
          </div>
        </div>
      </div> */}
      <div className={styles.view10}>
        <div className={styles.view10_title}>
          <FormattedMessage id="home44" />
        </div>
        <div className={styles.view10Content}>
          <div className={styles.v10ta}>
            <img
              data-aos="fade-down"
              data-aos-delay="1800"
              data-aos-duration="1000"
              src="/images/other/ta/5.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-delay="1500"
              data-aos-duration="1000"
              src="/images/other/ta/4.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-delay="1200"
              data-aos-duration="1000"
              src="/images/other/ta/3.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-delay="900"
              data-aos-duration="1000"
              src="/images/other/ta/2.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-duration="1000"
              src="/images/other/ta/1.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-delay="300"
              data-aos-duration="1000"
              src="/images/other/ta/Vector.png"
              alt=""
            />
            <img data-aos="flip-left" src="/images/other/ta/btc.png" alt="" />
            <div
              data-aos="fade-up-right"
              data-aos-delay="2400"
              data-aos-duration="1000"
            >
              <img src="/images/other/ta/5t.png" alt="" />
              <div><FormattedMessage id='ta1'/></div>
              <div><FormattedMessage id='ta2'/></div>
            </div>
            <div
              data-aos="fade-up-left"
              data-aos-delay="2000"
              data-aos-duration="1000"
            >
              <img src="/images/other/ta/4t.png" alt="" />
              <div><FormattedMessage id='ta3'/></div>
              <div><FormattedMessage id='ta4'/></div>
            </div>
            <div
              data-aos="fade-up-right"
              data-aos-delay="1600"
              data-aos-duration="1000"
            >
              <img src="/images/other/ta/3t.png" alt="" />
              <div><FormattedMessage id='ta5'/></div>
            </div>
            <div
              data-aos="fade-up-left"
              data-aos-delay="1200"
              data-aos-duration="1000"
            >
              <img src="/images/other/ta/2t.png" alt="" />
              <div><FormattedMessage id='ta6'/></div>
            </div>
            <div
              data-aos="fade-up-right"
              data-aos-delay="800"
              data-aos-offset="-100"
              data-aos-duration="1000"
            >
              <img src="/images/other/ta/1t.png" alt="" />
              <div><FormattedMessage id='ta7'/></div>
            </div>
          </div>

          <img
            className={styles.v10bg}
            src="/images/other/homev10.png"
            alt=""
          />
        </div>
      </div>
      {/* <div className={styles.view3}>
        <div className={styles.view3_imgage}>
          <img src="/images/other/view3.png" alt="" />
          <div className={styles.view3_title}>
            <FormattedMessage id="home15" />
          </div>
        </div>
        <div className={styles.view3_content}>
          <img src="/images/other/view3Left.png" alt="" />
          <img src="/images/other/view3LeftYellow.png" alt="" />
          <div>
            <div>
              <div>
                <FormattedMessage id="home16" />
              </div>
            </div>
            <div>
              <div>
                <FormattedMessage id="home17" />
              </div>
            </div>
            <div>
              <div>
                <FormattedMessage id="home18" />
              </div>
            </div>
            <div>
              <div>
                <FormattedMessage id="home19" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles.view4Title}>
        <FormattedMessage id="home20" />
      </div>
      <div className={styles.view4}>
        <div
          data-aos="flip-left"
          data-aos-delay="400"
          data-aos-offset="50"
          data-aos-duration="800"
          className={styles.view4_item}
        >
          <div className={styles.view4_item_title}>
            <FormattedMessage id="home21" />
          </div>
          <div className={styles.view4_item_subtitle}>
            {" "}
            <FormattedMessage id="home22" />
          </div>
          <div className={styles.view4_item_content}>
            <div className={styles.view4_item_content_text}>
              <FormattedMessage id="home23" />
            </div>
          </div>
        </div>

        <div
          data-aos="flip-left"
          data-aos-delay="400"
          data-aos-offset="50"
          data-aos-duration="800"
          className={styles.view4_item}
        >
          <div className={styles.view4_item_title}>
            <FormattedMessage id="home24" />
          </div>
          <div className={styles.view4_item_subtitle}>
            <FormattedMessage id="home25" />
          </div>
          <div className={styles.view4_item_content}>
            <div className={styles.view4_item_content_text}>
              <FormattedMessage id="home26" />
            </div>
          </div>
        </div>

        <div
          data-aos="flip-right"
          data-aos-delay="400"
          data-aos-offset="50"
          data-aos-duration="800"
          className={styles.view4_item}
        >
          <div className={styles.view4_item_title}>
            <FormattedMessage id="home27" />
          </div>
          <div className={styles.view4_item_subtitle}>
            <FormattedMessage id="home28" />
          </div>
          <div className={styles.view4_item_content}>
            <div className={styles.view4_item_content_text}>
              <FormattedMessage id="home29" />
            </div>
          </div>
        </div>

        <div
          data-aos="flip-right"
          data-aos-delay="400"
          data-aos-offset="50"
          data-aos-duration="800"
          className={styles.view4_item}
        >
          <div className={styles.view4_item_title}>
            <FormattedMessage id="home30" />
          </div>
          <div className={styles.view4_item_subtitle}>
            {" "}
            <FormattedMessage id="home31" />
          </div>
          <div className={styles.view4_item_content}>
            <div className={styles.view4_item_content_text}>
              <FormattedMessage id="home32" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.view9Title}>
        <FormattedMessage id="home33" />
      </div>
      <div className={styles.view9}>
        <div
          data-aos="zoom-in-up"
          data-aos-delay="400"
          data-aos-offset="120"
          data-aos-duration="1000"
        >
          <div className={styles.app_small_title}>
            <FormattedMessage id="home34" />
          </div>
          <img
            data-aos="fade-down-right"
            data-aos-duration="1000"
            src="/images/other/iphone.png"
            alt=""
          />
          <div>
            <FormattedMessage id="home35" />
          </div>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-delay="400"
          data-aos-offset="120"
          data-aos-duration="1000"
        >
          <div className={styles.app_small_title}>
            <FormattedMessage id="home36" />
          </div>
          <img
            data-aos="fade-down-right"
            data-aos-duration="1000"
            src="/images/other/watch.png"
            alt=""
          />
          <div>
            <FormattedMessage id="home37" />
          </div>
        </div>
        <div
          data-aos="zoom-in-down"
          data-aos-delay="400"
          data-aos-offset="120"
          data-aos-duration="1000"
        >
          <div className={styles.app_small_title}>
            <FormattedMessage id="home38" />
          </div>
          <img
            data-aos="fade-down-right"
            data-aos-duration="1000"
            src="/images/other/car.png"
            alt=""
          />
          <div>
            <FormattedMessage id="home39" />
          </div>
        </div>
        <div
          data-aos="zoom-in-down"
          data-aos-delay="400"
          data-aos-offset="120"
          data-aos-duration="1000"
        >
          <div className={styles.app_small_title}>
            <FormattedMessage id="home40" />
          </div>
          <img
            data-aos="fade-down-right"
            data-aos-duration="1000"
            src="/images/other/vr.png"
            alt=""
          />
          <div>
            <FormattedMessage id="home41" />
          </div>
        </div>
      </div>
      <div className={styles.view7}>
        <div className={styles.view7_c}>
          {/* <div className={styles.view7_des}>
            <FormattedMessage id="home42" />
          </div> */}
          <div className={styles.view7_cbg}>
            <div>
              <img src="/images/other/view7.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.view8}>
        <div>
          <div>
            <FormattedMessage id="home43" />
          </div>
          <div>
            <div>
              <img className={styles.height1}
                  data-aos="fade-down"
                  src="/images/cooperation/0.png"
                  alt=""
              />
            </div>
            <div>
              <img className={styles.height5}
                data-aos="fade-down"
                src="/images/cooperation/ainn.png"
                alt=""
              />
            </div>
            <div>
              <img className={styles.height5}
                data-aos="fade-down"
                src="/images/cooperation/bitlayer.png"
                alt=""
              />
            </div>
            <div>
              <img className={styles.height5}
                data-aos="fade-down"
                src="/images/cooperation/bevm.png"
                alt=""
              />
            </div>
            <div>
              <img className={styles.height5}
                data-aos="fade-down"
                src="/images/cooperation/1.png"
                alt=""
              />
            </div>
            <div>
              <img
                data-aos="fade-down"
                src="/images/cooperation/2.png"
                alt=""
              />
            </div>
            <div>
              <img
                data-aos="fade-down"
                src="/images/cooperation/3.png"
                alt=""
              />
            </div>
            <div>
              <img className={styles.height2}
                data-aos="fade-down"
                src="/images/cooperation/4.png"
                alt=""
              />
            </div>
            <div>
              <img
                data-aos="fade-down"
                src="/images/cooperation/5.png"
                alt=""
              />
            </div>
            <div>
              <img className={styles.height4}
                data-aos="fade-down"
                src="/images/cooperation/6.png"
                alt=""
              />
            </div>
            <div>
              <img className={styles.height2}
                data-aos="fade-down"
                src="/images/cooperation/7.png"
                alt=""
              />
            </div>
            <div>
              <img
                data-aos="fade-down"
                src="/images/cooperation/8.png"
                alt=""
              />
            </div>
            <div className={`flex ${styles.endLogo}`}>
              <img className={styles.height3}
                data-aos="fade-down"
                src="/images/cooperation/10.png"
                alt=""
              />
              <img
                className={styles.height1}
                data-aos="fade-down"
                src="/images/cooperation/9.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
