import styles from "./Desktop.module.scss";
import Button from "../../../../components/Button";
import Dialog from "../../../../components/Dialog";
import Icon from "../../../../components/Icon";
import classNames from "classnames";
import ApprovalButtons from "../../../../components/ApprovalButtons";
import Result from "../../../../components/Result";
import { Props, Type } from "../../Index";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from "../../../../components/Tooltip"
import {TooltipIcon} from "../../../../components/Tooltip"
import { b2ChainName, chain } from "../../../../libs/chain";
import { Key } from "react";

const Index = (props: Props) => {
  let {
    assetPop,
    setAssetPop,
    tokens,
    currentAsset,
    transactionInfo,
    networkPop,
    balance,
    value,
    free,
    timeLoading,
    isConfirming,
    stake,
    toTeam,
    toHistory,
    selectAsset,
    refresh,
    setCurrentNetwork,
    currentNetwork,
    valueChange,
    networks,
    selNetClick,
    invitationCode,
    codeCopied,
    codeCopy,
    type,
    setType,
    unStake,
    setMax,
    account
  } = props.params;

  let toAudit = () => {
    window.open('https://scalebit.xyz/reports/Camelot-Final-Audit-Report.pdf')
  }

  return (
    <div className={styles.page}>
      <Dialog open={assetPop} setOpen={setAssetPop}>
        <div className={styles.networks}>
          <div className={styles.title}><FormattedMessage id="stake1" /></div>
          <div className={styles.list}>
            {tokens.length > 0 ? (
              tokens.map((val: any, index: Key) => (
                <div
                  key={index}
                  onClick={() => selectAsset(val)}
                  className={classNames(
                    currentAsset?.name == val.name ? styles.selected : ""
                  )}
                >
                  <img src={val.icon} alt="" />
                  <div>{val.name}</div>
                </div>
              ))
            ) : (
              <div className={styles.none}>none</div>
            )}
          </div>
        </div>
      </Dialog>
      <div className={styles.bg}>
        <div className={styles.content}>
          <div className={styles.images}>
            <img src="/images/other/stake_1.png" alt="" />
            <video
              className={styles.view1_video}
              autoPlay
              loop
              muted
              preload="true"
            >
              <source src="/images/other/bg2.mp4" />
            </video>
            <img src="/images/other/stake.png" alt="" />
          </div>
          <div className={styles.center}>
            {transactionInfo.show ? (
              <Result
                className={styles.result}
                resultItem={transactionInfo}
                btnClick={refresh}
              ></Result>
            ) : (
              <div className={styles.pop}>
                <div className={styles.selectState}>
                  <div onClick={() => setType(Type.stake)} className={ type == Type.stake ? styles.selected : ''}><FormattedMessage id="Stake" /></div>
                  <div onClick={() => setType(Type.unStake)} className={ type == Type.unStake ? styles.selected : ''} ><FormattedMessage id="Unstake" /></div>
                </div>
                <div className={styles.popcontent}>
                  <div>
                    <div><FormattedMessage id="stake3" /></div>
                    <div className={styles.networkView} onClick={selNetClick}>
                      <img src={currentNetwork?.logo} alt="" />
                      <div>{currentNetwork.name === 'B2' ? b2ChainName : currentNetwork.name}</div>
                      <Icon style={{color: '#000'}} size={20} name="expand_more" />
                      {networkPop && (
                        <div className={styles.networkPop}>
                          {networks.map((val: chain, index: Key) => (
                            <div
                              key={index}
                              className={styles.networkcell}
                              onClick={() => setCurrentNetwork(val)}
                            >
                              <img src={val.logo} alt="" />
                              <div
                                className={classNames(
                                  currentNetwork?.name == val.name
                                    ? styles.nSelected
                                    : ""
                                )}
                              >
                                {val.name === 'B2' ? b2ChainName : val.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className={styles.Auditedby} onClick={toAudit}>
                      <FormattedMessage id="Audited" />
                      <img src="/images/other/scalebit.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div>
                      <FormattedMessage id="stake5" />
                      <TooltipIcon className={styles.tooltipIcon} content={'Due to limited network processing capacity, it is recommended that you transfer M-Token from AA（Particle） wallet to EVM wallet for staking.'}></TooltipIcon>
                    </div>
                    <div onClick={() => setAssetPop(true)}>
                      <img src={currentAsset?.icon} alt="" />
                      <div>{currentAsset?.name}</div>
                      <Icon style={{color: '#000'}} size={20} name="expand_more" />
                    </div>
                  </div>
                  <div>
                    <div>
                      <div><FormattedMessage id="stake6" /></div>
                      <div>
                        {type == Type.unStake && <FormattedMessage id="stake12" />}<FormattedMessage id="stake7" /> {balance} {currentAsset?.name}
                      </div>
                    </div>
                    <div>
                      <input
                        placeholder={"Please enter the amount"}
                        type="text"
                        value={value}
                        onChange={(e) => valueChange(e.target.value)}
                      />
                      <div className={styles.max} onClick={setMax}>MAX</div>
                    </div>
                    {
                      type == Type.stake && <div>
                        <div><FormattedMessage id="stake8" /></div>
                        <div>
                          {free} {currentAsset?.name}
                        </div>
                      </div>
                    }
                  </div>
                  <div className={styles.btn1}>
                    {
                      type == Type.stake ? (
                        <ApprovalButtons
                          address={currentAsset?.address}
                          spender={currentNetwork}
                          disabled={timeLoading || !value}
                          loading={isConfirming}
                          submit={stake}
                          needApprove={currentAsset?.name != "BTC"}
                        >
                          <FormattedMessage id="stake11" />
                        </ApprovalButtons>
                      ) : (
                        <Button 
                          disabled={account ? (timeLoading || !value) : false}
                          loading={isConfirming} 
                          className={styles.unstake} 
                          onClick={unStake}
                        >
                          {account ? <FormattedMessage id="Unstake" /> : <FormattedMessage id="Connect"/>}
                        </Button>
                      )
                    }
                    
                  </div>
                  <Button className={styles.btn2} onClick={() => toTeam()}>
                    <FormattedMessage id="stake9" />
                  </Button>
                  {
                    !!invitationCode && <div className={styles.invite}>
                      <div><FormattedMessage id="agent10" />&nbsp;</div>
                      <div className={styles.code}>{invitationCode}</div>
                      <CopyToClipboard
                        onCopy={codeCopy}
                        text={invitationCode}
                      >
                        <div id="parent">
                          <Tooltip
                            content="Copied!"
                            visible={codeCopied}
                            className={styles.copyButton}
                          >
                            <Icon style={{color: '#F36C17'}} size={12} name="file_copy"></Icon>
                          </Tooltip>
                        </div>
                      </CopyToClipboard>
                    </div>
                  }
                </div>
                <div className={styles.history} onClick={() => toHistory()}>
                  <FormattedMessage id="stake10" /> &gt;
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
