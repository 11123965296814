import styles from "./ApprovalButtons.module.scss"
import {FC, useEffect, useState} from "react";
import useApprove, {ApprovalState} from "../hooks/useApprove";
import {useWalletModal} from "../hooks/useWalletModal"
import Button from "./Button"
import {Contract} from "ethers";
import {TxStatus} from "../state/transactions/updater";
import {reject} from "ramda";
import {FormattedMessage} from 'react-intl';
import {useAccount, useWaitForTransactionReceipt} from "wagmi";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { Cam3lotProvider } from "../chain/Cam3lot-new";
import { chain } from "../libs/chain";
import { BTC_ADDRESS } from "../constants";

interface Props {
    address: string | undefined, //tokenAddress PHM/USDT cofing
    spender: chain, //makerAddress
    disabled: boolean,
    loading?: boolean,
    submit: (address: string) => void,
    needApprove?: boolean
    contractAddressKey?: 'clotStakeContractAddress' | 'contractAddress',
    hiddenProgress?: boolean,
    className?: string
}

const ApprovalButtons: FC<Props> = (props) => {
    let {address, spender, disabled, loading, hiddenProgress, submit, needApprove = true, children, contractAddressKey="contractAddress", className} = props
    /** approve hook
     *
     * @res approveState：在useApprove中有定义，当===3时，说明用户已授权过。
     *      当===1时，用户没有授权。当===2时，用户正在授权，loading。当===0时，按未授权处理
     * @res approve：授权函数，没有参数。当用户点击approve按钮时，调用这个函数即可。另外调用这个函数虽是链上操作，但不用跳转页面。
     * */
    const {address: account, chainId} = useAccount();
    // const {cam3lot} = useCam3lot()
    let {setWalletModal} = useWalletModal()
    const [instance, setInstance] = useState<Contract>()
    // Approval方法中 address spender 参数取配置文件传入
    const contractAddress = spender[`${contractAddressKey}`] || ''
    const [approveState, approve, approveHash] = useApprove(instance, contractAddress, needApprove)
    const [isFirstApprove, setIsFirstApprove] = useState<boolean>(false)
    const [hasApproval, setHasApproval] = useState<boolean>(false)
    const [requested, setRequested] = useState<boolean>(false)
    const [approveSuccess, setApproveSuccess] = useState<boolean>(false)
    const [isApproving, setIsApproving] = useState<boolean>(false)
    // @ts-ignore
    const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({hash: approveHash})
    const { open } = useWeb3Modal()

    useEffect(() => {
        setTimeout(() => {
            setRequested(true)
        }, 1200);
    }, [])

    useEffect(() => {
        // console.log(requested && !!account && (approveState != ApprovalState.APPROVED || isFirstApprove))
        setHasApproval(requested && !!account && (approveState != ApprovalState.APPROVED || isFirstApprove))
    }, [requested, account, approveState, isFirstApprove, spender])

    useEffect(() => {
        if (approveState == ApprovalState.APPROVED) {
            setApproveSuccess(true)
        }
    }, [approveState])

    useEffect(() => {
        if (!account) return
        if (!address || address === BTC_ADDRESS) return;
        try {
            const cam3lot = new Cam3lotProvider(spender)
            let instance = cam3lot.getERC20Contract(address)
            setInstance(instance);
        } catch (error) {
            console.error(error)
        }
    }, [account, address, spender])

    const approveClick = async () => {
        if(chainId !== spender.id) {
            open({
                view: "Networks"
            })
            return
        }
        setIsFirstApprove(true)
        setIsApproving(true)
        approve();
        const provider = instance?.provider;
        let number = 0
        let check = () => {
            if (number > 30) throw ""
            number = number + 1
            if (approveHash) {
                setTimeout(() => {
                    provider?.getTransactionReceipt(approveHash).then(receipt => {
                        if (receipt && (receipt.status === TxStatus.SUCCESS)) {
                            setApproveSuccess(true);
                            setIsApproving(false);
                            setHasApproval(false);
                        }
                        if (receipt && (receipt.status === TxStatus.ERROR)) {
                            setApproveSuccess(false);
                            setIsApproving(false)
                        }
                        check()
                    }).catch(err => {
                        throw reject(err)
                    })
                }, 700);
            }
        }
        check()
    }

    const beforeSubmit = () => {
        if (!account) {
            open()
            return
        } else {
            !!address && submit(address)
        }
    }
    if(hiddenProgress) {
        return <div className={(hasApproval ? styles.twoBtn : '')}>
        <Button disabled={disabled || isConfirming}
                loading={isConfirming} className={`${styles.stakebtn} ${className}`} onClick={() => {
                    (approveState === ApprovalState.APPROVED || isConfirmed) ? beforeSubmit() : approveClick()
                }}>
            {account ? (isConfirming ? <FormattedMessage id="Approve"/> : children) : <FormattedMessage id="Connect"/>}
        </Button>
    </div>
    }
    return (
        <div>
            <div className={(hasApproval ? styles.twoBtn : '')}>
                {
                    hasApproval ? (
                        <Button outline disabled={approveState == ApprovalState.APPROVED || isConfirmed || isConfirming}
                                loading={isConfirming} className={`${styles.approved} ${className}`}
                                onClick={approveClick}>
                            {(approveState == ApprovalState.APPROVED || isConfirmed) ? <FormattedMessage id="Approved"/> : <FormattedMessage id="Approve"/>}
                        </Button>
                    ) : null
                }
                <Button disabled={ account && (disabled || (approveState != ApprovalState.APPROVED && (!approveHash || (approveHash && !isConfirmed))))}
                        loading={loading} className={`${styles.stakebtn} ${className}`} onClick={beforeSubmit}>
                    {account ? children : <FormattedMessage id="Connect"/>}
                </Button>
            </div>
            {/* approval进度条样式 */}
            {
                hasApproval  && (
                    <div
                        className={styles.progress + ' ' + (approveState == ApprovalState.APPROVED ? styles.approved : '')}>
                        <span>1</span>
                        <span></span>
                        <span>2</span>
                    </div>
                )
            }
        </div>
    )
}

export default ApprovalButtons