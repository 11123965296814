
import {getB2Provider, getDefaultProvider} from "../chain/provider";
import {STATUS} from "../components/Result";
import {TxStatus} from "../state/transactions/updater";
const Web3 = require('web3')


export function convertStringToBytes32(symbol: string) {
    let b = Web3.utils.asciiToHex(symbol)
    while (b.length < 66) {
        b = b + "0";
    }

    return Buffer.from(b.slice(2), 'hex')
}

export function convertToExactLenthUint8Array(arr: Uint8Array) {
    const length = arr.length;
    let end = length;
    for (let i = length - 1; i >= 0; i--) {
        if (arr[i] !== 0) {
            end = i + 1;
            break
        }
    }
    return arr.slice(0, end);
}

/* object */

/* array */
export const insertIf = <T>(condition?: any, ...elements: T[]) =>
  condition ? elements : []

/* string */
export const getLength = (text: string) => new Blob([text]).size
export const capitalize = (text: string) =>
  text[0].toUpperCase() + text.slice(1)

export const toNumber = (text: string | undefined) => {
    if (text) {
        return isNaN(Number(text)) ? 0 : Number(text);
    } else {
        return 0
    }
}

export const contractSubmit = async (getHash:any,setLoading:any, setTransaction:any, callback?:any) => {
  setLoading(true)
  let hash: string = ''

  getHash().then((res: string) => {
    hash = res
    setTransaction({
      show: true,
      hash,
      status: STATUS.LOADING
    })
    return hash
  }).then((hash: string) => {
    const provider = getDefaultProvider();
    return new Promise((resolve:any, reject:any) => {
      let number = 0
      let check = () => {
        if(number > 30) throw reject('Query Timeout')
        number = number + 1
        setTimeout(() => {
          provider.getTransactionReceipt(hash).then(receipt => {
            if (receipt && (receipt.status === TxStatus.SUCCESS)) return resolve()
            if (receipt && (receipt.status === TxStatus.ERROR)) throw reject('Status ERROR')
            check()
          }).catch(err => {
            throw reject(err)
          })
        }, 700);
      }
      check()
    })    
  }).then(() => {
    setTransaction({
      show: true,
      hash,
      status: STATUS.SUCCESS
    })
    callback&&callback(hash)
  }).catch((err:any) => {
    let error = JSON.stringify(err)
    try {
      if(err.message){
        error = err.message
      }
    } catch (error) {
      
    }

    setTransaction({
      show: true,
      hash,
      status: STATUS.FAILURE,
      error: error
    })
  })  
}

export const getConditionId = (
    oracleAddress: string,
    questionId: string,
    outcomeSlotCount: number,
) => {
    return Web3.utils.soliditySha3(
        { t: 'address', v: oracleAddress },
        { t: 'bytes32', v: questionId },
        { t: 'uint', v: outcomeSlotCount },
    )
}

export const getPositionId = (collateralToken: string, collectionId: string) => {
    return Web3.utils.soliditySha3(
        { t: 'address', v: collateralToken },
        { t: 'bytes32', v: collectionId },
    )
}

// 获取urlquery参数的对象
export const getUrlQuery = (url:string) => {
  const params:any = {};
  if (url.includes('?')) {
    const urls = url.split('?');
    const arr = urls[1].split('&');
    for (let i = 0, l = arr.length; i < l; i++) {
      const a = arr[i].split('=');
      params[a[0]] = a[1];
    }
  }
  return params;
};

export const convertQuery = (url:any, query:any) => {
  if (!query) {
    return url;
  }

  let queryStr = '';
  if (typeof query === 'string' && query.length > 0) {
    queryStr = query;
  } else if (typeof query === 'object') {
    let urlQuery:any = {};
    urlQuery = Object.assign(urlQuery, getUrlQuery(url));

    urlQuery = Object.assign(urlQuery, query);
    for (const key in urlQuery) {
      const value = urlQuery[key];
      if (value != null) {
        if (value instanceof Array) {
          value.forEach(function (value) {
            queryStr += `${key}=${value}&`;
          });
        } else {
          queryStr += `${key}=${value}&`;
        }
      }
    }
  }

  //删除 queryStr 最后一个&
  if (queryStr && queryStr.endsWith('&')) {
    queryStr = queryStr.slice(0, -1);
  }

  if (queryStr) {
    return url.split('?')[0] + '?' + queryStr;
  } else {
    return url;
  }
};

//替换url 但不刷新页面 参数 query
export const replaceState = (path:string, query:any, hash = '') => {
  const queryStr = query ? convertQuery('', query) : '';
  const link = path + queryStr + hash;
  if (typeof (window.history.replaceState) != 'undefined') {
    window.history.replaceState(null, '', link);
  } else {
    console.error('Browser does not support HTML5. push State can\'t be use');
  }
};

export const receiptCheck = (hash: string) => {
  // todo 后面做成配置化的，参数中把链的rpc传过来
  const provider = getB2Provider();
  return new Promise((resolve, reject) => {
    let check = () => {
      provider.getTransactionReceipt(hash).then(receipt => {
        if (receipt && receipt.status === TxStatus.SUCCESS) {
          return resolve('ok')
        }
        if (receipt && receipt.status === TxStatus.ERROR) {
          return reject('err')
        }
        setTimeout(check, 600);
      }).catch(err => {
        reject(err)
      })
    }
    check()
  })
}