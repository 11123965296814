import { useState, useEffect, ReactNode } from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"
import Menu from "./Menu"
import styles from "./Header.module.scss"
import Icon from "../Icon"
import Connect from '../Connect/Connect'
import {MenuKey, getPath, omit, menu} from "../../routes"
import SelectLanguage from './SelectLanguage'
import Team from "./Team";
import {useAccount} from "wagmi";

const AppHeader = () => {
  let {address: account} = useAccount();
  const menuKeys = Object.values(MenuKey) //Object.values(MenuKey).filter((key: string) => !omit.includes(key))
  const navMenu = menuKeys.map((key: MenuKey) => {
      return ({
          attrs: {
            hidden: omit.includes(key),
            to: getPath(key), 
            children: key, 
            disabled: menu[key].disabled,
            tag: menu[key].tag,
            link: menu[key].link,
            second: menu[key].second,
          },
      })
  })

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.height = 'inherit';
      document.body.style.overflow = 'inherit';
    }
  }, [mobileMenuOpen])
  return (
    <>
      {
        mobileMenuOpen && <div className={styles.headerBg} onClick={()=>setMobileMenuOpen(false)}></div>
      }
      <div className={classNames(styles.header, mobileMenuOpen ? styles.mobileOpen : styles.collapsed )}>
        
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* logo */}
            <Link to="/" className={classNames(styles.logo, mobileMenuOpen ? styles.openLogo : null)}>
              <img className={styles.desktopLogo} src="/images/static/header_logo.png" alt="" />
              <img className={styles.mobileLogo} src={ account ? "/images/static/logo.png" : "/images/static/header_logo.png"  } alt="" />
            </Link>

            {/* 移动样式 */}
            <div className={styles.toggle}>
              {!mobileMenuOpen && <Connect />}
              <div className={styles.menuIcon} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                {
                  !mobileMenuOpen ? <img className={styles.menuIconImg} src="/images/other/menulist.png" alt="" /> : <Icon name={'close'} size={34} />
                }
              </div>
            </div>
          </div>
          {/* tab菜单 */}
          <div className={classNames(styles.support, styles.menus)}>
            <Menu closePop={setMobileMenuOpen} list={navMenu} />
          </div>
          {/* 账户 */}
          <div className={styles.support}>
            <SelectLanguage closeMenu={() => setMobileMenuOpen(false)}/>
            {/* <img style={{height: '32px'}} src="/images/other/en.png" alt="" /> */}
            <div className={styles.connect}>{<Connect />}</div>
          </div>
        </div>
        <Team />
      </div>
    </>
  )
}

export default AppHeader
