import Dialog from '@material-ui/core/Dialog';
import { FC } from "react"
import styles from './Dialog.module.scss';
import Icon from "./Icon"
import { useEffect, useState } from "react"
interface Props {
    children?: React.ReactNode
    open: boolean
    setOpen: (open: boolean) => void
}

const DialogView: FC<Props> = ({ children, open, setOpen }) => {
    return (
        <Dialog  open={open}>
            <div className={styles.content}>
                <div className={styles.close} onClick={() => setOpen(false)}>
                    <Icon className={styles.close} name="close" size={28} />
                </div>
                {children}
            </div>
        </Dialog>
    )
}
export default DialogView