import {FC, useEffect, useMemo, useState} from "react"
import classNames from "classnames"
import Icon from "../Icon"
import styles from "./Connect.module.scss"
import Loading from "../Loading"
import {FormattedMessage} from 'react-intl';
import {truncate} from "../../libs/text"
import WalletPop from "./WalletPop"
import {getFullDisplayBalance} from '../../libs/formatUtil'
import {useAllTransactions} from "../../state/transactions/hooks";
import {useAccount, useDisconnect} from "wagmi";
import Cookies from "js-cookie";
import {useCookie} from "../../hooks/useCookie";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { chains } from "../../libs/chain"
import { Cam3lotProvider } from "../../chain/Cam3lot-new"

interface Props {

}

const ConnectButton: FC<Props> = (props) => {
    const {address: account, chainId} = useAccount()
    // const originBalace = useBalance({address: account})
    const { disconnect } = useDisconnect()
    const {removeCustomCookie} = useCookie()
    const allTransactions = useAllTransactions();
    /** pendingTransactions是number类型，pendingTransactions > 0 表示有正在pending的交易 */
    const pendingTransactions = useMemo(() => {
        return Object.values(allTransactions).filter((tx) => !tx.receipt).length;
    }, [allTransactions]);
    const [balance, setBalance] = useState<string>('0')
    const { open } = useWeb3Modal()

    useEffect(() => {
        if (chainId && !chains.map(val=>val.id).includes(chainId)) {
            removeCustomCookie();
            Cookies.remove('account');
            disconnect()
        }
    }, [chainId]);

    useEffect(() => {
        if (!account) return
        const findChain = chains.find(val => val.id === chainId)
        if(findChain) {
            const provider = new Cam3lotProvider(findChain)
            provider.getBalance(account).then(res => {
                setBalance(getFullDisplayBalance(String(res)))
            })
        }
    }, [chainId, account]);

    const [walletPopShow, setWalletPopShow] = useState<boolean>(false)

    return (
        <>
            {
                pendingTransactions > 0 ? (
                    <button className={styles.loading}>
                        <FormattedMessage id="Pending" />
                        <Loading className={styles.progress}/>
                    </button>
                ) : (
                    account ? (
                        <button className={classNames(styles.connectedButton)}>
                            <div onClick={() => setWalletPopShow(true)}>
                                <Icon style={{marginLeft: '2px'}} name="account_balance_wallet" size={24}/>
                                <strong className={styles.balance}>{`${balance} BTC`}</strong>
                                <span className={styles.address}>
                                    <span>{truncate(account || '', [5, 4])}</span>
                                </span>
                            </div>
                        </button>
                    ) : (
                        <button className={classNames(styles.button)}>
                            <div onClick={() => open()}>
                                <span><FormattedMessage id="Connect" /></span>
                                <span className={styles.walltext}>&nbsp;<FormattedMessage id="Wallet" /></span>
                            </div>
                        </button>
                    )  
                )
            }
            {walletPopShow && <WalletPop open={setWalletPopShow}/>}
        </>
    )
}

export default ConnectButton
