import { useCallback, useMemo } from 'react';
import {
  addPopup,
  PopupContent,
  removePopup,
} from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../index';
// @ts-ignore
import { useWallet } from 'use-wallet';
import {useChainId} from "wagmi";

export function useBlockNumber(): number | undefined {
  const chainId = useChainId();
  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1]);
}

export function useWalletModalOpen(): boolean {
  return useSelector((state: AppState) => state.application.walletModalOpen);
}

export function useSettingsMenuOpen(): boolean {
  return useSelector((state: AppState) => state.application.settingsMenuOpen);
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }));
    },
    [dispatch],
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch],
  );
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList);
  // const list = [
  //   {
  //       key: "0xf1ef72c3a8ff1134861c5d4994d7bcd022e0e4c9c1711d94635ed83da05690f0",
  //       show: true,
  //       content: {
  //           txn: {
  //               hash: "0xf1ef72c3a8ff1134861c5d4994d7bcd022e0e4c9c1711d94635ed83da05690f0",
  //               success: true,
  //               summary: "Approve PGOLD"
  //           }
  //       },
  //       removeAfterMs: 15000
  //   }
  // ]
  return useMemo(() => list.filter((item) => item.show), [list]);
}
