import axios from "axios";
import Cookies from "js-cookie";
import { useWalletModal } from "../hooks/useWalletModal"
/**
 * 网络请求配置
 */

axios.defaults.timeout = 20000;
axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';


/**
 * http request 拦截器
 */
axios.interceptors.request.use(
   (config) => {
     config.data = JSON.stringify(config.data);
     config.headers = {
       "Content-Type": "application/json"
     };
     let account = Cookies.get('account')
     let token = Cookies.get(`t-${account}`);
     config.headers['CAM3LOT-X-TOKEN'] = token
     return config;
   },
   (error) => {
     return Promise.reject(error);
   }
);

 /**
   * http response 拦截器
   */
 axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

interface Request {
  post: any
  get: any
  normalGet: any
}

export default (): Request => {
  const { sgin, setSign } = useWalletModal();
  /**
  * 封装get方法
  * @param url  请求url
  * @param params  请求参数
  * @returns {Promise}
  */
  function get(url: string, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: params,
      }).then((response) => {
        if(response.data.code == 403){
          setSign(new Date().getTime())
        }
        if (!response.data.success) throw response.data.message
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  function normalGet(url: string, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: params,
      }).then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  /**
  * 封装post请求
  * @param url
  * @param data
  * @returns {Promise}
  */

 function post(url: string, data = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, data).then((response) => {
        if(response.data.code == 403){
          setSign(new Date().getTime())
        }
        if (!response.data.success) throw response.data.message
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }



  return {
    post: post,
    get,
    normalGet
  }
}