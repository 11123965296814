import Cookies from "js-cookie";

declare let window: any;
export default () => {
  // 登录插件
  let login = () => {
    let account = Cookies.get('account')
    let token = Cookies.get(`t-${account}`)
    if(!token || !account || !window.camelot) return
    checkCamelot(() => window.camelot.setUserInfo({token: token, account: account}))
  }
  // 退出插件
  let logout = () => {
    if(!window.camelot) return
    window.camelot.setUserInfo(null)
  }
  // 监听插件退出
  let onLogout = (calback: () => void) => {
    if(!window.camelot) return
    checkCamelot(() => window.camelot.onLogout(calback))
  }
  // 监听数据变化
  let onDataChange = (calback: ({}: {ip: string, quality: number}) => void) => {
    if(!window.camelot) return
    checkCamelot(() => window.camelot.onDataChange(calback))
  }
  let checkCamelot = (callback: () => void) => {
    setTimeout(() => {
      callback()
    }, 1200);
  }

  return {
    login,
    logout,
    onLogout,
    onDataChange
  }
}