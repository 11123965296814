import Button from "../../../../components/Button"
import styles from "./Mobile.module.scss"
import { truncate } from "../../../../libs/text"
import {getFullDisplayBalance} from '../../../../libs/formatUtil'
import Icon from "../../../../components/Icon";
import { Props } from "../../Team";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from "../../../../components/Tooltip"
import {TooltipIcon} from "../../../../components/Tooltip"
import {TEAM_NUMBER} from "../../../../constants";
import TokenLogoAndChainLogo from "../../../../components/TokenLogoAndChainLogo";
import BigNumber from "bignumber.js";

const Index = (props: Props) => {
  let {
    userTotalBTC,
    info,
    moreClick,
    showMore,
    tokens,
    showTokenMore,
    requestedUser,
    invitationCode,
    next,
    progress,
    toStake,
    moreTokenClick,
    showHistory,
    historyClick,
    snapshots,
    totalReward,
    reward,
    redeemed,
    withdraw,
    withdrawLoading,
    codeCopied,
    codeCopy,
  } = props.params;

  let stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  }

  return <div className={styles.page}>
    <div className={styles.bg}>
      <div className={styles.content}>
        <div className={styles.images}>
          <img src="/images/other/Vector5.png" alt="" />
          <img src="/images/other/Vector6.png" alt="" />
          <img src="/images/other/Vector7.png" alt="" />
        </div>
      </div>
    </div>
    <div className={styles.center}>
      <div className={styles.title}><FormattedMessage id="stakeT1"/></div>
      <div className={styles.view}>
        <div className={styles.viewContent}>
          <div className={styles.top}>
            <div className={styles.view1_1}>
              <img src="/images/other/box.png" alt="" />
              <div className={styles.title1}><FormattedMessage id="stakeT2"/></div>
              <div>
                <img src="/images/other/btcicon.png" alt="" />
                {userTotalBTC} BTC
              </div>
            </div>
            <div className={styles.view1_2}>
              <div  className={styles.title2}>
                <img src="/images/other/trophy_y.png" alt="" />
                <div><FormattedMessage id="stakeT3"/></div>
              </div>
              <div className={styles.camelotPoints}>
                <div className={styles.camelotNav}>
                  <div className={styles.tPoint}>
                    <FormattedMessage id="stakeT37"/> {info.totalPoint || 0}
                    <TooltipIcon className={styles.tooltipIcon} content={'Points are updated at 0:00 (UTC) every day'}></TooltipIcon>
                  </div>
                  <div className={styles.tPoint}>
                    <FormattedMessage id="stakeT4"/> {info.availablePoints || 0}
                  </div>
                  <div className={styles.cPoint}>
                    <FormattedMessage id="stakeT38"/>
                    {info?.totalPoint != null && info?.availablePoints != null
                        ? new BigNumber(info.totalPoint).minus(new BigNumber(info.availablePoints)).toString()
                        : '0'}
                  </div>
                  <div className={styles.btns}>
                    {/*<div onClick={historyClick}><FormattedMessage id="stakeT5"/></div>*/}
                    <div onClick={moreClick}><FormattedMessage id="stakeT6"/></div>
                  </div>
                </div>
                <div className={styles.rewardInfo}>
                  <div className={styles.alignCenter}>
                    <FormattedMessage id="stakeT21"/> { totalReward } CLOT
                    <TooltipIcon className={styles.tooltipIcon} content={'Please wait for official announcement.'}></TooltipIcon>
                  </div>
                  <div><FormattedMessage id="stakeT22"/> {redeemed} CLOT</div>
                  <div><FormattedMessage id="stakeT23"/> { reward } CLOT</div>
                </div>
                <Button className={styles.withdraw} loading={withdrawLoading} disabled={!(reward >= 100)} onClick={withdraw}><FormattedMessage id="stakeT24"/></Button>
              </div>
            </div>
            <div className={styles.view1_3}>
              <div className={styles.title2}>
                <img src="/images/other/icon1_y.png" alt="" />
                <div><FormattedMessage id="stakeT25"/></div>
              </div>
              <div>
                {
                  tokens.slice(0,3).map((val:any, index:number) => {
                    return  (
                      <div className={styles.cell} key={index}>
                        <div className={styles.balanceView}>{getFullDisplayBalance(val.amount, 18, 5)} {val.name}</div>
                        <TokenLogoAndChainLogo className={styles.logoView} chainLogo={val.chain?.logo} tokenLogo={val.icon} size={21}/>
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.tokensmoreView}>
                {
                  tokens.length > 3 && <div className={styles.tokensmore} onClick={moreTokenClick}>
                    <FormattedMessage id="stakeT26"/>
                  </div>
                }
              </div>
            </div>
            <div className={styles.view1_4}>
              <div className={styles.title2}>
                <img src="/images/other/icon3_y.png" alt="" />
                <div><FormattedMessage id="stakeT27"/></div>
              </div>
              <div>
                <div>
                  <div><FormattedMessage id="stakeT28"/></div>
                  <div>{info.teamNumber || 0}/{TEAM_NUMBER}</div>
                  <img src="/images/other/user_y.png" alt="" />
                </div>
                <div>
                  <div><FormattedMessage id="stakeT29"/></div>
                  <div>{info.teamBTCValue || 0} BTC</div>
                  <img src="/images/other/btc_y.png" alt="" />
                </div>
                {
                  requestedUser && <div>
                    <div><FormattedMessage id="stakeT30"/></div>
                    <div>{invitationCode ? <FormattedMessage id="stakeT31"/> : <FormattedMessage id="stakeT32"/> }</div>
                    {
                      invitationCode ? <img src="/images/other/leader_y.png" alt="" /> : <img src="/images/other/usericon.png" alt="" />
                    }
                  </div>
                }
                {
                  !!invitationCode && <div className={styles.invite}>
                    <div><FormattedMessage id="agent10" />&nbsp;</div>
                    <div className={styles.code}>{invitationCode}</div>
                    <CopyToClipboard
                      onCopy={codeCopy}
                      text={invitationCode}
                    >
                      <div id="parent">
                        <Tooltip
                          content="Copied!"
                          visible={codeCopied}
                          className={styles.copyButton}
                        >
                          <Icon style={{color: '#C28E15'}} size={18} name="file_copy"></Icon>
                        </Tooltip>
                      </div>
                    </CopyToClipboard>
                  </div>
                }
              </div>
            </div>
          </div>
          {
            info.stakeConfigurations.length > 0 && <>
              <div className={styles.BTC}>
                <div>{info.teamBTCValue} BTC</div>
                <div><FormattedMessage id="stakeT33"/> {next} BTC</div>
              </div>
              <div className={styles.progress}>
                <div>
                  <div><FormattedMessage id="stakeT34"/></div>
                  {
                    info.stakeConfigurations?.map((val:any, index:number) => {
                      return <div key={index}>+ {val.sharing * 100}%</div>
                    })
                  }
                </div>
                <div>
                  <div style={{width: `${progress}%`}}></div>
                </div>
                <div>
                  <div><FormattedMessage id="stakeT35"/></div>
                  {
                    info.stakeConfigurations?.map((val:any, index:number) => {
                      return <div key={index}>{val.btcValue} BTC</div>
                    })
                  }
                </div>
              </div>
            </>
          }
        </div>
        <Button className={styles.btn} onClick={toStake}>
          <FormattedMessage id="stakeT36"/>
        </Button>
      </div>
    </div>
    {/*  */}
    {
      showHistory && (
        <div className={styles.popView}>
          <div onClick={stopPropagation} className={styles.historyInfo}>
            <div className={styles.historyInfoTitle}><FormattedMessage id="stakeT7"/></div>
            <div className={styles.historyInfoNav}>
              <div><FormattedMessage id="stakeT8"/></div>
              <div><FormattedMessage id="stakeT9"/></div>
              <div><FormattedMessage id="stakeT10"/></div>
            </div>
            <div className={styles.historyInfoList}>
              {
                snapshots.map((val, index:number) => <div key={index} className={styles.historyInfoCell}>
                  <div>{val.cycleName}</div>
                  <div>{val.points}</div>
                  <div>{val.reward}</div>
                </div>)
              }
            </div>
            {
              snapshots.length == 0 && <div className={styles.none}>
                <img src="/images/static/none_icon.png" alt=""/>
              </div>
            }

            <div className={styles.close} onClick={historyClick}>
                <Icon className={styles.close} name="close" size={28}/>
            </div>
          </div>
        </div>
      )
    }
    {/*  */}
    {
      showMore && (
        <div className={styles.popView}>
          <div onClick={stopPropagation}  className={styles.moreInfo}>
            <div className={styles.moreInfoTitle}>
              <div><FormattedMessage id="stakeT11"/></div>
              <div>
                <img src="/images/other/money.png" alt="" />
                {info.totalPoint}
              </div>
            </div>
            <div className={styles.moreInfoCell}>
              <div className={styles.moreInfoSubTitle}>
                <div><FormattedMessage id="stakeT12"/>:&nbsp;</div>
                <div>{info.stakePoint}</div>
              </div>
              <div className={styles.secondPoint}>
                <div><FormattedMessage id="stakeT13"/>:&nbsp;</div>
                <div>{info.personalStakePoint}</div>
              </div>
              <div className={styles.secondPoint}>
                <div><FormattedMessage id="stakeT14"/>:&nbsp;</div>
                <div>{info.teamStakePoint}</div>
              </div>
            </div>
            <div className={styles.moreInfoCell}>
              <div className={styles.moreInfoSubTitle}>
                <div><FormattedMessage id="stakeT15"/>:&nbsp;</div>
                <div>{info.agentPoint}</div>
              </div>
              <div className={styles.secondPoint}>
                <div><FormattedMessage id="stakeT16"/>:&nbsp;</div>
                <div>{info.personalAgentPoint}</div>
              </div>
              <div className={styles.secondPoint}>
                <div><FormattedMessage id="stakeT17"/>:&nbsp;</div>
                <div>{info.teamAgentPoint}</div>
              </div>
            </div>
            <div className={styles.moreInfoSubCell}>
              <div className={styles.moreInfoSubTitle}><FormattedMessage id="stakeT18"/></div>
              <div className={styles.secondPoint}>{ info.networkPoint }</div>
            </div>
           
            <div className={styles.close} onClick={moreClick}>
              <Icon className={styles.close} name="close" size={28}/>
            </div>
          </div>
        </div>
      )
    }
    {/*  */}
    {
      showTokenMore && (
        <div className={styles.popView}>
          <div onClick={stopPropagation} className={styles.moretokenInfo}>
            {
              tokens.map((val:any, index:number) => {
                return  (
                  <div className={styles.moretokenInfoCell} key={index}>
                    <div>{getFullDisplayBalance(val.amount, 18, 5)} {val.name}</div>
                    <TokenLogoAndChainLogo className={styles.logoView} chainLogo={val.chain?.logo} tokenLogo={val.icon} size={21}/>
                  </div>
                )
              })
            }
            <div className={styles.close} onClick={moreTokenClick}>
              <Icon className={styles.close} name="close" size={28}/>
            </div>
            <div className={styles.moretokenInfoTitle}><FormattedMessage id="stakeT25"/></div>
          </div>
        </div>
      )
    }
  </div>
}
export default Index