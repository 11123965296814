import { CircularProgress } from "@material-ui/core"
import styles from "./Loading.module.scss"

interface Props {
  size?: number
  className?: string,
  hidden?: boolean,
}

const Loading = ({ size, className, hidden }: Props) => (
  <div hidden={hidden}>
    <div className={styles.center}>
      <CircularProgress color="inherit" size={size ?? 24} className={className} />
    </div>
  </div>
)

export default Loading
