/**
 * useBrowser 运行环境检测
 * 使用useBrowser() 获取browser实例
 *
 * 桌面操作系统：
 * @property {boolean} linux
 * @property {boolean} mac
 * @property {boolean} windows
 *
 * 手机操作系统：
 * @property {boolean} android
 * @property {boolean} ios
 *
 * 设备：
 * @property {boolean} mobile - 是否为移动设备，包括平板
 * @property {boolean} desktop - 是否为桌面设备
 * @property {boolean} iphoneX - 是否为支持有安全区域的设备，如 iPhone X

 */

export const useBrowser: any = () => {
  const $browser: any = Object.create(null);
  const userAgent: any = navigator.userAgent;
  // 桌面操作系统
  $browser.linux = userAgent.includes('Linux');
  $browser.mac = userAgent.includes('Macintosh');
  $browser.windows = userAgent.includes('Windows');

  // 手机操作系统
  $browser.android = userAgent.includes('Android');
  $browser.ios = /(iPhone|iPad|iPod)/i.test(userAgent);

  // 浏览器
  $browser.chrome = userAgent.includes('Chrome');
  $browser.ie = userAgent.includes('MSIE');
  $browser.firefox = userAgent.includes('Firefox');
  $browser.safari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
  $browser.wechat = userAgent.includes('MicroMessenger') && !userAgent.includes('wxwork');

  // App 内浏览器
  $browser.app = userAgent.includes('SmileBack') || userAgent.includes('CONSULTANT');
  $browser.appUser = userAgent.includes('SmileBack');
  $browser.appCoun = userAgent.includes('CONSULTANT');
  // iosApp
  $browser.ios_app = $browser.app && $browser.ios;

  // 移动端和桌面端
  $browser.mobile = $browser.android || $browser.ios;
  $browser.desktop = !$browser.mobile;
  // iphoneX机型
  $browser.iphoneX = $browser.ios && ((window.screen.width === 375 && window.screen.height === 812) || (window.screen.width === 414 && window.screen.height === 896) || (window.screen.width === 414 && window.screen.height === 896) || (window.screen.width === 390 && window.screen.height === 844))

  return $browser;
};
