import styles from "./Desktop.module.scss";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import Echarts from "../components/Echarts";
import City from "../components/City2";
import {Props} from "../Index"
import { FormattedMessage } from "react-intl";
import {TooltipIcon} from "../../../components/Tooltip"

const Index = (props: Props) => {
  let {
    account,
    conncet,
    window,
    quality,
    userIp,
    downloadZip,
    totalPoint,
    todayPoint,
    pointList,
    workList,
    getSecondTime,
  } = props.params
  
  return (
    <div className={styles.page}>
      <div className={styles.bg}>
        <div className={styles.content}>
          <div className={styles.images}>
            <img src="/images/other/brige.png" alt="" />
          </div>
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.btns}>
          {!account ? <Button onClick={conncet}><FormattedMessage id="network1" /></Button> : !window.camelot ? (
            <div className={styles.coming}>
              {/*<span className={styles.tag}><FormattedMessage id='comingSoon'/></span>*/}
              <Button className={styles.chromeDownload} outline onClick={downloadZip}>
                <img src="/images/other/chrome.png" alt="" />
                <div>
                  <div><FormattedMessage id='Beta'/></div>
                  <div><FormattedMessage id='Download'/></div>
                </div>
              </Button>
            </div>
          ) : ''}
        </div>
        <div className={styles.nav}>
          <div>
            <img src={`/images/other/wifi${(userIp && quality) ? quality : 0}.png`}/>
            <div>{ (userIp && quality) ? <FormattedMessage id="network6" /> : <FormattedMessage id="network7" />} <span className={(userIp && quality) ? styles.green : styles.red}></span></div>
          </div>
          {
            userIp ? (
              <div className={styles.userIp}>IP: <span>{userIp}</span></div>
            ) : (
              <div className={styles.dataTips}>
                <img src="/images/other/tips.png" alt="" className={styles.tip_img} />
                <FormattedMessage id="dataTips" />
              </div>
            )
          }
        </div>
        <div className={styles.info}>
          <div className={styles.left}>
            <div className={styles.subtitle}>
              <FormattedMessage id="network3" />
              {/* <TooltipIcon content={<FormattedMessage id="dataTips" />}/> */}
            </div>
            <div className={styles.leftCell}>
              <div><FormattedMessage id="network4" /></div>
              <div>{ totalPoint}</div>
            </div>
            <div className={styles.leftCell}>
              <div><FormattedMessage id="network5" /></div>
              <div>{ todayPoint}</div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.subtitle}><FormattedMessage id="network8" /></div>
            <div>
              <Echarts info={pointList}></Echarts>
            </div>
          </div>
        </div>
        <div className={styles.networks}>
          <div className={styles.subtitle}><FormattedMessage id="network9" /></div>
          <div className={styles.list}>
            <div className={styles.celltitle}>
              <div>
                <Icon size={20} name="wifi"></Icon>
              </div>
              <div>IP</div>
              <div><FormattedMessage id="network10" /></div>
              <div><FormattedMessage id="network12" /></div>
            </div>
            {
              workList.map((val: any, index:number) => (
                <div className={styles.cell} key={index}>
                  <div>
                    {
                      userIp == val.ip ? <FormattedMessage id="network6" /> : <FormattedMessage id="network7" />
                    }
                    <span className={userIp == val.ip ? styles.green : styles.red}></span>
                  </div>
                  <div>
                    <City ip={val.ip}/>
                  </div>
                  <div>{getSecondTime(val.costSeconds)}</div>
                  <div>{Number(val.amount).toFixed(2)}</div>
                </div>
              ))
            }
            {
              workList.length == 0 && <div className={styles.none}>
                <img src="/images/static/none_icon.png" alt=""/>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
