import Mobile from "./Pages/History/Mobile";
import Desktop from "./Pages/History/Desktop";
import { useEffect, useState } from "react";
import { useRequest } from "../../hooks";
import { StakeHistoryItem } from "../../chain/Cam3lot";
import { useAccount } from "wagmi";
import { useHistory } from "react-router-dom";
import { useBrowser } from "../../hooks/useBrowser";
import { Cam3lotProvider } from "../../chain/Cam3lot-new";
import { chains, merlin } from "../../libs/chain";
interface TokensObj {
  [key: string]: Token;
}

interface Token {
  address: string;
  icon: string;
  name: string;
}

export interface Props {
  params: {
    list: StakeHistoryItem[];
    tokensObj: TokensObj;
    account: string | undefined;
    back: () => void;
  };
}

const Index = () => {
  let browser = useBrowser();
  const { address: account } = useAccount();
  const [list, setList] = useState<StakeHistoryItem[]>([]);
  const [tokensObj, setTokensObj] = useState<TokensObj>({});
  let { get, normalGet, post } = useRequest();
  let history = useHistory();
  let back = () => {
    history.goBack();
  };
  const init = async () => {
    get("/api/tokens").then((tokenRes: any) => {
      let list: Token[] = tokenRes.data.list;
      let obj: TokensObj = {};
      list.forEach((val) => {
        obj[val.address] = val;
      });
      setTokensObj(obj);
    });
    if (!account) {
      setList([]);
      return;
    }
    const cam3lot = new Cam3lotProvider(merlin)

    const getStakeHistorys = chains.map(val => cam3lot.getStakeHistory(account, val))
    const res = await Promise.all(getStakeHistorys)
    let stakeRes: StakeHistoryItem[] = []
    res.forEach((val, index) => {
      val = val.map(item => ({...item, chain: chains[index]}))
      stakeRes = [...stakeRes, ...val]
    })
    setList(stakeRes);
    // TODO: mock
    // res = [
    //   {
    //     token: '0xD1c6a6BcD3A0b650dBDEae76F7CBc3A86ffAd13c',
    //     amount: '83283173173813',
    //     date: new Date().getTime() / 1000,
    //     action: 1
    //   },
    //   {
    //     token: '0xD1c6a6BcD3A0b650dBDEae76F7CBc3A86ffAd13c',
    //     amount: '83283173173813',
    //     date: new Date().getTime() / 1000,
    //     action: 1
    //   },
    //   {
    //     token: '0xD1c6a6BcD3A0b650dBDEae76F7CBc3A86ffAd13c',
    //     amount: '83283173173813',
    //     date: new Date().getTime() / 1000,
    //     action: 1
    //   }
    // ]
     
  }
  useEffect(() => {
    init()
  }, [account]);

  let getParams = () => {
    return {
      list,
      tokensObj,
      account,
      back,
    };
  };

  return (
    <div>
      {browser.mobile ? (
        <Mobile params={getParams()} />
      ) : (
        <Desktop params={getParams()} />
      )}
    </div>
  );
};
export default Index;
