const lang = {
  // public
  Connect: 'Connect',
  Pending: 'Pending',
  notconnect: 'notconnect',
  CopyAddress: 'CopyAddress',
  Disconnect: 'Disconnect',
  Wallet: 'Wallet',
  ConnectWallet: 'Connect Wallet',

  // Home
  home1: 'Bitcoin',
  'home1-1': 'Layer3',
  'home1-2': 'Protocol',
  home2: 'FOR DePIN',
  home3: 'Camelot Protocol is a Layer3 blockchain that harnesses idle GPU power for AI model training on mobile and\nwearable devices within the Bitcoin ecosystem.',
  home4: 'Layer3',
  home5: 'DePIN',
  home6: 'AI',
  home7: 'Crypto',
  home8: 'LAYER3',
  home9: 'Merlin-based Layer 3 protocol dedicated to DePIN',
  home10: 'Build Layer 3 based on Merlin and gradually build DePIN network to connect through Restaking mechanism. Pooling the idle resources of enterprises and individuals, focusing on distributed arithmetic and bandwidth sharing services for AI applications on removable devices.',
  home11: 'Learn More',
  home12: 'RESTAKE',
  home13: "Committed to building the world's largest DePIN-powered AI mobile device ecosystem",
  home14: 'Based on the DePIN network, release Camelot terminal devices with built-in BitcoinCore, and ultimately realize the vision that every DePIN network node is running a BTC full node, so that the BTC network can cover the world in an extremely decentralized and stable state.',
  home15: 'Architecture',
  home16: 'Distributed Consensus Computing Power Supply',
  home17: 'Layer3 for DePIN',
  home18: 'The Native Bitcoin Layer2',
  home19: 'The Core of Crypto',
  home20: 'Feature',
  home21: 'CAMELOT',
  home22: 'Agent',
  home23: 'Everyone is eligible for owning an agent through simple interactions, e.g. following our X, joining DC. The agent will help you earn extra points via daily tasks. ',
  home24: 'CAMELOT',
  home25: 'ReStake',
  home26: 'A highly secured, hassle-free system to help stake your mToken and other Merlin assets to earn points.',
  home27: 'CAMELOT',
  home28: 'Computility',
  home29: 'Utilize excess GPUs to the fullest and consolidate resources in Camelot DePIN to earn points.',
  home30: 'CAMELOT',
  home31: 'Data',
  home32: 'Earn rewards by running a web application that will fetch data from public websites and share it with AI companies.',
  home33: 'Ecosystem',
  home34: 'RACA Phone',
  home35: 'Compatible with the Bitcoin ecosystem and DePIN by Camelot. Win free airdrop from the community, no tasks needed.',
  home36: 'ZOBO Watch',
  home37: 'The very first AI-centric smartwatch with innovative wearable technology - just acting like your AI assistant. ',
  home38: 'Autonomous Vehicles',
  home39: 'Provide in-car AI scenarios and assist EV manufacturers with technical support and computing power supply in the direction of autonomous driving and onboard assistants.',
  home40: 'VR/AR',
  home41: 'Assist head-mounted display devices in technology and computing power support in the direction of AI miniaturization, artificial general intelligence, and gaming content creation.',
  home42: "Camelot Protocol: Crafting the Physical Foundation for AI's Magic.",
  home43: 'PARTNERS',
  home44: 'Architecture',

  // agent
  agent1: 'Try to pull out the Excalibur day by day',
  agent2: 'HOURS',
  agent3: 'MINUTES',
  agent4: 'SECONDS',
  agent5: 'Congrats! You‘ve got 10 points！',
  agent6: 'Has Tried',
  agent7: 'Pull Out',
  agent8: 'Connect Wallet',
  agent9: 'Check My Points',
  agent10: 'Your Invite Code:',
  
  // network 
  network1: 'Connect',
  network2: 'Download plugins',
  network3: 'Data Points',
  network4: 'Total Earnings:',
  network5: "Today's Earnings:",
  network6: 'Connected',
  network7: 'Not Connected',
  network8: 'Dashboard',
  network9: 'Your Networks',
  network10: 'Time Connected',
  network12: 'Points Earned',
  network13: 'Download',
  network14: 'Dashboard',
  
  // stake
  stake1: 'choose assets',
  stake2: 'The First BTC Layer3 for DePIN',
  stake3: 'Network',
  stake4: 'Merlin',
  stake5: 'Assets',
  stake6: 'Amount',
  stake7: 'Balance:',
  stake8: 'Service Fee',
  stake9: 'Check My Points',
  stake10: 'View your transaction history',
  stake11: 'Stake',
  stake12: 'Stake ',

  // history
  stakeH0: 'Chain',
  stakeH1: 'Time',
  stakeH2: 'Asset',
  stakeH3: 'Type',
  stakeH4: 'Amount',
  stakeH5: 'From',
  stakeH6: 'Stake More',
  stakeH7: 'Stake More',
  stakeH8: 'Status',

  // team
  stakeT1: "Camelot's Miracle",
  stakeT2: 'Staked Tokens',
  stakeT3: 'Your Rewards',
  stakeT4: 'Points Balance :',
  stakeT5: 'History',
  stakeT6: 'Details',
  stakeT7: 'Rewards History',
  stakeT8: 'Epoch',
  stakeT9: 'Points',
  stakeT10: 'Rewards',
  stakeT11: 'Total Points',
  stakeT12: 'Stake Points',
  stakeT13: 'Personal Points',
  stakeT14: 'Team Points',
  stakeT15: 'Agent Points',
  stakeT16: 'Personal Points',
  stakeT17: 'Team Points',
  stakeT18: 'Data Points',
  stakeT19: 'Cloud Points',
  stakeT20: 'Computility Points',
  stakeT21: 'Total Rewards : ',
  stakeT22: 'Redeemed : ',
  stakeT23: 'Redeemable :',
  stakeT24: 'Redeem',
  stakeT25: 'Your Assets',
  stakeT26: 'More',
  stakeT27: 'Your Team',
  stakeT28: 'Total members',
  stakeT29: 'Total Assets',
  stakeT30: 'Role',
  stakeT31: 'Lord',
  stakeT32: 'Knight',
  stakeT33: 'Next Milestone:',
  stakeT34: 'Award',
  stakeT35: 'Target',
  stakeT36: 'Stake More',
  stakeT37: 'Total Points : ',
  stakeT38: 'Spent Points : ',

  // v2
  Home: 'Home',
  Agent: 'Agent',
  Stake: 'Stake',
  Dapp: "Dapp",
  Develop: "Develop",
  Market: "Market",
  FunPIN: "FunPIN",
  Valiant: "Valiant",
  Hot: "Hot",
  Watch: "Watch",
  Computility: 'Computility',
  StakeHistory: 'StakeHistory',
  StakeTeam: 'StakeTeam',
  Data: 'Data',
  Testnet: 'Testnet',
  Docs: 'Docs',
  Managed: 'Managed by',
  Camelot: 'Camelot',
  GetConnected: 'Get Connected',
  Twitter: 'Twitter',
  Discord: 'Discord',
  YourWallet: 'Your Wallet',
  DisconnectWallet: 'Disconnect Wallet',
  ViewonMerlin: 'View on Merlin',
  CreateAgent: 'Create Agent',
  FIRSTSTEP: 'FIRST STEP',
  Leader: 'Lord',
  Lord: 'Lord',
  ExcellentLeader: 'As a Excellent Leader, Build You Own Team! Defending You Name!',
  Knight: 'Knight',
  BraveKnight: 'As a Brave Knight, Join The Evolution! The Light Will Guide You Forward!',
  getaninvite: 'You will get an invite code',
  invitecode: 'invite code',
  SECONDSTEP: 'SECOND STEP',
  Create: 'Create',
  ChooseYourRole: 'Warrior! Please Choose Your Role!',
  Enterinvite: 'Enter invite code',
  CurrentRole: 'Current Role',
  Beta: 'Beta',
  Download: 'Download',
  comingSoon: 'Coming Soon',
  ta1: 'Camelot BTC Devices',
  ta2: 'AI Cloud Service',
  ta3: 'Decentralized Physical',
  ta4: 'Infrastructure Network',
  ta5: 'Layer3 for DePIN',
  ta6: 'The Native Bitcoin Layer2',
  ta7: 'The Core of Crypto',
  dataTips: ' You must stake at least 0.001 BTC worth of tokens and stay connected to the network.',
  Audited: 'Audited by',
  Audit: 'Audit',
  Approve: 'Approve',
  Approved: 'Approved',
  Unstake: "Unstake",

  // FunPIN
  joinDefi: "Join the",
  defi2Pin: "GameFi 2 PIN",
  movement: "Movement",
  "campaign1": "FunPIN: ",
  "campaign1-1": "Embark on an Exciting Adventure and Win Your Smartwatch!",
  "campaign2": "Imagine combining the thrill of gaming with the excitement of real-world rewards! ",
  "campaign2-1":"With FunPIN, you can use your $CLOT tokens to embark on an adventure, unlock exclusive treasures, and earn the coveted Camelot Smartwatch.",
  "campaign3": "How It Works:",
  "campaign4": "1. Start Your Adventure:",
  "campaign5": "• Ticket Price: 20,000 $CLOT",
  "campaign6": "• Adventure Duration: 7 days per round",
  "campaign7": "2. Unlock Amazing Rewards:",
  "campaign8": "A.Titan Chest:",
  "campaign9": "Open at the end of your adventure for guaranteed rewards:",
  "campaign10": "• A brand new Camelot Smartwatch",
  "campaign11": "• 20,000 $CLOT",
  "campaign12": "• Additional surprises like NFTs, tokens, and points",
  "campaign13": "B.Daily Loot Chest:",
  "campaign14": "• Open every day for random rewards including tokens and points",
  "campaign15": "3. How to Get Started:",
  "campaign16": "• Connect Your Wallet: Ensure you have a compatible wallet with at least 20,000 $CLOT tokens.",
  "campaign17": "• Purchase Your Ticket: Head to the 'Join Now' section and buy your ticket. You can join multiple adventures!",
  "campaign18": "• Track Your Journey: Use our dashboard to monitor your adventure and see your rewards.",
  "campaign19": "• Open Your Chests: Enjoy daily surprises from Loot Chests and open your Titan Chest after 7 days for big rewards!",
  "campaign20": "Why Join FunPIN?",
  "campaign21": "• Exciting Adventures: Dive into a thrilling experience with every adventure.",
  "campaign22": "• Real-World Rewards: Earn exclusive Camelot Smartwatches and other valuable rewards.",
  "campaign23": "• Daily Surprises: Enjoy the excitement of opening daily Loot Chests.",
  "campaign24": "• Easy Participation: Simple steps to join and track your progress.",
  "campaign25": "Connect your wallet and join now to start your FunPIN adventure!",
  "Enteron": "Enter on",
  "Ended": "Ended",
  'history': 'History',
  "YourHistory": "Your History",
  "startTime": "Start Time",
  "endTime": "End Time",
  "dailyRewards": "Daily Rewards",
  "ultimatePrize": "Ultimate Prize",
  "open": "Open",
  "days": "day {n}",
  "waiting": "Waiting"
}
export default lang